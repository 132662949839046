export function StepsIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0062 11.4132C20.4181 11.7031 20.8236 12.1039 20.8236 13.8059C20.8236 15.508 20.849 17.9809 20.849 19.6768C20.849 21.3726 21.1722 22.3285 22.5726 22.902C24.2012 23.568 28.8335 24.3142 28.8335 27.3113C28.8335 29.0873 27.3126 30 26.4952 30H19.8288C18.4283 30 17.0849 29.4758 16.071 28.5323C13.9291 26.5342 8.72649 21.052 5.69112 17.8329C4.43641 16.5008 4.57582 14.4165 5.99529 13.2509L6.03965 13.2139L12.3702 8.12631C12.6871 7.8673 13.175 8.02764 13.2701 8.41615C13.4855 9.30417 14.0305 10.6239 15.3866 11.3146C17.5411 12.4122 20.0062 11.4132 20.0062 11.4132Z"
        stroke="#00363F"
        strokeWidth="1.1"
        strokeMiterlimit="10"
      />
      <path
        d="M12.8335 9.19722L16.8441 1.33301M19.8335 11.333L24.8335 3.51733"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default StepsIcon;
