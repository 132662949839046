import { FormGroup, InputGroup, InputSelector } from '@allurion/ui';
import { getTodayDateStr, isError } from '@allurion/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedButton } from 'src/analytics/TrackedUI';
import { Loader } from 'src/components/ui/Loader';
import { toastSuccess, toastError } from 'src/components/ui/toasts';
import { Unit } from 'src/domain/User';
import { toKg, toStone } from 'src/helpers/convertions';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { usePatientWeight } from 'src/hooks/usePatientWeight';
import { Logger } from 'src/services/Logger';

import messages from './weight-entry-messages';

import styles from './WeightEntryDialog.module.scss';

const maxWeightInKg = 453;
const weightLimitsMap: Record<Unit, { min: number; max: number }> = {
  'kg': {
    min: 0,
    max: maxWeightInKg,
  },
  'lb': {
    min: 0,
    max: 999,
  },
  'stone': {
    min: 0,
    max: toStone(maxWeightInKg, 'stone'),
  },
};

type WeightEntryForm = {
  weight: number;
  entryDate: string;
  isStartingWeight: boolean;
};

type Props = {
  patientId: string;
  initialValues: { isStartingWeight?: boolean };
  onClose: () => void;
  afterSave?: () => void;
};

export function WeightEntryDialog({ patientId, initialValues, onClose, afterSave }: Props) {
  const intl = useIntl();
  const [isSaving, setIsSaving] = useState(false);
  const { unitsPreference } = useCurrentUser();
  const { createPatientWeightData } = usePatientWeight(patientId);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const weightLimits = weightLimitsMap[unitsPreference] ?? 'kg';
  const WeightEntrySchema = Yup.object()
    .shape({
      weight: Yup.number()
        .typeError(intl.formatMessage(messages.requiredWeight, weightLimits))
        .required(intl.formatMessage(messages.requiredWeight, weightLimits))
        .min(weightLimits.min, intl.formatMessage(messages.requiredWeight, weightLimits))
        .max(weightLimits.max, intl.formatMessage(messages.requiredWeight, weightLimits)),
      entryDate: Yup.string().required(intl.formatMessage(messages.requiredEntryDate)),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WeightEntryForm>({
    resolver: yupResolver(WeightEntrySchema),
    defaultValues: initialValues,
  });

  return (
    <>
      <Loader cover isLoading={isSaving} />

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.content}>
          <FormGroup
            label={intl.formatMessage(messages.dateLabel)}
            error={errors.entryDate?.message}
          >
            <InputGroup
              textInput={{ type: 'date', max: getTodayDateStr(), ...register('entryDate') }}
            />
          </FormGroup>

          <FormGroup
            label={intl.formatMessage(messages.weightLabel)}
            error={errors.weight?.message}
          >
            <InputGroup
              textInput={{
                type: 'number',
                placeholder: intl.formatMessage(messages.weightPlaceholder),
                min: weightLimits.min,
                max: weightLimits.max,
                step: 0.1,
                ...register('weight'),
              }}
              sufix={unitsPreference}
            />
          </FormGroup>

          <FormGroup error={errors.isStartingWeight?.message}>
            <InputSelector
              type="checkbox"
              label={intl.formatMessage(messages.isStartingWeightLabel)}
              {...register('isStartingWeight')}
            />
          </FormGroup>
        </div>
        <div className={styles.footer}>
          <TrackedButton
            onClick={onClose}
            label={intl.formatMessage(messages.cancelBtn)}
            variant="secondary"
            type="button"
            size="sm"
            trackLabel="cancel-weight-entry"
          />
          <TrackedButton
            label={intl.formatMessage(messages.submitBtn)}
            variant="primary"
            type="submit"
            size="sm"
            trackLabel="submit-weight-entry"
          />
        </div>
      </form>
    </>
  );

  async function onSubmit(data: WeightEntryForm) {
    setIsSaving(true);
    try {
      const convertedWeight = toKg(data.weight, unitsPreference);
      const entryDate = new Date(data.entryDate);

      // Set the time to noon to avoid timezone issues
      entryDate.setUTCHours(12);

      await createPatientWeightData(patientId, { ...data, entryDate, weight: convertedWeight });
      toastSuccess(intl.formatMessage(messages.addSuccessMessage));
      trackFormSuccess('weight-entry');
      afterSave?.();
    } catch (error) {
      Logger.captureException(error);
      const errorMessage = isError(error) ? error.message : error;

      toastError(intl.formatMessage(messages.addErrorMessage));
      trackFormError('weight-entry', { error: errorMessage });
    } finally {
      setIsSaving(false);
    }
    onClose();
  }
}
