export function AllurionIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4070_107)">
        <path
          d="M6.91133 0.666748H6.90275L0.666504 15.3278L3.31383 15.3334C4.18101 13.211 5.8152 11.712 7.86055 11.7111C9.90591 11.712 11.7013 13.21 12.5675 15.3334L15.3332 15.3278L9.09693 0.666748H6.91133ZM7.81381 10.8319C6.42575 10.8329 5.20655 11.3375 4.19246 11.9403L7.99984 2.99108L11.9074 12.1761C10.7788 11.4168 9.45372 10.8319 7.81381 10.8319Z"
          fill="currentColor"
          fillOpacity="0.9"
        />
      </g>
      <defs>
        <clipPath id="clip0_4070_107">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
