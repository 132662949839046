import { useIntl } from 'react-intl';

import { getMessageFromDbId } from 'src/helpers/locale';
import { noteTopicMessages } from 'src/pages/PatientPage/Notes/notes-messages';

import { useMetadata } from './useMetadata';

export function useNoteTopics() {
  const intl = useIntl();

  const { metadata } = useMetadata();
  const notesTopics = metadata?.noteTopics;

  const categories: { value: string; label: string; id: string | number }[] = [];

  notesTopics?.forEach(
    (element: { TopicID: number; TopicName: string; TopicDescription: string }) => {
      categories.push({
        value: element.TopicID.toString(),
        label: intl.formatMessage(
          getMessageFromDbId(noteTopicMessages, element.TopicID, element.TopicName)
        ),
        id: element.TopicName,
      });
    }
  );

  return { notesTopics, categories };
}
