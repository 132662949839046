import { useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './Modal.module.scss';

type PropsWithClose<T> = T & {
  close: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type Props<T> = {
  content: (props: PropsWithClose<T>) => React.ReactNode;
};

export function useModal<T>({ content }: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [contentProps, setContentProps] = useState<PropsWithClose<T>>();

  function open(props: T) {
    setContentProps({ ...props, close });
    setIsOpen(true);
  }

  function close(e?: React.MouseEvent) {
    e?.preventDefault();
    setIsOpen(false);
  }

  function Modal() {
    if (!isOpen || !contentProps) {
      return null;
    }

    return createPortal(
      <div className={styles.overlay} onClick={close}>
        <div className={styles.modal}>
          <div className={styles.content}>{content(contentProps)}</div>
        </div>
      </div>,
      document.body
    );
  }

  return {
    Modal,
    open,
  };
}
