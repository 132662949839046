export function MicOnIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0.666748C7.46957 0.666748 6.96086 0.877462 6.58579 1.25253C6.21071 1.62761 6 2.13631 6 2.66675V8.00008C6 8.53051 6.21071 9.03922 6.58579 9.41429C6.96086 9.78937 7.46957 10.0001 8 10.0001C8.53043 10.0001 9.03914 9.78937 9.41421 9.41429C9.78929 9.03922 10 8.53051 10 8.00008V2.66675C10 2.13631 9.78929 1.62761 9.41421 1.25253C9.03914 0.877462 8.53043 0.666748 8 0.666748V0.666748Z"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
      <path
        d="M12.6666 6.66675V8.00008C12.6666 9.23776 12.1749 10.4247 11.2998 11.2999C10.4246 12.1751 9.2376 12.6667 7.99992 12.6667C6.76224 12.6667 5.57526 12.1751 4.70009 11.2999C3.82492 10.4247 3.33325 9.23776 3.33325 8.00008V6.66675"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
      <path d="M8 12.6667V15.3334" stroke="currentColor" strokeWidth="1.1" strokeLinecap="square" />
      <path
        d="M5.33325 15.3333H10.6666"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
    </svg>
  );
}
