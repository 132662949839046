export const tooltips: any = {
  backgroundColor: 'black',
  color: '#fff',
  // plugin
  shadowOffsetX: 1,
  shadowOffsetY: 1,
  shadowBlur: 5,
  shaodwInset: 10,
  shadowColor: 'rgba(0, 0, 0, 0.2)',
  //
  bodyAlign: 'left',
  bodyFontColor: '#fff',
  bodySpacing: 2,
  borderColor: 'rgba(0,0,0,0)',
  borderWidth: 0,
  yAlign: 'bottom',
  xAlign: 'center',
  // callbacks: {
  //   label: (tooltipItem, data) => {
  //     console.log({ tooltipItem });
  //     return `${tooltipItem.value}%`;
  //   },
  // },
  caretPadding: 2,
  caretSize: 5,
  cornerRadius: 6,
  custom: null,
  displayColors: false,
  enabled: true,
  footerAlign: 'left',
  footerFontColor: '#fff',
  footerFontStyle: 'bold',
  footerMarginTop: 6,
  footerSpacing: 2,
  intersect: true,
  mode: 'x-axis', // closest data point to x-axis vertical line
  multiKeyBackground: '#fff',
  position: 'average',
  titleAlign: 'left',
  titleFontColor: '#fff',
  titleFontStyle: 'bold',
  titleMarginBottom: 6,
  titleSpacing: 2,
  xPadding: 12,
  yPadding: 7,
};

export default tooltips;
