import styled from 'styled-components';

import { imageTypes } from 'src/helpers/file';
import audioIconSrc from 'src/images/audio-icon.png';
import pdfIconSrc from 'src/images/pdf-icon.png';

type Props = {
  type: string;
  url: string;
};

export function FileContent({ type, url }: Props) {
  if (imageTypes.includes(type)) {
    return <img src={url} alt={type} style={{ maxWidth: '100%' }} />;
  }
  if (type === 'application/pdf') {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <PDFIcon src={pdfIconSrc} alt="pdf" />
      </a>
    );
  }

  if (type === 'audio/x-m4a' || type === 'audio/mpeg') {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <AudioIcon src={audioIconSrc} alt="audio" />
      </a>
    );
  }

  return null;
}

const PDFIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const AudioIcon = styled.img`
  width: 64px;
  height: 64px;
`;
