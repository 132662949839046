import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

export function useTrackPageView() {
  const location = useLocation();
  const { user } = useCurrentUser();
  const { trackPageView, trackCurrentUser } = useTrackEvent();

  useEffect(() => {
    trackCurrentUser(user);
  }, [trackCurrentUser, user]);

  useEffect(() => {
    trackPageView();
  }, [location.pathname, trackPageView]);

  return null;
}
