import { toast, ToastOptions } from 'react-toastify';
import styled from 'styled-components';

import { CheckCircleIcon } from 'src/icons/CheckIcon';
import { InfoIcon } from 'src/icons/InfoIcon';
import { WarningIcon } from 'src/icons/WarningIcon';
import colors from 'src/styles/theme/colors';
import { GENERIC_TOAST_ID } from 'src/utils/constants';

const ToastWrapper = styled.div`
  display: flex;
  padding-right: 8px;
`;

const IconWrapper = styled.div`
  width: 25px;
`;

const ToastMessage = styled.span`
  /* color: #666; */
  flex: 1;
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
`;

function ToastLayout({ icon, msg }: any) {
  return (
    <ToastWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <ToastMessage>{msg}</ToastMessage>
    </ToastWrapper>
  );
}

const renderSuccess = (msg: string | any) => (
  <ToastLayout msg={msg} icon={<CheckCircleIcon color={colors.Primary} />} />
);
const renderError = (msg: string | any) => (
  <ToastLayout msg={msg} icon={<WarningIcon color={colors.Alert} />} />
);
const renderInfo = (msg: string | any) => (
  <ToastLayout msg={msg} icon={<InfoIcon fill={colors.Primary} />} />
);
const renderWarning = (msg: string | any) => (
  <ToastLayout msg={msg} icon={<WarningIcon color={colors.Alert} />} />
);

export const toastSuccess = (msg: string | any, opts: Partial<ToastOptions> = {}) => {
  toast(renderSuccess(msg), {
    type: toast.TYPE.SUCCESS,
    ...opts,
    className: 'toast-container',
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);
};

export const toastInfo = (msg: string | any, opts: Partial<ToastOptions> = {}) =>
  toast(renderInfo(msg), {
    type: toast.TYPE.INFO,
    ...opts,
    className: 'toast-container',
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastError = (msg: string | any, opts: Partial<ToastOptions> = {}) => {
  toast(renderError(msg), {
    type: toast.TYPE.ERROR,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);
};

export const toastWarning = (msg: string | any, opts: Partial<ToastOptions> = {}) =>
  toast(renderWarning(msg), {
    type: toast.TYPE.WARNING,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateSuccess = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderSuccess(msg),
    type: toast.TYPE.SUCCESS,
    className: 'toast-container',
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateInfo = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderInfo(msg),
    type: toast.TYPE.INFO,
    className: 'toast-container',
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateError = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderError(msg),
    type: toast.TYPE.ERROR,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateWarning = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderWarning(msg),
    type: toast.TYPE.WARNING,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

/**
 * Needs work --> not the best animation lol
 *
 * @param {*} toastId
 */
export const progressControl = (toastId: string) => {
  let progress = 1;

  setInterval(() => {
    if (progress <= 0) {
      progress = 1;
    }
    toast.update(toastId, {
      progress: (progress -= 0.01),
      containerId: GENERIC_TOAST_ID,
    });
  }, 30);
};
