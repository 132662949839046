/**
 *
 * CreatePassword validation
 *
 * */
import { FormattedMessage } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

export const validate = (values: any) => {
  const errors: any = {};

  switch (true) {
    case !values.password:
      errors.password = <FormattedMessage id={globalMessages.required.id} />;
      break;

    case values.password.length < 8:
      errors.password = <FormattedMessage id={globalMessages.minChars.id} />;
      break;

    case !/[a-z]/.test(values.password):
      errors.password = <FormattedMessage id={globalMessages.oneLower.id} />;
      break;

    case !/[A-Z]/.test(values.password):
      errors.password = <FormattedMessage id={globalMessages.oneCapital.id} />;
      break;

    case !/[0-9]/.test(values.password):
      errors.password = <FormattedMessage id={globalMessages.oneNumber.id} />;
      break;

    case !/[!@#$%^&*()_+{}:;'"/\\]/.test(values.password):
      errors.password = <FormattedMessage id={globalMessages.oneSpecialChar.id} />;
      break;

    case !values.confirmPassword:
      errors.confirmPassword = <FormattedMessage id={globalMessages.required.id} />;
      break;

    case values.confirmPassword !== values.password:
      errors.confirmPassword = <FormattedMessage id={globalMessages.passwordMustMatch.id} />;
      break;

    default:
  }

  return errors;
};

export default validate;
