/**
 *
 * Tooltips
 *
 *  */

import baseTooltip from 'src/components/ChartJS/chart-tooltip';

export const tooltips: any = {
  ...baseTooltip,
  callbacks: {
    label: (tooltipItem: any) => `${tooltipItem?.parsed?.y}%`,
  },
  mode: 'nearest',
};

export default tooltips;
