/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  monthsInto: {
    id: 'weight-loss-bar-chart.monthsInto',
    defaultMessage: 'Months into Allurion program',
  },
  Month1: {
    id: 'weight-loss-bar-chart.month1',
    defaultMessage: 'Month 1',
  },
  Month2: {
    id: 'weight-loss-bar-chart.month2',
    defaultMessage: 'Month 2',
  },
  Month3: {
    id: 'weight-loss-bar-chart.month3',
    defaultMessage: 'Month 3',
  },
  Month4: {
    id: 'weight-loss-bar-chart.month4',
    defaultMessage: 'Month 4',
  },
  Month5: {
    id: 'weight-loss-bar-chart.month5',
    defaultMessage: 'Month 5',
  },
  Month6: {
    id: 'weight-loss-bar-chart.month6',
    defaultMessage: 'Month 6',
  },
});
