type Props = {
  size?: string | number;
};

export function GroupIcon({ size = '16' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3638_514)">
        <circle
          cx="3"
          cy="8"
          r="2.45"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.1"
        />
        <circle
          cx="13"
          cy="8"
          r="2.45"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.1"
        />
        <circle
          cx="8"
          cy="13"
          r="2.45"
          transform="rotate(-90 8 13)"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.1"
        />
        <circle
          cx="8"
          cy="3"
          r="2.45"
          transform="rotate(-90 8 3)"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.1"
        />
      </g>
    </svg>
  );
}
