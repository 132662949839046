import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './Popover.module.scss';
import { OverlayContainer } from '../OverlayContainer/OverlayContainer';

type PopoverProps = PropsWithChildren<{
  content: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  closeOnOutsideClick?: boolean;
}>;

export function Popover({
  content,
  children,
  placement = 'right',
  closeOnOutsideClick,
}: PopoverProps) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleToggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className={styles.popoverWrapper}>
      <div ref={setRef} className={styles.popoverToggle} onClick={handleToggleOpen}>
        {children}
      </div>

      {open && (
        <PopoverContent
          anchorEl={ref}
          placement={placement}
          close={() => setOpen(false)}
          closeOnOutsideClick={closeOnOutsideClick}
        >
          {content}
        </PopoverContent>
      )}
    </div>
  );
}

type PopoverContent = {
  children: ReactNode;
  anchorEl: HTMLElement | null;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  closeOnOutsideClick?: boolean;
  close: () => void;
};

export function PopoverContent({
  children,
  anchorEl,
  placement,
  close,
  closeOnOutsideClick,
}: PopoverContent) {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles } = usePopper(anchorEl, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      { name: 'preventOverflow', enabled: true },
    ],
  });

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (!closeOnOutsideClick) {
        return;
      }

      if (anchorEl && !anchorEl.contains(e.target as Node)) {
        close();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div ref={setPopperElement} style={popperStyles.popper} className={styles.popover}>
      {typeof children === 'string' ? (
        <OverlayContainer padding="16px">{children}</OverlayContainer>
      ) : (
        children
      )}
    </div>
  );
}
