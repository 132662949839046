import { useDialog, Card, PencilIcon, GroupIcon } from '@allurion/ui';
import { ComponentProps, Suspense, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedButton, TrackedEmptyState } from 'src/analytics/TrackedUI';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Provider } from 'src/domain/Provider';
import { isAdmin } from 'src/domain/User';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { fetchPatientProviders } from 'src/hooks/usePatientProviders';
import translations from 'src/messages/translations';

import { EditPatientProvidersDialog } from './EditPatientProvidersDialog';

import styles from './PatientProvidersCard.module.scss';

type Props = {
  patientId: string;
  clinicId?: string;
};

export function PatientProvidersCard({ patientId, clinicId }: Props) {
  const intl = useIntl();

  return (
    <Suspense
      fallback={
        <Card title={intl.formatMessage(translations.providers)}>
          <InlineLoader />
        </Card>
      }
    >
      <PatientProvidersCardBody patientId={patientId} clinicId={clinicId} />
    </Suspense>
  );
}

function PatientProvidersCardBody({ patientId, clinicId }: Props) {
  const intl = useIntl();
  const { user } = useCurrentUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [providers, setProviders] = useState<Provider[]>();
  const [showAll, setShowAll] = useState<boolean>(false);

  const shownProviders = showAll ? providers : providers?.slice(0, 5);
  const hasMoreThanFiveProviders = providers && providers?.length > 5;

  const { openDialog, Dialog } = useDialog<ComponentProps<typeof EditPatientProvidersDialog>>({
    content: ({ close, ...contentProps }) => (
      <Suspense fallback={<InlineLoader />}>
        <EditPatientProvidersDialog
          {...{
            ...contentProps,
            providers,
            onCancel: () => close?.(),
            onConfirm: () => {
              fetchProviders();
              close?.();
            },
          }}
        />
      </Suspense>
    ),
  });

  const fetchProviders = useCallback(async () => {
    setIsLoading(true);
    const fetchedProviders = await fetchPatientProviders(patientId);

    setIsLoading(false);

    setProviders(fetchedProviders?.providers);
  }, [patientId]);

  useEffect(() => {
    fetchProviders();
  }, [patientId, clinicId, fetchProviders]);

  const openProvidersAccess = () => {
    openDialog({
      title: intl.formatMessage({
        id: 'provider-access.modal.title',
        defaultMessage: 'Add or change providers',
      }),
      contentProps: { patientId, clinicId, setIsLoading, isOpen: true },
    });
  };

  const iconButtons = [];

  if (isAdmin(user)) {
    iconButtons.push({
      icon: <PencilIcon />,
      onClick: openProvidersAccess,
      tooltip: intl.formatMessage({
        id: 'patient-providers-card.edit-button-tooltip',
        defaultMessage: 'Add or change providers',
      }),
    });
  }

  if (isLoading) {
    return (
      <Card title={intl.formatMessage(translations.providers)}>
        <InlineLoader />
      </Card>
    );
  }

  const hasProviders = providers && providers?.length > 0;

  return (
    <Card title={intl.formatMessage(translations.providers)} iconButtons={iconButtons}>
      {hasProviders ? (
        <div style={{ paddingTop: '20px' }}>
          {shownProviders?.map(({ ProviderID, FirstName, LastName, EmailAddress }) => (
            <div className={styles.providerInformation} key={ProviderID}>
              <span className={styles.providerInformationName}>{`${FirstName} ${LastName}`}</span>
              <span className={styles.providerInformationEmail}>{EmailAddress}</span>
            </div>
          ))}

          {hasMoreThanFiveProviders && (
            <TrackedButton
              onClick={() => {
                setShowAll((val) => !val);
              }}
              variant="secondary"
              size="xs"
              label={!showAll ? `View all ${providers?.length}` : 'View first 5'}
              trackLabel="show-more-patient-providers"
              style={{ width: '100%' }}
            />
          )}
        </div>
      ) : (
        <TrackedEmptyState
          title={intl.formatMessage({
            id: 'patient-providers-card.empty-state.title',
            defaultMessage: 'Providers will appear here',
          })}
          subtitle={intl.formatMessage({
            id: 'patient-providers-card.empty-state.subtitle',
            defaultMessage:
              'Edit the providers with access to this patient and they will appear here.',
          })}
          icon={<GroupIcon />}
          trackedLabel="no-patient-providers-found"
        />
      )}

      <Dialog />
    </Card>
  );
}
