import { Suspense } from 'react';

import { InlineLoader } from 'src/components/ui/InlineLoader';

import { PatientNotes } from '../Notes/PatientNotes';
import { PatientVideoCall } from '../VideoCall/PatientVideoCall';

import styles from './Sidebar.module.scss';

type SidebarProps = {
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  selectedButton?: string;
  setActiveButton?: (button: string) => void;
};

export function Sidebar({ isOpen, selectedButton, setIsOpen, setActiveButton }: SidebarProps) {
  return (
    isOpen && (
      <div className={styles.sidebar}>
        {selectedButton === 'notes' && (
          <Suspense fallback={<InlineLoader />}>
            <PatientNotes setActiveButton={setActiveButton!} setIsSidebarOpen={setIsOpen!} />
          </Suspense>
        )}
        {selectedButton === 'video' && (
          <Suspense fallback={<InlineLoader />}>
            <PatientVideoCall setIsSidebarOpen={setIsOpen!} setActiveButton={setActiveButton!} />
          </Suspense>
        )}
      </div>
    )
  );
}
