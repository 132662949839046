import {
  isToday,
  strToDate,
  toLocalizedShortDate,
  toLocalizedDayOfWeek,
  secondsToHHMMSS,
  minutesToHMM,
} from '@allurion/utils';
import { IntlShape, useIntl } from 'react-intl';

import {
  ActivityItem,
  calculateActivityAverage,
  convertToOrderedArrayByDateAsc,
  ExerciseMapValue,
  fillMissingDays,
  getExerciseDataByDay,
  getMaxDate,
  getSleepDataByDay,
  getStepDataByDay,
} from 'src/domain/patient/activity';
import { PatientExercice, PatientSleep, PatientSteps } from 'src/domain/patient/Patients';
import { capitalize } from 'src/helpers/string';
import globalMessages from 'src/messages/global.messages';

import { useLocale } from './useLocale';

export interface ActivityListItem {
  date: Date;
  value: string;
  description?: string;
  formattedDate: string;
}

export type ActivityChartData = Array<{ x: string; y: number; date: Date }>;

type Props = {
  sleepData: PatientSleep[];
  stepsData: PatientSteps[];
  exerciseData: PatientExercice[];
};

export function useActivityData({ sleepData, stepsData, exerciseData }: Props) {
  const { locale } = useLocale();
  const intl = useIntl();

  const stepsDataByDay = getStepDataByDay(stepsData);
  const stepsDataByDaySortedArray = convertToOrderedArrayByDateAsc(stepsDataByDay);

  const sleepDataByDay = getSleepDataByDay(sleepData);
  const sleepDataByDaySortedArray = convertToOrderedArrayByDateAsc(sleepDataByDay);

  const exerciseDataByDay = getExerciseDataByDay(exerciseData);
  const exerciseByDatSortedArray = convertToOrderedArrayByDateAsc(exerciseDataByDay);

  const startDate = getMaxDate([
    stepsDataByDaySortedArray,
    sleepDataByDaySortedArray,
    exerciseByDatSortedArray,
  ]);

  const last7DaysStepData = fillMissingDays(stepsDataByDaySortedArray, startDate);
  const formattedStepsDataByDay = formatActivityDataForChart(last7DaysStepData, locale);
  const stepsAverage = calculateActivityAverage(last7DaysStepData).toFixed(0);
  const stepsFormatter = (value: number) => `${Number(value).toFixed(0).toLocaleString()}`;
  const formattedStepsDataForList = formatActivityDataForList(
    stepsDataByDay,
    locale,
    intl,
    stepsFormatter
  );

  const last7DaysSleepData = fillMissingDays(sleepDataByDaySortedArray, startDate);
  const minutesToHourConverter = (minutes: number) => minutes / 60;
  const formattedSleepDataByDay = formatActivityDataForChart(
    last7DaysSleepData,
    locale,
    minutesToHourConverter
  );
  const sleepAverage = calculateActivityAverage(last7DaysSleepData);
  const formattedSleepDataForList = formatActivityDataForList(
    sleepDataByDay,
    locale,
    intl,
    minutesToHMM
  );

  const last7DaysExcerciseData = fillMissingDays(exerciseByDatSortedArray, startDate);
  const formattedExerciseDataByDay = formatActivityDataForChart(last7DaysExcerciseData, locale);
  const exerciseAverage = calculateActivityAverage(last7DaysExcerciseData);
  const formattedExerciseDataForList = formatExerciseDataForList(
    exerciseDataByDay,
    locale,
    secondsToHHMMSS,
    intl
  );

  return {
    steps: {
      average: stepsAverage,
      data: formattedStepsDataByDay,
      formatter: stepsFormatter,
      dataForList: formattedStepsDataForList,
      dataByDay: stepsDataByDay,
    },
    sleep: {
      average: sleepAverage,
      data: formattedSleepDataByDay,
      formatter: minutesToHMM,
      dataForList: formattedSleepDataForList,
      dataByDay: sleepDataByDay,
    },
    exercise: {
      average: exerciseAverage,
      data: formattedExerciseDataByDay,
      formatter: secondsToHHMMSS,
      dataForList: formattedExerciseDataForList,
      dataByDay: exerciseDataByDay,
    },
  };
}

function formatActivityDataForChart(
  databyDay: Array<ActivityItem>,
  locale: string,
  converter = (v: number) => v
) {
  const data: ActivityChartData = [];

  if (databyDay.length === 0) return data;

  databyDay.forEach(({ activityDuration, date }) => {
    const dayOfWeek = toLocalizedDayOfWeek(date, locale);

    data.push({
      x: capitalize(dayOfWeek),
      y: converter(activityDuration),
      date,
    });
  });

  return data;
}

function formatActivityDataForList(
  dataByDay: Map<string, number>,
  locale: string,
  intl: IntlShape,
  formatter: (v: number) => string
) {
  const data: Array<ActivityListItem> = [];

  dataByDay.forEach((activityDuration, activityDate) => {
    const formattedDate = isToday(activityDate)
      ? intl.formatMessage(globalMessages.Today)
      : toLocalizedShortDate(activityDate, locale);

    data.push({
      date: strToDate(activityDate),
      value: formatter(activityDuration),
      formattedDate: capitalize(formattedDate),
    });
  });

  return data.sort(sortDatesDesc);
}

function formatExerciseDataForList(
  exerciseDataByDay: Map<string, ExerciseMapValue[]>,
  locale: string,
  formatter: (v: number) => string,
  intl: IntlShape
) {
  const data: Array<ActivityListItem> = [];

  exerciseDataByDay.forEach((activities, activityDate) => {
    const date = strToDate(activityDate);
    const formattedDate = isToday(date)
      ? intl.formatMessage(globalMessages.Today)
      : toLocalizedShortDate(activityDate, locale);

    activities.forEach((activity) => {
      data.push({
        description: activity.activityName,
        value: formatter(activity.activityDuration),
        formattedDate: capitalize(formattedDate),
        date,
      });
    });
  });

  return data.sort(sortDatesDesc);
}

function sortDatesDesc(a: ActivityListItem, b: ActivityListItem): number {
  return b.date.getTime() - a.date.getTime();
}
