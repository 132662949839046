type Props = {
  stroke?: string;
  width?: string | number;
  height?: string | number;
  style?: any;
};

export function ArrowBack({ stroke = '#111836', width = '18', height = '14', style = {} }: Props) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7L17 7"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13L1 7L7 1"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
