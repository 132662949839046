import useSWRInfinite from 'swr/infinite';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientSteps } from 'src/domain/patient/Patients';

const PATIENT_STEPS = (patientId: string) => `/patients/${patientId}/steps`;

export function usePatientSteps(patientId: string) {
  const {
    data = [],
    error,
    isValidating,
    size,
    setSize,
  } = useSWRInfinite(
    getKey,
    (_url) =>
      InsightsApi.get<{ stepsData: PatientSteps[] }>(_url).then(({ stepsData }) => stepsData),
    { revalidateOnFocus: false }
  );

  return {
    isLoading: isValidating,
    error,
    stepsData: data.flat(),
    fetchMore() {
      setSize(size + 1);
    },
  };

  function getKey(page: number, previousPageData: PatientSteps[]) {
    if (previousPageData && !previousPageData.length) return null; // reached the end

    return (
      patientId &&
      buildUrl(PATIENT_STEPS(patientId), {
        page: page + 1,
        pageSize: 7,
      })
    );
  }
}
