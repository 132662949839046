import useSWR from 'swr';

import { InsightsApi, buildUrl } from 'src/api/Api';
import { DailyAction } from 'src/domain/patient/Patients';

import { useLocale } from './useLocale';

export const PATIENT_DAILY_ACTIONS = (patientId: string) => `/patients/${patientId}/daily-actions`;

export function usePatientDailyActions(patientId?: string) {
  const { locale } = useLocale();
  const url = patientId && buildUrl(PATIENT_DAILY_ACTIONS(patientId), { 'locale': locale });
  const { data, error, isValidating } = useSWR(
    url,
    (_url: string) => InsightsApi.get<DailyAction[]>(_url),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return { isLoading: isValidating, dailyActions: data ?? [], hasError: error };
}
