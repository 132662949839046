import { dateAdd, toDateStr } from '@allurion/utils';

import { PatientTreatment } from 'src/domain/patient/Patients';

export function hasAllurionBallonTreatment(treatments: PatientTreatment[]) {
  return treatments.find(({ OriginalTreatmentTypeID }) =>
    isAllurionBallonTreatment(OriginalTreatmentTypeID)
  );
}

export function isAllurionBallonTreatment(treatmentTypeId?: number) {
  return treatmentTypeId === 1;
}

export function isValidStartDate(startDateStr: string) {
  const today = new Date();
  const maxDate = toDateStr(dateAdd(today, 90));

  return startDateStr <= maxDate;
}
