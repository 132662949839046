import styled from 'styled-components';

type Props = {
  color?: string;
  size?: string;
  thickness?: string;
  marginRight?: string;
};

export function CircularProgress({
  color = 'inherit',
  size = '16px',
  thickness = '1px',
  marginRight = '6px',
}: Props) {
  return (
    <CircularElement color={color} size={size} thickness={thickness} marginRight={marginRight} />
  );
}

const CircularElement = styled.div<Props>`
  margin-right: ${(props) => props.marginRight};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border: ${(props) => props.thickness} solid transparent;
  border-top-color: ${(props) => props.color};
  border-radius: 50%;
  animation: spin 0.8s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
`;
