import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import globalMessages from 'src/messages/global.messages';

import messages from './reset-password-messages';
import validate from './reset-password-validate';

type Props = {
  initialValues: any;
};

export function ResetPasswordForm({ initialValues }: Props) {
  const intl = useIntl();
  const { forgotPassword } = useOutletContext<{ forgotPassword: (email: string) => void }>();

  return (
    <>
      <Text>{intl.formatMessage(globalMessages.resetPasswordEmailText)}</Text>
      <Formik initialValues={initialValues} validate={validate(intl)} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <AllurionTextField
              darkbg
              name="email"
              type="email"
              placeholder={intl.formatMessage(messages.emailPlaceholder)}
              label={intl.formatMessage(messages.email)}
            />
            <Controls>
              <TrackedButton
                label={intl.formatMessage(globalMessages.resetPassword)}
                type="submit"
                variant="secondary"
                trackLabel="submit-reset-password"
              />
            </Controls>
          </Form>
        )}
      </Formik>
    </>
  );

  function onSubmit(values: any) {
    const { email: submittedEmail } = values;

    forgotPassword(submittedEmail);
  }
}

const Form = styled.form`
  max-width: 420px;
  margin: 0 auto;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 25px;

  button {
    width: 100%;
  }
`;

const Text = styled.p`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-width: 320px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
`;
