import styles from './TextArea.module.scss';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  invalid?: boolean;
  className?: string;
}

export function TextArea({ className, invalid, ...props }: TextAreaProps) {
  const classNames = [
    styles['text-area'],
    props.disabled ? styles['text-area-disabled'] : '',
    invalid ? styles['text-area-invalid'] : '',
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return <textarea className={classNames} {...props} />;
}
