import Chart from 'chart.js/auto';
import 'chartjs-plugin-style';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import 'chartjs-plugin-style';

import baseTooltip from 'src/components/ChartJS/chart-tooltip';
import { PatientOverview } from 'src/domain/patient/Patients';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import colors from 'src/styles/theme/colors';

import messages from './total-body-weight-loss-chart.messages';

Chart.defaults.font.family = "'GT-America-Standard-Regular', 'PT Sans', sans-serif";

type Props = {
  id: string;
  data: PatientOverview;
};

export function TotalBodyWeightLossChart(props: Props) {
  const intl = useIntl();
  // fixes odd bug where chart does not update correctly on immediate change
  // of locale value

  const canvasRef = useRef<any>(null);
  const chartRef = useRef<any>(null);

  const [width] = useThrottledWindowSize();

  const isMobile = width < breakpoints.tablet;

  const labels = [
    intl.formatMessage({ id: messages.Month1.id }),
    intl.formatMessage({ id: messages.Month2.id }),
    intl.formatMessage({ id: messages.Month3.id }),
    intl.formatMessage({ id: messages.Month4.id }),
    intl.formatMessage({ id: messages.Month5.id }),
    intl.formatMessage({ id: messages.Month6.id }),
  ];

  const { data } = props;
  const weightLossData = [
    data.perc_weight_loss_mo1 ? data.perc_weight_loss_mo1.toFixed(1) : null,
    data.perc_weight_loss_mo2 ? data.perc_weight_loss_mo2.toFixed(1) : null,
    data.perc_weight_loss_mo3 ? data.perc_weight_loss_mo3.toFixed(1) : null,
    data.perc_weight_loss_mo4 ? data.perc_weight_loss_mo4.toFixed(1) : null,
    data.perc_weight_loss_mo5 ? data.perc_weight_loss_mo5.toFixed(1) : null,
    data.perc_weight_loss_mo6 ? data.perc_weight_loss_mo6.toFixed(1) : null,
  ];

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    if (chartRef.current) {
      chartRef.current.destroy();
    }
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Month 1',
            data: weightLossData,
            backgroundColor: colors.GraphBlue,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: isMobile,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: isMobile ? 15 : 25,
            bottom: 0,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: intl.formatMessage({ id: messages.monthsInto.id }),
              font: {
                size: isMobile ? 10 : 13,
                family: 'GT-America-Standard-Regular',
              },
              color: colors.DarkSilver,
              padding: isMobile ? 5 : 20,
            },
            ticks: {
              display: true,
              // userCallback: function (item, index) {
              //   return null;
              // },
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            title: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              display: false,

              callback: (item: number | string) =>
                // this only allows the first/base horizontal line
                item === 0 ? item : null,
            },
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          // @ts-ignore
          datalabels: {
            display: true,
            clip: true,
            align: 'center',
            anchor: 'center',
            textAlign: 'center',
            color: '#ffffff',
            font: {
              weight: 'bold',
              size: 11,
            },
            labels: {
              value: {
                font: {
                  weight: 'bold',
                  family: 'GT-America-Standard-Medium',
                },
              },
            },
            formatter(value: number | string) {
              return value ? `${value}%` : '';
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            ...baseTooltip,
            intersect: true,
            mode: 'nearest',
            callbacks: {
              title: () => null,
              label: (tooltipItem) => `${tooltipItem.parsed.y}%`,
            },
          },
        },
      },
    });
  });
  const { id } = props;

  return (
    <div>
      <canvas id={id} ref={canvasRef} />
    </div>
  );
}
