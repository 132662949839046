import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';

type Props = {
  providersNames: string[];
  endMessage: { label: string; error?: string; icon?: JSX.Element; errorIcon?: JSX.Element };
  onClose: () => void;
};

export function EditProvidersEnd({ providersNames, endMessage, onClose }: Props) {
  const intl = useIntl();

  return (
    <>
      <MessageWrapper>
        {endMessage?.error && (
          <ErrorWrapper>
            <Label>
              <Icon>{endMessage?.errorIcon}</Icon>
              {endMessage?.error}
            </Label>
            <ProvidersList>{providersNames.join('\n')}</ProvidersList>
          </ErrorWrapper>
        )}
        <Label>
          <Icon>{endMessage?.icon}</Icon>
          {endMessage?.label}
        </Label>
      </MessageWrapper>
      <ButtonsWrapper>
        <TrackedButton
          onClick={onClose}
          label={intl.formatMessage({
            id: 'provider-access.form.close',
            defaultMessage: 'Close',
          })}
          trackLabel="acknowledge-successful-edit-providers"
        />
      </ButtonsWrapper>
    </>
  );
}

const Label = styled.div`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
`;
const ProvidersList = styled.p`
  white-space: pre-line;
  line-height: 30px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ErrorWrapper = styled.div`
  color: ${({ theme }) => theme.colors.Alert};
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
