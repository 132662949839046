import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { PasswordPopup } from 'src/components/PasswordPopup/PasswordPopup';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import { MyLink as Link } from 'src/components/ui/Link';
import globalMessages from 'src/messages/global.messages';

import messages from './validate-email-code-messages';
import validate from './validate-email-code-validate';

type Props = {
  initialValues: any;
};

export function ValidateEmailCodeForm({ initialValues }: Props) {
  const intl = useIntl();
  const { forgotPassword, forgotPasswordSubmit, email } = useOutletContext<{
    forgotPassword: (email: string) => void;
    forgotPasswordSubmit: (code: string, password: string) => void;
    email: string;
  }>();

  return (
    <>
      <Text>{intl.formatMessage(globalMessages.resetPasswordVerificationCodeText)}</Text>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <AllurionTextField
              darkbg
              name="code"
              type="text"
              placeholder={intl.formatMessage(messages.verficationCode)}
              label={intl.formatMessage(messages.yourVerficationCode)}
            />
            <AllurionTextField
              darkbg
              name="password"
              type="password"
              placeholder={intl.formatMessage(messages.password)}
              label={
                <>
                  {`${intl.formatMessage(messages.newPassword)} `}
                  <PasswordPopup />
                </>
              }
            />
            <Controls>
              <TrackedButton
                disabled={isSubmitting}
                type="submit"
                label={intl.formatMessage(messages.submit)}
                variant="secondary"
                trackLabel="submit-validate-email-code"
              />
              <StyledLink
                to="#!"
                onClick={(evt: any) => {
                  evt.preventDefault();
                  resendVerification();
                }}
              >
                {intl.formatMessage(messages.resendVerfification)}
              </StyledLink>
              <EmailWrapper>
                {`${intl.formatMessage(messages.email)} `}
                <StyledAnchor href="mailto:help@allurion.com" target="_blank">
                  help@allurion.com
                </StyledAnchor>
              </EmailWrapper>
            </Controls>
          </Form>
        )}
      </Formik>
    </>
  );

  function onSubmit(values: any) {
    const { code, password } = values;

    return forgotPasswordSubmit(code, password);
  }

  function resendVerification() {
    forgotPassword(email);
  }
}

const Form = styled.form`
  max-width: 420px;
  margin: 0 auto;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 25px;

  a {
    border-bottom: 1px solid #fff;
  }

  button {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 40px;
`;

const StyledAnchor = styled.a`
  margin-top: 40px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.75;
  }
`;

const EmailWrapper = styled.div`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 16px;
  margin-top: 70px;
`;

const Text = styled.p`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-width: 320px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
`;
