import { useEffect } from 'react';

import { Loader } from 'src/components/ui/Loader';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

export function HomePage() {
  const { user } = useCurrentUser();
  const { toLoginPage, toClinicsPage, toClinicPage } = useAppNavigate();

  useEffect(() => {
    if (!user?.roleId) {
      toLoginPage();
    } else {
      toClinicPage();
    }
  }, [toClinicPage, toLoginPage, toClinicsPage, user]);

  return <Loader isLoading />;
}
