import { buildUrl, InsightsExportApi } from './Api';

export const EXPORT_PATIENT_DATA = (patientId: string) => `/csv/patients/${patientId}`;
export const EXPORT_CLINIC_DATA = (clinicId: string) => `/csv/clinics/${clinicId}`;

/**
 * In order to pass authorization credentials to the url we cannot just open it
 * in a new tab using window.open(url)
 * Reference: https://stackoverflow.com/questions/4325968/window-open-with-headers
 */
export function exportClinicData(clinicId: string, locale: string) {
  return InsightsExportApi.get(buildUrl(EXPORT_CLINIC_DATA(clinicId), { locale }), {
    isFile: true,
  }).then((file) => {
    const fileURL = URL.createObjectURL(file as Blob);

    // create <a> tag dinamically
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;

    // it forces the name of the downloaded file
    fileLink.download = `${clinicId}.zip`;

    // triggers the click event
    fileLink.click();
  });
}

/**
 * In order to pass authorization credentials to the url we cannot just open it
 * in a new tab using window.open(url)
 * Reference: https://stackoverflow.com/questions/4325968/window-open-with-headers
 */
export function exportPatientData(patientId: string, locale: string) {
  return InsightsExportApi.get(buildUrl(EXPORT_PATIENT_DATA(patientId), { locale }), {
    isFile: true,
  }).then((file) => {
    const fileURL = URL.createObjectURL(file as Blob);

    // create <a> tag dinamically
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;

    // it forces the name of the downloaded file
    fileLink.download = `${patientId}.zip`;

    // triggers the click event
    fileLink.click();
  });
}
