/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  verficationCode: {
    id: 'validate-email.verficationCode',
    defaultMessage: 'Verification Code',
  },
  resendVerfification: {
    id: 'validate-email.resendVerfification',
    defaultMessage: 'resend verification code',
  },
  yourVerficationCode: {
    id: 'validate-email.yourVerficationCode',
    defaultMessage: 'Your verification Code',
  },
  password: {
    id: 'validate-email.password',
    defaultMessage: 'Create Password',
  },
  newPassword: {
    id: 'validate-email.newPassword',
    defaultMessage: 'Create new password',
  },
  submit: {
    id: 'validate-email.submit',
    defaultMessage: 'Reset Password',
  },
  email: {
    id: 'validate-email.email',
    defaultMessage: 'For help email',
  },
  incorrectCode: {
    id: 'validate-email.incorrectCode',
    defaultMessage: 'Incorrect verification code. Please try again or resend code below.',
  },
  newPasssowrdPrompt: {
    id: 'validate-email.newPasssowrdPrompt',
    defaultMessage: 'Enter a password that meets the guidelines',
  },
});
