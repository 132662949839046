import Select from 'react-select';

import { colors } from 'src/styles/theme/colors';

type Props = {
  [key: string]: any;
  options: any[];
  defaultValue?: any;
  selectStyles?: any;
  dark?: boolean;
  isDisabled?: boolean;
};

export function StyledSelect({ options, defaultValue, selectStyles, isDisabled, ...props }: Props) {
  return (
    <Select
      {...props}
      defaultValue={defaultValue}
      options={options}
      isDisabled={isDisabled}
      styles={{
        indicatorSeparator: () => ({ display: 'none' }),
        // indicatorContainer: () => ({ backgroundColor: 'red' }),
        dropdownIndicator: (styles: any) => ({
          ...styles,
          color: colors.Primary,
        }),
        input: (styles: any) => ({ ...styles, outline: 'none' }),
        placeholder: (styles: any) => ({
          ...styles,
          color: 'grey',
          fontSize: '13px',
          fontFamily: 'GT-America-Standard-Light',
        }),
        singleValue: (styles: any) => ({
          ...styles,
          color: colors.Primary,
          fontSize: '13px',
          fontFamily: 'GT-America-Standard-Light',
          fontWeight: 100,
        }),
        control: (styles: any) => ({
          ...styles,
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          paddingLeft: '16px',
          paddingRight: '16px',
          borderRadius: '30px',
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
          height: '40px',
        }),
        option: (styles: any, { isFocused, isSelected }: any) => {
          const allStyles = {
            ...styles,
            fontFamily: 'GT-America-Standard-Regular',
          };

          // uses transparent menu bg color unless selected
          if (isSelected) {
            return {
              ...allStyles,
              backgroundColor: colors.GreyBorder,
              color: '#000',
            };
          }
          if (isFocused) {
            return {
              ...allStyles,
              backgroundColor: 'rgba(0,0,0,0.1)',
            };
          }

          return {
            ...allStyles,
            backgroundColor: 'transparent',
          };
        },
        ...(selectStyles || {}),
      }}
    />
  );
}
