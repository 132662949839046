type Props = {
  size?: string | number;
  stroke?: string;
};

export function FolderIcon({ size = '16' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.05"
        y="4.55"
        width="13.9"
        height="10.4"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
      />
      <path
        d="M9.5 4.5L8.44446 2H1V4.5"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
      />
    </svg>
  );
}
