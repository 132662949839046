/**
 *
 * Global Messages
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  logout: {
    id: 'global.logout',
    defaultMessage: 'Log out',
  },
  Clinic: {
    id: 'global.clinic',
    defaultMessage: 'Clinic',
  },
  selectClinic: {
    id: 'create-new-users.selectClinic',
    defaultMessage: 'Clinics',
  },
  //
  // months of the year
  //
  Jan: {
    id: 'global.Jan',
    defaultMessage: 'Jan',
  },
  Feb: {
    id: 'global.Feb',
    defaultMessage: 'Feb',
  },
  Mar: {
    id: 'global.Mar',
    defaultMessage: 'Mar',
  },
  Apr: {
    id: 'global.Apr',
    defaultMessage: 'Apr',
  },
  May: {
    id: 'global.May',
    defaultMessage: 'May',
  },
  Jun: {
    id: 'global.Jun',
    defaultMessage: 'Jun',
  },
  Jul: {
    id: 'global.Jul',
    defaultMessage: 'Jul',
  },
  Aug: {
    id: 'global.Aug',
    defaultMessage: 'Aug',
  },
  Sep: {
    id: 'global.Sep',
    defaultMessage: 'Sep',
  },
  Oct: {
    id: 'global.Oct',
    defaultMessage: 'Oct',
  },
  Nov: {
    id: 'global.Nov',
    defaultMessage: 'Nov',
  },
  Dec: {
    id: 'global.Dec',
    defaultMessage: 'Dec',
  },
  //
  // Days of week
  //
  Today: {
    id: 'global.today',
    defaultMessage: 'Today',
  },
  Mon: {
    id: 'global.Mon',
    defaultMessage: 'Mon',
  },
  Tue: {
    id: 'global.Tue',
    defaultMessage: 'Tue',
  },
  Wed: {
    id: 'global.Wed',
    defaultMessage: 'Wed',
  },
  Thu: {
    id: 'global.Thu',
    defaultMessage: 'Thu',
  },
  Fri: {
    id: 'global.Fri',
    defaultMessage: 'Fri',
  },
  Sat: {
    id: 'global.Sat',
    defaultMessage: 'Sat',
  },
  Sun: {
    id: 'global.Sun',
    defaultMessage: 'Sun',
  },
  date: {
    id: 'scale-data-table.date',
    defaultMessage: 'Date',
  },
  login: {
    id: 'global.login',
    defaultMessage: 'Welcome to Allurion Insights. Log in to your account below.',
  },
  resetPassword: {
    id: 'global.resetPassword',
    defaultMessage: 'Reset Password',
  },
  resetPasswordEmailText: {
    id: 'global.resetPasswordEmailText',
    defaultMessage: 'Enter your email address below to reset your password.',
  },
  resetPasswordVerificationCodeText: {
    id: 'global.resetPasswordVerificationCodeText',
    defaultMessage: 'Please enter the verification code sent to your email.',
  },
  passwordSetup: {
    id: 'global.passwordSetup',
    defaultMessage:
      'Welcome to Allurion Insights. Please set up a password to complete your account.',
  },
  sessionTimeOut: {
    id: 'global.sessionTimeOut',
    defaultMessage: 'Your Session Has Timed Out',
  },
  helpEmail: {
    id: 'global.helpEmail',
    defaultMessage: 'For help, email',
  },
  minChars: {
    id: 'global.minChars',
    defaultMessage: 'Min 8 characters',
  },
  oneLower: {
    id: 'global.oneLower',
    defaultMessage: 'At least one lowercase letter.',
  },
  oneCapital: {
    id: 'global.oneCapital',
    defaultMessage: 'At least one uppercase letter.',
  },
  oneNumber: {
    id: 'global.oneNumber',
    defaultMessage: 'At least one number.',
  },
  oneSpecialChar: {
    id: 'global.oneSpecialChar',
    defaultMessage: 'At least one special char.',
  },
  required: {
    id: 'global.required',
    defaultMessage: 'Required',
  },
  passwordMustMatch: {
    id: 'global.passwordMatch',
    defaultMessage: 'Passwords must match',
  },
  notValidEmail: {
    id: 'global.notValidEmail',
    defaultMessage: 'Not a valid email',
  },
  UserNotFoundException: {
    id: 'cognito.UserNotFoundException',
    defaultMessage: 'Username does not exist',
  },
  CodeMismatchException: {
    id: 'cognito.CodeMismatchException',
    defaultMessage: 'Invalid verification code',
  },
  NotAuthorizedException: {
    id: 'cognito.NotAuthorizedException',
    defaultMessage: 'Username is not authorized',
  },
  UnknownException: {
    id: 'cognito.UnknownException',
    defaultMessage: 'Unknown error',
  },
  ExpiredCodeException: {
    id: 'cognito.ExpiredCodeException',
    defaultMessage:
      'Your invitation to Allurion Insights has expired.  Please contact your clinic admin user to resend your invitation.  Invitations expire after 48 hours.',
  },
  genericError: {
    id: 'generic-error',
    defaultMessage: 'There has been an error, please try again',
  },
  confirm: {
    id: 'global.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'global.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'global.save',
    defaultMessage: 'Save',
  },
  delete: {
    id: 'global.delete',
    defaultMessage: 'Delete',
  },
});
