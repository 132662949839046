import { useIntl } from 'react-intl';

import { ADMIN_ROLE_ID, COACH_ROLE_ID, STAFF_ROLE_ID, SUPER_ADMIN_ROLE_ID } from 'src/domain/User';
import translations from 'src/pages/ManageUsers/ManageUsers.translations';

export function useProviderRoleLabels() {
  const intl = useIntl();

  const superUserLabel = 'Super User';
  const adminLabel = intl.formatMessage(translations.manageUsersAdmin);
  const staffLabel = intl.formatMessage(translations.manageUsersStaff);
  const coachLabel = intl.formatMessage(translations.manageUsersCoach);

  return {
    1: { label: superUserLabel, value: SUPER_ADMIN_ROLE_ID },
    2: { label: adminLabel, value: ADMIN_ROLE_ID },
    3: { label: staffLabel, value: STAFF_ROLE_ID },
    4: { label: coachLabel, value: COACH_ROLE_ID },
  };
}
