export function SearchIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_431_34765)">
        <path
          d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38782 10.2789 2 7.33333 2C4.38782 2 2 4.38782 2 7.33333C2 10.2789 4.38782 12.6667 7.33333 12.6667Z"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
        />
        <path
          d="M14.0001 14.0001L11.1001 11.1001"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_431_34765">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
