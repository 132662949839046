import { Card, HelpIcon, IconButton, InputSelector, Tooltip } from '@allurion/ui';
import { isError, isNumber } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { ClinicSettings } from 'src/domain/Clinic';
import { Unit } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinic } from 'src/hooks/useClinic';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { Logger } from 'src/services/Logger';

import { ClinicOfferedTreatments } from './ClinicOfferedTreatments';
import translations from './ClinicSettings.translations';

import styles from './ClinicSettingsPage.module.scss';

export default function ClinicSettingsPage() {
  const intl = useIntl();
  const [isSaving, setIsSaving] = useState(false);
  const { clinicId } = useParams();
  const { settings, updateSettings } = useClinicSettings(clinicId);
  const [modifiedSettings, setModifiedSettings] = useState<Partial<ClinicSettings>>({});
  const { clinic } = useClinic(clinicId);
  const { toClinicPage } = useAppNavigate();
  const { trackFormSuccess, trackFormError } = useTrackEvent();
  const [isDirty, setIsDirty] = useState(false);
  const combinedSettings = { ...settings, ...modifiedSettings };

  if (!clinicId) {
    throw new Error('Invalid clinic');
  }

  const clinicAddress = !clinic?.address
    ? ''
    : `${clinic.address.address}${'\n'}${clinic.address.city}, ${clinic.address.country}`;
  const clinicName = clinic?.post_title;

  const weightUnitOptions: { label: string; value: Unit }[] = [
    { label: 'Kg', value: 'kg' },
    { label: 'Lb', value: 'lb' },
  ];

  return (
    <>
      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'clinic-settings.header',
          description: 'Clinic Settings page header',
          defaultMessage: 'Settings',
        })}
        button={{
          label: intl.formatMessage(translations.clinicSettingsSave),
          disabled: !isDirty,
          size: 'sm',
          trackLabel: 'submit-clinic-settings-form',
          onClick: () => onSave(combinedSettings),
        }}
      />
      <Seo title="Clinic Settings" />
      <div className={styles.container}>
        <div className={styles.column}>
          <Card
            title={
              <div className={styles.cardTitle}>
                {intl.formatMessage(translations.clinicSettingsClinicInformation)}
                <Tooltip
                  text={intl.formatMessage(translations.clinicSettingsClinicInformationTooltip)}
                  placement="bottom"
                >
                  <IconButton size="sm" variant="icon" icon={<HelpIcon />} />
                </Tooltip>
              </div>
            }
          >
            <ul className={styles.infoPanel}>
              <li>
                <strong className={styles.label}>
                  {intl.formatMessage(translations.clinicNameFieldLabel)}
                </strong>
                <span className={styles.value}>{clinicName}</span>
              </li>
              <li>
                <strong className={styles.label}>
                  {intl.formatMessage(translations.clinicAddressFieldLabel)}
                </strong>
                <span className={styles.value}>{clinicAddress}</span>
              </li>
              <li className={styles.withAction}>
                <div className={styles.labelAndValue}>
                  <strong className={styles.label}>
                    {intl.formatMessage(translations.clinicIdFieldLabel)}
                  </strong>
                  <span className={styles.value}>{clinic?.ID}</span>
                </div>
                <div className={styles.action}>
                  <Tooltip
                    text={intl.formatMessage(translations.clinicIdTooltip)}
                    placement="bottom"
                  >
                    <IconButton size="sm" variant="icon" icon={<HelpIcon />} />
                  </Tooltip>
                </div>
              </li>
            </ul>
          </Card>
          <Card
            title={
              <div className={styles.cardTitle}>
                {intl.formatMessage(translations.clinicSettingsUnitMeasurement)}
                <Tooltip
                  text={intl.formatMessage(translations.clinicSettingsUnitMeasurementTooltip)}
                  placement="bottom"
                >
                  <IconButton size="sm" variant="icon" icon={<HelpIcon />} />
                </Tooltip>
              </div>
            }
          >
            <div className={styles.measureUnitContainer}>
              {weightUnitOptions.map(({ label, value }) => (
                <InputSelector
                  type="radio"
                  label={label}
                  checked={combinedSettings.weightUnit === value}
                  onChange={({ currentTarget: { checked } }) =>
                    checked && onWeightUnitChange(value)
                  }
                  key={value}
                />
              ))}
            </div>
          </Card>
        </div>
        <div className={styles.column}>
          <Card
            title={
              <div className={styles.cardTitle}>
                {intl.formatMessage(translations.clinicSettingsTreatments)}
                <Tooltip
                  text={intl.formatMessage(translations.clinicSettingsTreatmentsTooltip)}
                  placement="bottom"
                >
                  <IconButton size="sm" variant="icon" icon={<HelpIcon />} />
                </Tooltip>
              </div>
            }
          >
            <ClinicOfferedTreatments
              clinicId={clinicId}
              treatmentsOffered={combinedSettings.treatmentsOffered}
              onChange={onOfferedTreatmentChange}
            />
          </Card>
        </div>
      </div>
      <Loader isLoading={isSaving} />
    </>
  );

  function onOfferedTreatmentChange(treatmentId: number, checked: boolean) {
    const isIncluded = combinedSettings.treatmentsOffered?.includes(treatmentId);

    if (checked && !isIncluded) {
      setModifiedSettings({
        ...combinedSettings,
        treatmentsOffered: [...(combinedSettings.treatmentsOffered || []), treatmentId],
      });
    } else if (!checked && isIncluded) {
      setModifiedSettings({
        ...combinedSettings,
        treatmentsOffered: combinedSettings.treatmentsOffered?.filter((id) => id !== treatmentId),
      });
    }

    setIsDirty(true);
  }

  function onWeightUnitChange(unit: string) {
    setModifiedSettings((prev) => ({ ...prev, weightUnit: unit }));

    setIsDirty(true);
  }

  async function onSave(values: ClinicSettings) {
    if (!clinicId) {
      return;
    }

    setIsSaving(true);
    try {
      const parsedTreatmentsOffered = values?.treatmentsOffered
        ?.map(Number)
        .filter((v) => isNumber(v) && !Number.isNaN(v));

      await updateSettings({
        ...values,
        treatmentsOffered: parsedTreatmentsOffered,
      });

      toastSuccess(intl.formatMessage(translations.clinicSettingsSuccess));
      trackFormSuccess('clinic-settings');

      toClinicPage();
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      toastError(intl.formatMessage(translations.clinicSettingsFailure));
      trackFormError('clinic-settings', { error: errorMessage });
    } finally {
      setIsSaving(false);
    }
  }
}
