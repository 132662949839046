/**
 *
 * Tooltips
 *
 *  */

import baseTooltip from 'src/components/ChartJS/chart-tooltip';

export const tooltips: any = (tooltipLabelFormatter: any, custom: any) => ({
  ...baseTooltip,
  callbacks: {
    title() {},
    label: tooltipLabelFormatter,
  },
  custom,
  intersect: true,
  mode: 'nearest',
  xPadding: 10,
  yPadding: 10,
});

export default tooltips;
