import { isError, isString } from './is-type';

export function enhanceError(error: unknown, { prefix }: { prefix: string }) {
  if (isError(error)) {
    error.message = `${prefix}: ${error.message}`;
    return error;
  }

  if (isString(error)) {
    return `${prefix}: ${error}`;
  }

  return error;
}
