import { Tabs } from '@allurion/ui';
import { strToDate } from '@allurion/utils';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ScaleDataLineChart } from 'src/components/ScaleDataLineChart/ScaleDataLineChart';
import { PatientWeight } from 'src/domain/patient/Patients';
import { fixNumbers, getUnitFactor } from 'src/helpers/convertions';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { usePatientWeight } from 'src/hooks/usePatientWeight';

import messages from './datamenu-messages';

import styles from './PatientScaleData.module.scss';

type Props = {
  patientId: string;
};

export function PatientScaleDataProgressChart({ patientId }: Props) {
  const intl = useIntl();
  const { weightData: patientWeight } = usePatientWeight(patientId);
  const { unitsPreference } = useCurrentUser();
  let unitFactor = 1.0;

  const dataItems = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: messages.weight.id }),
        id: 'Weight',
        units: unitsPreference,
      },
      {
        label: intl.formatMessage({ id: messages.bmi.id }),
        id: 'BMI',
        units: '',
      },
      {
        label: intl.formatMessage({ id: messages.bodyFat.id }),
        id: 'BodyFat',
        units: '%',
      },
      {
        label: intl.formatMessage({ id: messages.muscleMass.id }),
        id: 'MuscleMass',
        units: unitsPreference,
      },
      {
        label: intl.formatMessage({ id: messages.boneMass.id }),
        id: 'Bone',
        units: unitsPreference,
      },
      {
        label: intl.formatMessage({ id: messages.bodyWater.id }),
        id: 'BodyWater',
        units: '%',
      },
      {
        label: intl.formatMessage({ id: messages.bmr.id }),
        id: 'BMR',
        units: 'kcal',
      },
      {
        label: intl.formatMessage({ id: messages.visceralFat.id }),
        id: 'VisceralFat',
        units: '',
      },
    ],
    [intl, unitsPreference]
  );

  const [activeItem, setActiveItem] = useState(dataItems[0]);

  // apply unit factor only to Weight, Muscle Mass, Bone Mass
  if (['Weight', 'MuscleMass', 'Bone'].includes(activeItem.id)) {
    unitFactor = getUnitFactor(unitsPreference);
  }

  const formattedData = useMemo(() => {
    if (!patientWeight) {
      return [];
    }

    const weightEntriesByDate = patientWeight.reduce(
      (acc, entry) => {
        const date = entry.CreatedDate.slice(0, 10);

        return {
          ...acc,
          [date]: [...(acc[date] || []), entry],
        };
      },
      {} as Record<string, PatientWeight[]>
    );

    const avgEntries = (Object.values(weightEntriesByDate) as PatientWeight[][]).map((entries) => {
      const metrics: Record<string, number> = {};

      ['Weight', 'BMI', 'BodyFat', 'BodyWater', 'Bone', 'VisceralFat', 'BMR', 'MuscleMass'].forEach(
        (attribute) => {
          const values = entries
            .map((entry) => (entry[attribute] ? parseFloat(entry[attribute]) : null))
            .filter(Boolean) as number[];
          const sum = values.reduce((a, b) => a + b, 0);
          const avg = sum / values.length || 0;

          metrics[attribute] = avg;
        }
      );

      return {
        CreatedDate: strToDate(entries[0].CreatedDate),
        ...metrics,
      };
    });

    return [...avgEntries]
      .sort((a, b) => {
        return a.CreatedDate.getTime() - b.CreatedDate.getTime();
      })
      .map((data) => ({
        x: data.CreatedDate.getTime(),
        y: fixNumbers((data as any)[activeItem.id], 1, unitFactor),
      }));
  }, [activeItem.id, patientWeight, unitFactor]);

  return (
    <div>
      <div className={styles.progress__tabsWrapper} style={{ marginBottom: '36px' }}>
        <Tabs
          direction="row"
          tabs={dataItems}
          selectedTabId={activeItem.id}
          onTabChange={(tab) => setActiveItem(dataItems.find((item: any) => tab.id === item.id)!)}
        />
      </div>
      <ScaleDataLineChart
        id="scale-data-line-chart"
        data={formattedData}
        units={activeItem.units}
      />
    </div>
  );
}
