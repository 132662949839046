import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';

type Props = {
  id: string;
  data: {
    color: string;
    label: string;
    value: number;
  }[];
  unit: string;
};

Chart.defaults.font.family = "'GT-America-Standard-Regular', sans-serif";

export function DailyActionsDoughnutChart({ id, data, unit }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const datasets: any = [
      {
        label: unit,
        data: data.map((d) => d.value),
        backgroundColor: data.map((d) => d.color),
      },
    ];

    // @ts-ignore
    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: data.map((d) => d.label),
        datasets,
      },
      options: {
        events: [],
        responsive: true,
        maintainAspectRatio: false,

        animation: {
          animateRotate: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      chartRef.current?.destroy();
    };
  }, [data, unit]);

  return <canvas id={id} ref={canvasRef} />;
}
