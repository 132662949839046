import { PageHeader } from '@allurion/ui';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { AddPatientData } from 'src/pages/InvitePatientPage/AddPatient.types';

import { Container, InnerContainer } from '../shared-page-elements';

import translations from './AddPatientPage.translations';

type Props = {
  data: AddPatientData;
};

export function InvitePatientSuccess({ data }: Props) {
  const intl = useIntl();
  const { toClinicPage } = useAppNavigate();
  const { clinicId } = useParams();

  const onDone = () => {
    toClinicPage(clinicId);
  };

  const { firstName, lastName, email } = data;
  const name = `${firstName} ${lastName}`;

  return (
    <>
      <PageHeader title={intl.formatMessage(translations.header)} />
      <Container>
        <InnerContainer>
          <Title>{intl.formatMessage(translations.added, { name })}</Title>
          <Description>
            <Invitation>{intl.formatMessage(translations.invitation, { email })}</Invitation>
            <Instructions>{intl.formatMessage(translations.instructions, { name })}</Instructions>
            <Expiration>{intl.formatMessage(translations.expiration)}</Expiration>
          </Description>
          <TrackedButton
            label={intl.formatMessage(translations.done)}
            onClick={onDone}
            trackLabel="confirm-invite-patient-success"
          />
        </InnerContainer>
      </Container>
    </>
  );
}

const Description = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.h2`
  font-weight: bolder;
  font-size: 28px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Primary};
  margin-bottom: 35px;
  margin-top: 25px;
`;

const Invitation = styled.p`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  padding-bottom: 10px;
`;

const Instructions = styled.p`
  font-family: 'GT-America-Standard-Light', sans-serif;
  padding-bottom: 10px;
`;

const Expiration = styled.p`
  font-family: 'GT-America-Standard-Light', sans-serif;
`;
