import { isString } from '@allurion/utils';
import { useField } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  row?: boolean;
  options: { label: string; description?: string; value: string }[];
  onSelect: Function;
  defaultValue?: string | number | readonly string[];
  className?: string;
  cyErrorLabelSelector?: string;
  name?: string;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
};

export function AllurionRadioButtons({
  row = false,
  options,
  onSelect,
  defaultValue,
  className,
  ...props
}: Props) {
  const [selectedValue, setSelectedValue] = useState<string | number | readonly string[]>();
  const [field] = useField(props as any);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setSelectedValue(value);
    onSelect({ value });
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <Container className={className} row={row}>
      {options?.map(({ label, value, description }) => (
        <React.Fragment key={value}>
          <span>
            <input
              {...field}
              type="radio"
              value={value}
              checked={selectedValue === value}
              onChange={handleChange}
              aria-label={label}
            />
            {label}
          </span>
          {description && <LabelDescription>{description}</LabelDescription>}
        </React.Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div<any>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  font-family: 'GT-America-Standard', sans-serif;
  input[type='radio'] {
    cursor: pointer;
    accent-color: ${({ theme }) => theme.colors.Primary};
    transform: scale(1.3);
    color: ${({ theme, haserror }) =>
      haserror && isString(haserror) ? theme.colors.Alert : theme.colors.Primary} !important;
    border: 1px solid ${({ theme }) => theme.colors.Primary};
    margin-right: 8px;
    margin-left: 0px;
    width: 18px;
  }
  span {
    display: flex;
    align-items: center;
    font-family: 'GT-America-Standard', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 10px 10px 0px;
  }
`;

const LabelDescription = styled.span`
  font-size: 13px;
`;
