import { isError } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { createProviderPassword } from 'src/api/UserApi';
import { Footer } from 'src/components/Footer/Footer';
import { Seo } from 'src/components/Seo';
import { Loader } from 'src/components/ui/Loader';
import { CreatePasswordForm } from 'src/forms/CreatePassword/CreatePasswordForm';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import LogoImgSrc from 'src/images/wordmark-white.svg';
import globalMessages from 'src/messages/global.messages';

import { FullscreenContainer } from './shared-page-elements';

export default function CreatePasswordPage() {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { username, confirmationCode } = useParams();
  const { toLoginPage } = useAppNavigate();

  async function createPassword(newPassword: string) {
    try {
      setIsLoading(true);
      await createProviderPassword(username!, newPassword, confirmationCode!);
      setIsLoading(false);
      toLoginPage(false);
    } catch (error) {
      if (isError(error)) {
        switch (error.message) {
          case 'user not found':
            setErrorMessage(intl.formatMessage(globalMessages.UserNotFoundException));
            break;

          case 'Confirmation code expired':
            setErrorMessage(intl.formatMessage(globalMessages.ExpiredCodeException));
            break;
        }
      } else {
        setErrorMessage(intl.formatMessage(globalMessages.UnknownException));
      }

      setIsLoading(false);
    }
  }

  return (
    <FullscreenContainer>
      <Seo title="Create Password" />
      {errorMessage ? (
        <Content>
          <LogoTitleWrapperLink to="/">
            <Logo src={LogoImgSrc} alt="Allurion" />
          </LogoTitleWrapperLink>
          <Text>{errorMessage}</Text>
        </Content>
      ) : (
        <Content>
          <LogoTitleWrapperLink to="/manage-users">
            <Logo src={LogoImgSrc} alt="Allurion" />
          </LogoTitleWrapperLink>
          <Text>{intl.formatMessage(globalMessages.passwordSetup)}</Text>
          <CreatePasswordForm
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            onSubmit={(values: any) => {
              const { password } = values;

              createPassword(password);
            }}
          />
        </Content>
      )}

      <Footer dark />
      <Loader isLoading={isLoading} />
    </FullscreenContainer>
  );
}

const LogoTitleWrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
`;

const Logo = styled.img`
  margin-bottom: 3em;
  height: 72px;
  width: auto;
`;

const Content = styled.div`
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  ${({ theme }) => theme.media.min.mobile`
    margin-top: 100px;
  `}
`;

const Text = styled.p`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-width: 520px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
`;
