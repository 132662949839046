import uniqueId from 'lodash.uniqueid';
import styled from 'styled-components';

import { ScrollingMenu } from 'src/components/ScrollingMenu';

import { StatCard } from './StatCard';

type Props = {
  data: { value: string | number; label: any }[];
};

export function StatsScrollingList({ data }: Props) {
  return (
    <>
      <Smaller>
        <ScrollingMenu
          data={data}
          renderCard={(item: any, idx: number) => (
            <MenuItem key={uniqueId('scroll-stat-card_')} idx={idx}>
              <StatCard data={item} />
            </MenuItem>
          )}
        />
      </Smaller>
      <Bigger>
        <List>
          {data.map((item: any, idx: number) => (
            <ListItem key={uniqueId('list-stat-card_')} idx={idx}>
              <StatCard data={item} />
            </ListItem>
          ))}
        </List>
      </Bigger>
    </>
  );
}

const List = styled.ul`
  display: flex;
`;

const ListItem = styled.li<{ idx: number }>`
  display: flex;
  padding-left: 0px;
`;

const MenuItem = styled.div<{ idx: number }>`
  display: flex;
  height: 100px;
  align-items: stretch;
`;

const Smaller = styled.div`
  ${({ theme }) => theme.media.min.desktopXL`
    display: none;
  `}
`;
const Bigger = styled.div`
  ${({ theme }) => theme.media.max.desktopXL`
    display: none;
  `}
`;
