import { Suspense, useEffect, useState } from 'react';
import { useParams, Outlet, useLocation, Navigate } from 'react-router-dom';

import { ClinicSidebar } from 'src/components/ClinicSidebar';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { MobileMenu } from 'src/components/MobileMenu/MobileMenu';
import { Seo } from 'src/components/Seo';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { useUserClinics } from 'src/hooks/useUserClinics';
import { useUserClinicsSettings } from 'src/hooks/useUserClinicsSettings';
import { toClinicPatientsPage } from 'src/utils/appRoutes';

import styles from './ClinicPage.module.scss';

export function ClinicPage() {
  const { clinicId } = useParams();
  const location = useLocation();
  const [sidebarVisibleOnMobile, setSidebarVisibleOnMobile] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const { clinics } = useUserClinics();
  const { clinicHasFeature } = useUserClinicsSettings();

  // FIXME: why do we need this?
  useEffect(() => {
    sessionStorage.removeItem('add-patient-form');
  }, []);

  if (!clinicId) {
    const lastClinicId = sessionStorage.getItem('clinicId');

    if (lastClinicId) {
      const isLastClinicAvailable = clinics.some((clinic) => clinic.ID === lastClinicId);

      if (isLastClinicAvailable) {
        return <Navigate to={toClinicPatientsPage(lastClinicId)} replace={true} />;
      }
    }

    if (clinics.length > 0) {
      return <Navigate to={toClinicPatientsPage(clinics[0].ID)} replace={true} />;
    }

    return;
  }

  if (/^\/clinic\/\d+$/.test(location.pathname)) {
    return <Navigate to={toClinicPatientsPage(clinicId)} replace={true} />;
  }

  return (
    <>
      <Seo title="Clinic" />
      <ClinicSidebar
        isMenuCollapsed={isMenuCollapsed}
        visibleOnMobile={sidebarVisibleOnMobile}
        setVisibleOnMobile={setSidebarVisibleOnMobile}
        setIsMenuCollapsed={setIsMenuCollapsed}
        clinics={clinics}
        clinicId={clinicId}
        clinicHasFeature={clinicHasFeature}
      />
      <div className={styles.innerContainer}>
        <ErrorBoundary>
          <Suspense fallback={<InlineLoader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </div>
      <Suspense fallback={<></>}>
        <MobileMenu
          clinicId={clinicId}
          setIsMenuCollapsed={setIsMenuCollapsed}
          setSidebarVisible={setSidebarVisibleOnMobile}
          clinicHasFeature={clinicHasFeature}
        />
      </Suspense>
    </>
  );
}
