import { createBridge, ContextMenu, useContextMenu } from 'react-context-menu-hooks';
import styled from 'styled-components';
import 'react-context-menu-hooks/src/ContextMenu.css';

export interface ContextMenuTriggerData {
  select: (action: any) => typeof action;
}

export const menuBridge = createBridge<ContextMenuTriggerData>({
  select: (action: any) => action,
});

type Props = {
  menuOptions: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
};

export function MouseContextMenu({ menuOptions }: Props) {
  const { select } = useContextMenu(menuBridge);

  const handleOnSelect = (action: any) => {
    select(action);
  };

  // NOTE: <span> wrapper helps to dismiss the custom context menu :)
  return (
    <span>
      <StyledContextMenu bridge={menuBridge} onSelect={handleOnSelect}>
        {menuOptions?.map(({ label, value, disabled = false }) => (
          <StyledContextMenu.Option key={`ctx-menu_${value}`} disabled={disabled} select={value}>
            {label}
          </StyledContextMenu.Option>
        ))}
      </StyledContextMenu>
    </span>
  );
}

const StyledContextMenu = styled(ContextMenu)<any>`
  border: 1px solid;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.Silver};
  background-color: ${({ theme }) => theme.colors.White} !important;
  a {
    padding: 1em;
    font-size: 16px !important;
    color: ${({ theme }) => theme.colors.Primary} !important;
    cursor: pointer !important;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 90%;
      border-bottom: 1px solid;
      border-color: ${({ theme }) => theme.colors.Silver};
      padding-bottom: 1em;
    }
    &:last-child:after {
      content: '';
      display: block;
      border-bottom: 0 !important;
    }
  }
`;
