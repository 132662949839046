// TODO: Add suffix *Route to constants
export const createPassword = '/create-password';
export const resetPassword = '/reset-password';
export const manageUsers = '/manage-users';
export const editUser = (providerId: string) => `${manageUsers}/${providerId}/edit`;
export const resendInvitation = `${manageUsers}/resend-invitation`;
export const validateEmail = '/reset-password/validate-email';
export const addPatientRoute = '/add-patient';
export const clinicRoute = '/clinic';
export const toClinicPage = (clinicId: string) => `/clinic/${clinicId}`;
export const clinicSettingsRoute = `/clinic-settings`;
export const toClinicSettings = (clinicId: string) => `/clinic/${clinicId}/settings`;
export const profileRoute = '/profile';
export const loginRoute = '/';
export const feedbackRoute = '/feedback';
export const termsConditionsRoute = '/terms-conditions';
export const managePatientsPage = (clinicId: string) => `/clinic/${clinicId}/organize-patients`;
export const messageNotifications = '/message-notifications';
export const toClinicPatientsPage = (clinicId: string) => `/clinic/${clinicId}/patients`;
export const toClinicAnalytics = (clinicId: string) => `/clinic/${clinicId}/analytics`;
export const toClinicProviders = (clinicId: string) => `/clinic/${clinicId}/members`;
