import { Badge } from '@allurion/ui';
import { toLocalizedDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { DailyAction } from 'src/domain/patient/Patients';
import { useLocale } from 'src/hooks/useLocale';

import { DailyActionsOverview } from './DailyActionsOverview';

import styles from './DailyActions.module.scss';

export function DailyActionCard({ dailyAction }: { dailyAction: DailyAction }) {
  const intl = useIntl();
  const { locale } = useLocale();

  return (
    <div className={styles.card}>
      <div className={styles.title}>{dailyAction.title}</div>

      <ul className={styles.rows}>
        <li>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'daily-actions-table.category',
              defaultMessage: 'Category',
            })}
          </span>
          <span className={styles.value}>{dailyAction.category}</span>
        </li>
        <li>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'daily-actions-table.status',
              defaultMessage: 'Status',
            })}
          </span>
          <span className={styles.value}>
            {!dailyAction.end_date ? (
              <Badge
                label={intl.formatMessage({
                  id: 'daily-actions-table.currently-tracking',
                  defaultMessage: 'Currently tracking',
                })}
                variant="success"
                size="sm"
              />
            ) : (
              <Badge
                label={intl.formatMessage({
                  id: 'daily-actions-table.stopped-period',
                  defaultMessage: 'Stopped tracking',
                })}
                variant="secondary"
                size="sm"
              />
            )}
          </span>
        </li>
        <li>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'daily-actions-table.tracking-period',
              defaultMessage: 'Tracking Period',
            })}
          </span>
          <span className={styles.value}>
            {`${toLocalizedDate(dailyAction.start_date, locale)} - `}
            {dailyAction.end_date
              ? toLocalizedDate(dailyAction.end_date, locale)
              : intl.formatMessage({
                  id: 'daily-actions-table.tracking-period.today',
                  defaultMessage: 'Today',
                })}
          </span>
        </li>
        <li>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'daily-actions-table.total-days',
              defaultMessage: 'Total Days',
            })}
          </span>
          <span className={styles.value}>
            {dailyAction.total_days}
            {` ${intl.formatMessage({
              id: 'daily-actions-table.days',
              defaultMessage: 'days',
            })}`}
          </span>
        </li>
        <li>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'daily-actions-table.progress-overview',
              defaultMessage: 'Progress overview',
            })}
          </span>
          <span className={styles.value}>
            <DailyActionsOverview
              id={dailyAction.daily_action_id}
              completed={dailyAction.completed}
              not_completed={dailyAction.not_completed}
              no_response={dailyAction.no_response}
            />
          </span>
        </li>
      </ul>
    </div>
  );
}
