import { css } from 'styled-components';

// TODO: Rename colors
export const Primary = '#00363F';
export const Secondary = '#DEE0E0';
export const Ivory = '#FFF8F0';
export const DarkBlue = '#2F3F83';
export const GraphBlue = '#8590BD';
export const RiseBlue = '#9AA6DA';
export const SeaGreen = '#77989E';
export const MintGreen = '#A68A8A';
export const DarkGrey = '#56585C';
export const Alert = '#A66565';
export const Success = '#81A010';
export const BrightGreen = '#ADD910';
export const DarkSilver = '#D1D4D4';
export const GreyBorder = '#DEE0E0';
export const LinkBlue = '#0c33d0';
export const SoftGrey = '#8e8e8e';
export const SoftGreen = '#DEEAB3';
export const White = '#FFFFFF';
export const Silver = '#F2F5F5';
export const SoftSilver = '#FAFAFA';
export const SilverBorder = '#D6D6D6';
export const Facebook = '#4469b0';
export const Twitter = '#38A1F3';
export const ProviderColor = '#9aa5da';
export const PatientColor = '#deeab2';

export const Gradient = (angle = 'to right', colorA = Primary, colorB = Secondary) => css`
  background: ${colorA}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    ${angle},
    ${colorA},
    ${colorB}
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    ${angle},
    ${colorA},
    ${colorB}
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

// default footer gradient
export const gradient = Gradient('to right');

export const colors = {
  Alert,
  Primary,
  Secondary,
  SeaGreen,
  MintGreen,
  Silver,
  SoftSilver,
  SilverBorder,
  DarkGrey,
  GreyBorder,
  White,
  Ivory,
  DarkBlue,
  GraphBlue,
  RiseBlue,
  BrightGreen,
  DarkSilver,
  SoftGrey,
  SoftGreen,
  LinkBlue,
  Loader: Primary,
  Facebook,
  Twitter,
  Success,
};

export default colors;
