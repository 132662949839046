import { useCallback, useEffect, useRef } from 'react';

export function useDebounceCallback<T extends (...args: any[]) => any>(callback: T, delay: number) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback(
    (...args: any[]) => {
      const handler = setTimeout(() => callbackRef.current(...args), delay);

      return () => clearTimeout(handler);
    },
    [delay]
  );
}
