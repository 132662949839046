export function DownloadIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_404_20793)">
        <path d="M1 10L1 15L15 15L15 10" stroke="currentColor" strokeWidth="1.1" />
        <path d="M5 5L8 8L11 5" stroke="currentColor" strokeWidth="1.1" strokeLinecap="square" />
        <path
          d="M8 7L8 0"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_404_20793">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
