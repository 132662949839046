import baseTooltip from 'src/components/ChartJS/chart-tooltip';

export const tooltips: any = (units: string) => ({
  ...baseTooltip,
  callbacks: {
    title: () => null,
    label: (tooltipItem: any) => `${tooltipItem?.parsed.y} ${units}`,
  },
  mode: 'nearest',
});

export default tooltips;
