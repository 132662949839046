import { defineMessages } from 'react-intl';

export default defineMessages({
  loginFailure: {
    id: 'login.failure',
    defaultMessage: 'Failed to log in.  Email not found or password not correct.',
  },
  passwordResetRequestFailure: {
    id: 'passwordResetRequest.failure',
    defaultMessage: 'Failed to request reset of password.',
  },
  passwordResetRequestSuccess: {
    id: 'passwordResetRequest.succcess',
    defaultMessage: 'Verification code sent to your email address.',
  },
  passwordResetFailure: {
    id: 'passwordReset.failure',
    defaultMessage: 'Failed to reset password.',
  },
  passwordResetSuccess: {
    id: 'passwordReset.succcess',
    defaultMessage: 'Password successfully reset.',
  },
  sessionTimeout: {
    id: 'api.sessionTimeout',
    defaultMessage: 'Session Timed Out.  Please log back in.',
  },
});
