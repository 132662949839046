export function TrashCanIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4H3.33333H14" stroke="currentColor" strokeWidth="1.2" strokeLinecap="square" />
      <path
        d="M12.6668 4.00065V13.334V14.6673H11.3335H4.66683H3.3335V13.334V4.00065M5.3335 4.00065V2.66732C5.3335 2.3137 5.47397 1.97456 5.72402 1.72451C5.97407 1.47446 6.31321 1.33398 6.66683 1.33398H9.3335C9.68712 1.33398 10.0263 1.47446 10.2763 1.72451C10.5264 1.97456 10.6668 2.3137 10.6668 2.66732V4.00065"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="square"
      />
    </svg>
  );
}
