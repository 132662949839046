import { useIntl } from 'react-intl';

import { dailyActionLegend } from './daily-actions';

import styles from './DailyActions.module.scss';

export function OverviewLegend() {
  const intl = useIntl();

  return (
    <ul className={styles.overviewLegend}>
      <li style={{ color: dailyActionLegend.completed.color }}>
        <span>{intl.formatMessage(dailyActionLegend.completed.message)}</span>
      </li>
      <li style={{ color: dailyActionLegend.not_completed.color }}>
        <span>{intl.formatMessage(dailyActionLegend.not_completed.message)}</span>
      </li>
      <li style={{ color: dailyActionLegend.no_response.color }}>
        <span>{intl.formatMessage(dailyActionLegend.no_response.message)}</span>
      </li>
    </ul>
  );
}
