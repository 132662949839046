import './reset.scss';
import './index.scss';

import { Auth, Amplify } from 'aws-amplify';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { LanguageWrapper } from 'src/components/LanguageWrapper';
import { Loader } from 'src/components/ui/Loader';
import createStore from 'src/state/store';
import Theme from 'src/styles/theme';
import currentEnv from 'src/utils/env';

import { AppRoutes } from './Routes';

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(<App />);

const currentConfig = Auth.configure();

Auth.configure({
  ...currentConfig,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: currentEnv.COGNITO_USER_POOL_ID,
    userPoolWebClientId: currentEnv.COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

const { persistor, store } = createStore();

export default function App() {
  return (
    <Suspense fallback={<Loader cover isLoading />}>
      <Provider store={store}>
        <PersistGate loading={<Loader cover isLoading />} persistor={persistor}>
          <LanguageWrapper>
            <ThemeProvider theme={Theme}>
              <AppRoutes />
            </ThemeProvider>
          </LanguageWrapper>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
