import { defineMessages } from 'react-intl';

export default defineMessages({
  actionCompleteSuccess: {
    defaultMessage: 'Success',
    id: 'user-updated.title',
  },
  actionCompleteClinicName: {
    defaultMessage: 'Clinics',
    id: 'invitation-sent.clinicName',
  },
  actionCompleteCloseButton: {
    defaultMessage: 'CLOSE',
    id: 'invitation-sent.closeButton',
  },
  actionCompleteDeleteTitle: {
    defaultMessage: 'The following user has been deleted from Allurion Insights.',
    id: 'invitation-sent.deleteTitle',
  },
  actionCompleteEmail: {
    defaultMessage: 'Email Address',
    id: 'invitation-sent.email',
  },
  actionCompleteName: {
    defaultMessage: 'First Name and Last Name',
    id: 'invitation-sent.name',
  },
  actionCompleteRole: {
    defaultMessage: 'Role',
    id: 'invitation-sent.role',
  },
  actionCompleteTitle: {
    defaultMessage:
      'The following user has been invited to Allurion Insights. They will receive an email to complete their registration.',
    id: 'invitation-sent.title',
  },
  actionCompleteUpdateTitle: {
    defaultMessage: 'The following user has been updated',
    id: 'user-updated.message',
  },
  expirationWarningCreatePasswordExpiration: {
    defaultMessage: 'Please note the invitation will expire after 48 hours on ',
    id: 'create-new-users.createPasswordExpiration',
  },
  manageUsersAdmin: {
    defaultMessage: 'Admin',
    id: 'create-new-users-form.admin',
  },
  manageUsersCoach: {
    defaultMessage: 'coach',
    id: 'create-new-users-form.coach',
  },
  manageUsersStaff: {
    defaultMessage: 'Staff',
    id: 'create-new-users-form.staff',
  },
  manageUsersTableClinic: {
    defaultMessage: 'Clinic',
    id: 'manage-users-table.clinic',
  },
  manageUsersTableEdit: {
    defaultMessage: 'Edit',
    id: 'manage-users-table.edit',
  },
  manageUsersTableEditProfile: {
    defaultMessage: 'Edit Profile',
    id: 'manage-users-table.editProfile',
  },
  manageUsersTableEmail: {
    defaultMessage: 'Email',
    id: 'manage-users-table.email',
  },
  manageUsersTableFirstName: {
    defaultMessage: 'First Name',
    id: 'manage-users-table.firstName',
  },
  manageUsersTableInvited: {
    defaultMessage: 'Invited',
    id: 'manage-users-table.invited',
  },
  manageUsersTableLastName: {
    defaultMessage: 'Last Name',
    id: 'manage-users-table.lastName',
  },
  manageUsersTableResendInvite: {
    defaultMessage: 'Resend Invite',
    id: 'manage-users-table.resendInvite',
  },
  manageUsersTableRole: {
    defaultMessage: 'Role',
    id: 'manage-users-table.role',
  },
  pageHeaderButton: {
    defaultMessage: 'CREATE NEW USER',
    id: 'manage-users-header.button',
  },
  pageHeaderSearchPlaceholder: {
    defaultMessage: 'Search by Name, Clinic, etc.',
    id: 'manage-users.searchPlaceholder',
  },
  pageHeaderTitle: {
    defaultMessage: 'Manage Users',
    id: 'manage-users-header.title',
  },
  resendInvitationCancelGoBack: {
    defaultMessage: 'Cancel and go back',
    id: 'resend-invitation.cancelGoBack',
  },
  resendInvitationCancelNoDelete: {
    defaultMessage: 'Cancel. Do not delete user',
    id: 'resend-invitation.cancelNoDelete',
  },
  resendInvitationClinic: {
    defaultMessage: 'Clinics',
    id: 'resend-invitation.clinic',
  },
  resendInvitationClose: {
    defaultMessage: 'Close',
    id: 'resend-invitation.close',
  },
  resendInvitationDeleteFailure: {
    defaultMessage: 'Failed to delete User',
    id: 'resend-invitation.deleteFailure',
  },
  resendInvitationDeleteSuccess: {
    defaultMessage: 'User Deleted',
    id: 'resend-invitation.deleteSuccess',
  },
  resendInvitationDeleteUser: {
    defaultMessage: 'Delete User',
    id: 'resend-invitation.deleteUser',
  },
  resendInvitationDeleteUserTitle: {
    defaultMessage: 'The following user will be deleted from Allurion Insights. ',
    id: 'resend-invitation.deleteUserTitle',
  },
  resendInvitationDeleteUserWarning: {
    defaultMessage:
      'You are deleting this user from Allurion Insights. They will no longer have access.',
    id: 'resend-invitation.deleteUserWarning',
  },
  resendInvitationEmail: {
    defaultMessage: 'Email Address',
    id: 'resend-invitation.email',
  },
  resendInvitationHasBeenDeletedUserTitle: {
    defaultMessage: 'The following user has been deleted from Allurion Insights.',
    id: 'resend-invitation.hasBeenDeletedUserTitle',
  },
  resendInvitationNameAndLastName: {
    defaultMessage: 'First Name and Last Name',
    id: 'resend-invitation.nameAndLastName',
  },
  resendInvitationResendFailure: {
    defaultMessage: 'Failed to send Invitation',
    id: 'resend-invitation.resendFailure',
  },
  resendInvitationResendInvite: {
    defaultMessage: 'Resend Invite',
    id: 'resend-invitation.resendInvite',
  },
  resendInvitationResendSuccess: {
    defaultMessage: 'Invitation successfully resent',
    id: 'resend-invitation.resendSuccess',
  },
  resendInvitationRole: {
    defaultMessage: 'Role',
    id: 'resend-invitation.role',
  },
  resendInvitationTitle: {
    defaultMessage:
      'Resend an invitation to the following user for Allurion Insights. If they are not receiving the invite in their email, ask them to check their spam folder.',
    id: 'resend-invitation.title',
  },
  searchPlaceholder: {
    defaultMessage: 'Search by Name, Clinic, etc.',
    id: 'manage-users.searchPlaceholder',
  },
  userFormWrapperAdminWarning: {
    defaultMessage:
      'You are adding this user as an Admin user, which means they will be able to add and delete users as well as make other users Admin users.',
    id: 'create-new-users.adminWarning',
  },
  userFormWrapperCancelCreateUser: {
    defaultMessage: 'Cancel. Do not create user',
    id: 'create-new-users.cancelCreateUser',
  },
  userFormWrapperCreateNewUser: {
    defaultMessage: 'Create New User ',
    id: 'create-new-users.createNewUser',
  },
  userFormWrapperCreatePassword: {
    defaultMessage:
      'The new user will be invited to create a password so they can log in with their email address.',
    id: 'create-new-users.createPassword',
  },
  userFormWrapperDeleteUserFailure: {
    defaultMessage: 'Failure to Delete User',
    id: 'create-new-users.deleteUserFailure',
  },
  userFormWrapperDuplicateUserMessage: {
    defaultMessage: 'Email address already exists.',
    id: 'create-new-users.duplicateUserMessage',
  },
  userFormWrapperEditUser: {
    defaultMessage: 'Edit User',
    id: 'create-new-users.editUser',
  },
  userFormWrapperEditUserFailure: {
    defaultMessage: 'Failure to Edit User',
    id: 'create-new-users.editUserFailure',
  },
  userFormWrapperFailureMessage: {
    defaultMessage: 'Error inviting user',
    id: 'create-new-users.failureMessage',
  },
  userFormWrapperInviteNewUser: {
    defaultMessage: 'Invite New User',
    id: 'invite-user.button-label',
  },
  userFormWrapperSuccessDeleteUser: {
    defaultMessage: 'Successfully deleted user.',
    id: 'create-new-users.successDeleteUser',
  },
  userFormWrapperSuccessEdit: {
    defaultMessage: 'Successfully Edited User.',
    id: 'create-new-users.successEdit',
  },
  userFormWrapperSuccessMessage: {
    defaultMessage: 'Successfully invited user.',
    id: 'create-new-users.successMessage',
  },
});
