import { Helmet } from 'react-helmet';

import { useLocale } from 'src/hooks/useLocale';

interface IRTLocales {
  [key: string]: any;
}

const RTL_LOCALES: IRTLocales = {
  ar: true,
  // Add any other Right to left languge here
};

type Props = {
  title: string;
  description?: string;
  meta?: any;
};

export function Seo({ title, description = '', meta = [] }: Props) {
  const metaDescription = description;
  const defaultTitle = 'Allurion Insights';
  const titleTemplate = `%s | ${defaultTitle}`;
  const { locale } = useLocale();
  const dir = RTL_LOCALES[locale] ? 'rtl' : 'ltr';

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
        dir,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}
