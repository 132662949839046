import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';

type Props = {
  patientsCount: number;
  onAddPatient: () => void;
};

//FIXME: this component shouldn't exist
export function ManagePatientsDropdown({ patientsCount, onAddPatient }: Props) {
  const [touched, setTouched] = useState<boolean>(false);
  const intl = useIntl();

  const handleOnClick = () => {
    onAddPatient();
  };

  useEffect(() => {
    if (touched && patientsCount > 0) {
      setTouched(false);
    }
  }, [touched, patientsCount]);

  return (
    <Container>
      <TrackedButton
        label={intl.formatMessage(
          {
            id: 'manage-patients.edit-button-label',
            defaultMessage: 'Edit {count} patient providers ',
          },
          { count: patientsCount }
        )}
        disabled={patientsCount === 0}
        onClick={handleOnClick}
        size="sm"
        trackLabel="edit-selected-patients-providers"
      />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 12px;
`;
