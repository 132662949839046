import { Card } from '@allurion/ui';
import { isString, isUndefined } from '@allurion/utils';
import { Message } from '@twilio/conversations/lib/message';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { PatientProfile } from 'src/domain/patient/Patients';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import translations from 'src/messages/translations';
import { TabContentMessage } from 'src/styles/components';
import { updateReadHorizon } from 'src/twilio/TwilioApi';
import { useTwilioConversation } from 'src/twilio/useTwilioConversation';
import { KEY_CHAT } from 'src/utils/constants';

import { ChatEmpty } from './ChatEmpty';
import { ChatError } from './ChatError';
import { ChatInput } from './ChatInput';
import { ChatMessage } from './ChatMessage';
import ChatScrollToBottom from './ChatScrollToBottom';

import styles from './PatientChat.module.scss';

const selector = {
  chatHistory: 'chat-history',
  chatContainer: 'patient-chat-container',
};

type ParsedMessage =
  | {
      index: number;
      id: string;
      name: string;
      message: string;
      date: Date;
      isMe: boolean;
      isProvider: boolean;
      attachment: any; // <-- is it MediaContent?
    }
  | undefined;

type Props = {
  patientIdentity: string;
  patient?: PatientProfile;
  isPatientAvailable?: boolean;
};

export function PatientChat({ patientIdentity, isPatientAvailable = false, patient }: Props) {
  const { user } = useCurrentUser();
  const intl = useIntl();
  const patientName = `${patient?.name} ${patient?.lastname}`;
  const identity = user.username;
  const providerName = `${user?.firstName} ${user?.lastName}`;
  const [lastReadIndex, setLastReadIndex] = useState<number | undefined>();
  const [, setScrollBottom] = useState<boolean>(true);
  const location = useLocation();
  const {
    loading,
    messages,
    conversation,
    setAllMessagesRead,
    getOpponentLastReadMessageIndex,
    sendMessage,
    error,
  } = useTwilioConversation({
    identity,
    patientIdentity,
  });
  const parsedMessages = useMemo(
    () => parseMessages({ messages, username: identity!, patientName }),
    [messages, patientName, identity]
  );

  useEffect(() => {
    const readHorizon = async () => {
      const opponent = await conversation?.getParticipantByIdentity(patientIdentity);
      const idx = getOpponentLastReadMessageIndex(opponent);

      setLastReadIndex(idx ?? undefined);
    };

    if (isUndefined(lastReadIndex)) {
      readHorizon();
    }
  }, [lastReadIndex, conversation, patientIdentity, getOpponentLastReadMessageIndex]);

  useEffect(() => {
    if (!conversation) {
      return;
    }

    const readAllMessages = async () => {
      await setAllMessagesRead();
      updateReadHorizon(conversation?.sid || '', patientIdentity);
    };

    if (location.hash.includes(KEY_CHAT)) {
      readAllMessages();
    }
  }, [location.hash, conversation, setAllMessagesRead, patientIdentity]);

  const onMessageSent = (isSent: boolean) => setScrollBottom(isSent);

  if (error) {
    return (
      <TabContentMessage>{intl.formatMessage(translations.chatErrorConnection)}</TabContentMessage>
    );
  }

  if (!isPatientAvailable) {
    return (
      <TabContentMessage>
        {intl.formatMessage({
          id: 'chat-error.notInstalled',
          defaultMessage: 'Patient has not installed Allurion mobile app',
        })}
      </TabContentMessage>
    );
  }

  let content: React.ReactNode = null;

  if (loading) {
    content = <ChatError uniqueName={patientIdentity} />;
  } else if (conversation) {
    if (!parsedMessages?.length) {
      content = <ChatEmpty name={patientName} />;
    } else {
      content = (
        <div className={styles['chat-history']} data-cy={selector.chatHistory}>
          {parsedMessages?.map((msg) => (
            <ChatMessage key={msg?.id} data={msg!} lastReadIndex={lastReadIndex} />
          ))}
        </div>
      );
    }
  }

  return (
    <Card>
      <div data-cy={selector.chatContainer} className={styles.container}>
        <div className={styles.messages}>
          {content}
          {conversation && parsedMessages?.length > 0 && <ChatScrollToBottom />}
        </div>
        <ChatInput
          uniqueName={patientIdentity}
          onMessageSent={onMessageSent}
          providerName={providerName}
          sendMessage={sendMessage}
        />
      </div>
    </Card>
  );
}

function parseMessages({
  messages,
  username,
  patientName,
}: {
  messages: Message[];
  username: string;
  patientName: string;
}): ParsedMessage[] {
  return messages?.map((message, idx: number) => ({
    index: message.index,
    id: `${message.sid}-${idx}`,
    name: (message.attributes as Record<string, string>)?.name || patientName || 'User',
    // TOOD: we need to align what is being sent as attribute in both iOS and Android
    message:
      message.body || // ios -> string
      (isString(message.attributes)
        ? message.attributes // android -> string
        : (message.attributes as Record<string, string>)?.msgWithText || ''),
    date: message.dateUpdated,
    isMe: message.author === username,
    isProvider: (message.attributes as Record<string, boolean>)?.isProvider || false,
    attachment: message.media,
  }));
}
