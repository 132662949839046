import { ErrorBoundaryMessage } from 'src/components/ErrorBoundary/ErrorBoundaryMessage';
import { Seo } from 'src/components/Seo';

export default function NotFoundPage() {
  return (
    <>
      <Seo title="404: Not found" />
      <ErrorBoundaryMessage kind={'notFound'} />
    </>
  );
}
