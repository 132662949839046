import { useDialog } from '@allurion/ui';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';

import { DailyActionDialog } from './DailyActionDialog';

type Props = {
  title: string;
  description: string;
  icon: string;
  patientId: string;
};

export function DailyActionInfoModal({ title, icon, description }: Props) {
  const intl = useIntl();
  const { openDialog, Dialog } = useDialog({
    content: () => {
      return <DailyActionDialog title={title} icon={icon} description={description} />;
    },
  });
  const { trackClick } = useTrackEvent();

  const openActionModal = (e: React.MouseEvent<HTMLDivElement>) => {
    trackClick(e.currentTarget, { label: 'open-patient-daily-actions-info-dialog' });
    openDialog({
      title: intl.formatMessage({
        id: 'daily-actions.modal.title',
        defaultMessage: 'Daily Action',
      }),
      contentProps: {},
    });
  };

  return (
    <>
      <DailyActionLink onClick={openActionModal} data-cy="open-patient-daily-actions-info-dialog">
        {title}
      </DailyActionLink>
      <Dialog />
    </>
  );
}

const DailyActionLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Primary};
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  width: fit-content;
`;
