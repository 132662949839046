import { isClinicLedOnboardingEnabled } from '@allurion/domain';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { isAdmin } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

import { ClinicActivePatients } from './ClinicActivePatients';

export function ClinicPatientsPage() {
  const { user } = useCurrentUser();
  const { clinicId } = useParams();
  const intl = useIntl();
  const { toInvitePatientPage: toAddPatientPage } = useAppNavigate();
  const { settings } = useClinicSettings(clinicId);

  const showAddPatientBtn = isAdmin(user) && isClinicLedOnboardingEnabled(settings);

  if (!clinicId) {
    throw new Error('Invalid clinic');
  }

  return (
    <>
      <Seo title="Clinic Patients" />

      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'clinic-patients',
          description: 'Clinic Patients',
          defaultMessage: 'Active Patients',
        })}
        button={
          showAddPatientBtn
            ? {
                label: intl.formatMessage({
                  id: 'invite-patient-button',
                  defaultMessage: 'Add new patient',
                }),
                size: 'sm',
                onClick() {
                  toAddPatientPage(clinicId);
                },
                trackLabel: 'invite-new-patient',
              }
            : undefined
        }
      />

      <ClinicActivePatients clinicId={clinicId} key={`ClinicActivePatients-${clinicId}`} />
    </>
  );
}
