import env from 'src/utils/env';

import { buildUrl } from '../api/Api';

export const { TWILIO_URL } = env;

export const TWILIO_TOKEN = '/token';
export const TWILIO_SEND_NOTIFICATION = '/send-notification';
export const TWILIO_READ_HORIZON = '/update-read-horizon';
export const TWILIO_VIDEO_LOG = '/get-video-call-log';

export async function fetchTwilioToken(identity: string) {
  const tokenUrl = buildTwilioUrl(TWILIO_TOKEN, { identity });
  const res = await fetch(tokenUrl);
  const { accessToken } = (await res.json()) || {};

  return accessToken;
}

const parseSpaces = (str: string) => str?.replace(/ /g, '%20');

export async function sendNotification({
  identity = '',
  type = 0,
  title = 'Allurion Insights',
  body = '',
  roomId = '',
  sound = '',
  filename = '',
}) {
  let sendNotificationUrl = buildTwilioUrl(TWILIO_SEND_NOTIFICATION, {
    identity,
    type,
    title: parseSpaces(title),
  });

  if (roomId) sendNotificationUrl += `&roomID=${roomId}`;
  if (sound) sendNotificationUrl += `&sound=${sound}`;
  if (body) sendNotificationUrl += `&body=${parseSpaces(body)}`;
  if (filename) sendNotificationUrl += `&filename=${parseSpaces(filename)}`;
  const res = await fetch(sendNotificationUrl);
  const r = await res.json();

  // eslint-disable-next-line no-console
  console.debug('sendNotification: ', r);
}

export async function updateReadHorizon(conversationSid: string, uniqueName: string) {
  const updateReadHorizonUrl = buildTwilioUrl(TWILIO_READ_HORIZON, {
    conversationSID: conversationSid,
    uniqueName,
  });

  const res = await fetch(updateReadHorizonUrl, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  const data = await res.json();

  return data;
}

function buildTwilioUrl(pathname: string, query: { [attribute: string]: string | number }) {
  return buildUrl(`${TWILIO_URL}${pathname}`, query);
}
