import { daysSince, isNull, toLocalizedDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { ActivePatient } from 'src/domain/patient/Patients';
import { convertWeight, fixNumbers } from 'src/helpers/convertions';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import messages from './active-patients-table.messages';
import { ValueWithArrow } from './ValueWithArrow';
import { ValueWithCalendar } from './ValueWithCalendar';

export function usePatientFormatters(clinicId: string) {
  const intl = useIntl();
  const { getTreatmentTypeLabel } = useTreatmentLabels(clinicId);
  const { unitsPreference } = useCurrentUser();

  const typeLabels: { [key: string]: string } = {
    1: intl.formatMessage(messages.balloonType),
    2: intl.formatMessage(messages.otherType),
    3: 'Non-patient',
  };

  return {
    getPatientFullName,
    getPatientFullNameAndAge(patient: ActivePatient) {
      return [getPatientFullName(patient), patient.age].filter(Boolean).join(', ');
    },
    getPatientTreatmentLabel(patient: ActivePatient) {
      return patient.latest_treatment_id
        ? getTreatmentTypeLabel(patient.latest_treatment_id, 'short')
        : typeLabels[patient.usertype];
    },
    getPatientCurrentWeight(patient: ActivePatient) {
      if (!patient.current_weight) {
        return '';
      }

      const absValue = Math.abs(patient.current_weight);

      return `${convertWeight(absValue, unitsPreference)} ${unitsPreference}`;
    },
    getPatientCurrentBmi(patient: ActivePatient) {
      if (!patient.current_bmi) {
        return '';
      }

      return fixNumbers(patient.current_bmi.toString(), 1, 1);
    },
    getPatientWeightLossSincePlacement(patient: ActivePatient) {
      const value = patient.weight_loss_since_placement;

      if (isNull(value)) {
        return '';
      }

      if (value === 0) {
        return `0 ${unitsPreference}`;
      }

      const absValue = Math.abs(value);

      return (
        <ValueWithArrow
          value={value}
          label={`${convertWeight(absValue, unitsPreference)} ${unitsPreference}`}
        />
      );
    },
    getPatientTBWL(patient: ActivePatient) {
      const value = patient.perc_weight_loss_since_placement;

      if (isNull(value)) {
        return '';
      }

      if (value === 0) {
        return `0 %`;
      }

      const absValue = Math.abs(value);

      return <ValueWithArrow value={value} label={`${fixNumbers(absValue.toString(), 1, 1)} %`} />;
    },
    getPatientDaysInTreatment(patient: ActivePatient) {
      const value = daysSince(patient.latest_treatment_start_date);

      if (!value || value < 0) {
        return '';
      }

      return <ValueWithCalendar value={value} />;
    },
    getLastWeightIn(patient: ActivePatient, locale: string) {
      const value = patient.last_weigh_in;

      if (!value) {
        return '';
      }

      return toLocalizedDate(value, locale);
    },
  };

  function getPatientFullName(patient: ActivePatient) {
    const names = [patient.name, patient.lastname]
      .map((v) => v?.trim())
      .filter(Boolean)
      .join(' ');

    return names;
  }
}
