import { forwardRef } from 'react';
import styles from './InputSelector.module.scss';

interface InputSelectorProps {
  type: 'checkbox' | 'radio' | 'switch';
  label?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputSelector = forwardRef<HTMLInputElement, InputSelectorProps>(
  function InputSelector({ type, label, checked, ...props }: InputSelectorProps, ref) {
    return (
      <div className={styles.inputSelectorWrapper}>
        <div className={styles.inputSelector}>
          {type === 'checkbox' && (
            <label className={styles.inputSelector__checkbox}>
              <div className={styles.inputSelector__checkbox__content}>
                <input ref={ref} type="checkbox" checked={checked} {...props} />
                <span />
              </div>
            </label>
          )}
          {type === 'radio' && (
            <label className={styles.inputSelector__radio}>
              <div className={styles.inputSelector__radio__content}>
                <input ref={ref} type="radio" checked={checked} {...props} />
                <span />
              </div>
            </label>
          )}
          {type === 'switch' && (
            <label className={styles.inputSelector__switch}>
              <div className={styles.inputSelector__switch__content}>
                <input ref={ref} type="checkbox" checked={checked} {...props} />
                <span className={styles.inputSelector__switch__slider} />
              </div>
            </label>
          )}
        </div>
        <label className={styles.label}>{label}</label>
      </div>
    );
  }
);
