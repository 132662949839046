import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyPolicy: {
    id: 'footer.privacy-policy',
    defaultMessage: 'Privacy Policy',
  },
  privacyPolicyLink: {
    id: 'footer.privacy-policy-html',
    defaultMessage: 'http://templates.insights.allurion.com/privacy_policy/privacy-policy.html',
  },
});
