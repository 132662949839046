import { Table } from '@allurion/ui';
import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { AllurionSearchbar } from 'src/components/ui/AllurionSearchbar';
import { ParsedClinicData } from 'src/domain/Clinic';
import { Spacer } from 'src/pages/shared-page-elements';
import { useTableSort } from 'src/ui/useTableSort';

import messages from '../../messages/invite-user.messages';

import { StyledCheckbox } from './styles';

type Props = {
  onSelected: Function;
  allClinics: ParsedClinicData[];
  providerClinics: string[];
};

export function SelectClinicsTable({ onSelected, allClinics, providerClinics }: Props) {
  const [selectedClinics, setSelectedClinics] = useState<string[]>(providerClinics ?? []);
  const [search, setSearch] = useState<string>('');
  const intl = useIntl();

  const placeholderLabel = intl.formatMessage({ id: messages.searchPlaceholder.id });
  const data = allClinics || [];
  const filteredData = data.filter((row) => {
    const { clinicName, city, country } = row;

    return (
      clinicName.toLowerCase().includes(search.toLowerCase()) ||
      city.toLowerCase().includes(search.toLowerCase()) ||
      country.toLowerCase().includes(search.toLowerCase())
    );
  });

  const { sortedData, sortBy, setSortBy } = useTableSort<ParsedClinicData>(
    filteredData,
    '+clinicName',
    {}
  );

  const onSelection = useCallback(
    (clinic: string) => {
      if (selectedClinics.includes(clinic)) {
        const newClinics = selectedClinics.filter((c) => c !== clinic);

        setSelectedClinics(newClinics);
      } else {
        setSelectedClinics((clinics) => [...clinics, clinic]);
      }
    },
    [selectedClinics]
  );

  useEffect(() => {
    if (providerClinics?.length > 0) {
      const selected = allClinics
        .filter(({ id }) => providerClinics?.includes(id?.toString()))
        .map((c) => c.id);

      if (selected.length > 0) {
        setSelectedClinics(selected);
      }
    }
  }, [allClinics, providerClinics]);

  useEffect(() => {
    if (allClinics) {
      const selected = allClinics?.filter(({ id }) => id && selectedClinics?.includes(id));

      onSelected(selected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinics, allClinics]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e || {};

    setSearch(value);
  };

  return (
    <>
      <AllurionSearchbar onChange={handleOnChange} placeholder={placeholderLabel} value={search} />
      <Spacer />

      <Table
        columns={[
          {
            title: intl.formatMessage(messages.selection),
            field: 'id',
            sortable: false,
            formatter(id: string) {
              return (
                <StyledCheckbox
                  onChange={() => {
                    onSelection(id);
                  }}
                  key={id}
                >
                  <input type="checkbox" defaultChecked={selectedClinics.includes(id)} />
                  <span />
                </StyledCheckbox>
              );
            },
          },
          {
            title: intl.formatMessage(messages.clinicName),
            field: 'clinicName',
          },
          {
            title: intl.formatMessage(messages.city),
            field: 'city',
          },
          {
            title: intl.formatMessage(messages.country),
            field: 'country',
          },
        ]}
        data={sortedData}
        sortBy={sortBy}
        onSortChange={setSortBy}
      />
    </>
  );
}
