export function NoteIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3861_1343)">
        <path
          d="M7.5 1.5L1.5 1.5L1.5 14.5L14.5 14.5L14.5 8.5"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.1"
        />
        <path
          d="M12.3084 1.87602C12.4276 1.75681 12.5691 1.66224 12.7249 1.59772C12.8807 1.53321 13.0476 1.5 13.2162 1.5C13.3848 1.5 13.5517 1.53321 13.7075 1.59772C13.8632 1.66224 14.0048 1.75681 14.124 1.87602C14.2432 1.99523 14.3378 2.13676 14.4023 2.29251C14.4668 2.44827 14.5 2.61521 14.5 2.78381C14.5 2.9524 14.4668 3.11934 14.4023 3.2751C14.3378 3.43086 14.2432 3.57238 14.124 3.69159L7.99642 9.81916L5.5 10.5L6.18084 8.00358L12.3084 1.87602Z"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3861_1343">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
