import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'chat-input.placeholder',
    defaultMessage: 'Type your message',
  },
  send: {
    id: 'chat-input.send',
    defaultMessage: 'Send',
  },
  attach: {
    id: 'chat-input.attach',
    defaultMessage: 'Attach',
  },
  emptyTitle: {
    id: 'chat-empty.title',
    defaultMessage: 'No Messages Yet',
  },
  emptySubtitle: {
    id: 'chat-empty.subtitle',
    defaultMessage: 'Enter text below to start a conversation with',
  },
  errorNotInstalled: {
    id: 'chat-error.notInstalled',
    defaultMessage: 'Patient has not installed Allurion mobile app',
  },
  errorRetry: {
    id: 'chat-error.retry',
    defaultMessage: 'Trying to connect you, please wait',
  },
  messageRead: {
    id: 'chat-message.read',
    defaultMessage: 'Read',
  },
});
