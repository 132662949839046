import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { WarningCircleIcon } from 'src/icons/WarningIcon';

export function AssignedProvidersWarning() {
  const intl = useIntl();

  return (
    <Warning>
      <WarningCircleIcon color="#FF5C00" />
      <WarningMessage>
        {intl.formatMessage({
          id: 'provider-access.form.warning',
          defaultMessage:
            'All previously assigned providers will be removed and replaced by those selected above.',
        })}
      </WarningMessage>
    </Warning>
  );
}

const Warning = styled.div`
  background: #fff5e6;
  display: flex;
  align-items: center;
  padding: 24px;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 15px;
  line-height: 19px;
  margin: 20px 0 0 0;
`;

const WarningMessage = styled.div`
  margin: 0 0 0 18px;
`;
