import { useIntl } from 'react-intl';
import styled from 'styled-components';

import messages from './chat.messages';

const selector = {
  messageTitle: 'chat-empty-title',
  messageSubtitle: 'chat-empty-subtitle',
  messageContainer: 'chat-empty-container',
};

export function ChatEmpty({ name = '' }: { name: string }) {
  const intl = useIntl();

  return (
    <EmptyMessageContainer data-cy={selector.messageContainer}>
      <MessageTitle data-cy={selector.messageTitle}>
        {intl.formatMessage(messages.emptyTitle)}
      </MessageTitle>
      <MessageSubtitle data-cy={selector.messageSubtitle}>{`${intl.formatMessage(
        messages.emptySubtitle
      )} ${name}`}</MessageSubtitle>
    </EmptyMessageContainer>
  );
}

const EmptyMessageContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  height: 150px;
`;

const MessageTitle = styled.p`
  color: ${({ theme }) => theme.colors.SoftGrey};
  font-weight: 600;
  line-height: 3;
`;

const MessageSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.SoftGrey};
  line-height: 2;
`;
