import { breakpoints, min, max, MAX_WIDTH } from './breakpoints';
import colors from './colors';
import styles from './styles';
import { ThemeProps } from './types';

const theme: ThemeProps = {
  colors,
  styles,
  breakpoints,
  media: {
    min,
    max,
    MAX_WIDTH,
  },
};

export default theme;
