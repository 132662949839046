import { TrashCanIcon, SelectInput, TextArea, useConfirmationDialog, Button } from '@allurion/ui';
import { toLocalizedShortDateTime } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useBlocker, useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { PatientNote } from 'src/domain/patient/Patients';
import { useClinic } from 'src/hooks/useClinic';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useLocale } from 'src/hooks/useLocale';
import { useNoteTopics } from 'src/hooks/useNoteTopics';
import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { Logger } from 'src/services/Logger';
import { NEW, NOTE_TOPIC_ADVERSE_EVENT_ID } from 'src/utils/constants';

import messages from './notes-messages';

import styles from './PatientNotes.module.scss';

type Props = {
  note?: PatientNote;
  changeFormMode: Function;
  onReset: () => void;
  saveNote: (
    patientId: string,
    providerId: string,
    clinicId: string,
    topicId: number,
    details: string
  ) => Promise<PatientNote>;
  deleteNote: (patientId: string, noteId: string) => Promise<any>;
};

export function NotePanel({ note, changeFormMode, saveNote, onReset, deleteNote }: Props) {
  const { patientId } = useParams();
  const intl = useIntl();
  const { locale } = useLocale();
  const { user } = useCurrentUser();
  const { patient } = usePatientProfile(patientId);
  const { clinic } = useClinic(patient?.hospitalid.toString());
  const [isLoading, setIsLoading] = useState(false);
  const [noteText, setNoteText] = useState('');
  const categoryOption = {
    value: -1,
    label: intl.formatMessage(messages.categorySelect),
    id: 'select',
  };
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [isCategoryInvalid, setIsCategoryInvalid] = useState(false);
  const [isTextInvalid, setIsTextInvalid] = useState(false);
  const { askConfirmation, ConfirmationDialog } = useConfirmationDialog();
  const { trackSaveNote } = useTrackEvent();
  const [isDirty, setIsDirty] = useState(false);

  const blocker = useBlocker(isDirty);

  const { categories } = useNoteTopics();

  const submitNewNote = () => {
    if (!validateNoteSave()) {
      return;
    }
    handleNoteSave();
    setIsDirty(false);
    blocker.proceed?.();
  };

  const validateNoteSave = () => {
    if (selectedCategory === -1 || noteText.length === 0) {
      if (selectedCategory === -1) {
        setIsCategoryInvalid(true);
      }
      if (noteText.length === 0) {
        setIsTextInvalid(true);
      }

      return false;
    }

    return true;
  };

  const handleNoteSave = async () => {
    if (!patient?.uid || !user?.providerId || !clinic?.ID) {
      toastError(intl.formatMessage(messages.saveNoteFailure));

      return;
    }

    try {
      setIsLoading(true);
      await saveNote(
        patient.uid.toString(),
        user.providerId,
        clinic.ID,
        selectedCategory,
        noteText
      );

      trackSaveNote({
        patientId: patient.uid.toString(),
        providerId: user.providerId,
        category: selectedCategory,
      });
      toastSuccess(intl.formatMessage(messages.saveNoteSuccess));
      setIsLoading(false);
      onReset();
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(messages.saveNoteFailure));
      setIsLoading(false);
    }
  };

  const onNoteDelete = async () => {
    if (!patient?.uid || !note?.NoteID) {
      toastError(intl.formatMessage(messages.deleteNoteFailure));

      return;
    }
    try {
      setIsLoading(true);
      await deleteNote(patient.uid.toString(), note?.NoteID);
      toastSuccess(intl.formatMessage(messages.deleteNoteSuccess));
      changeFormMode(NEW);
      onReset();
      setIsLoading(false);
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(messages.deleteNoteFailure));
      setIsLoading(false);
    }
  };

  const onCategoryChange = (item: number) => {
    setSelectedCategory(item);

    setIsCategoryInvalid(false);
    setIsDirty(true);
  };

  const placeholder =
    selectedCategory === -1 ? intl.formatMessage(messages.newNotePlaceholder) : '';

  const now = toLocalizedShortDateTime(new Date().toISOString(), locale);

  const userProviderId = user?.providerId?.toString();
  const canBeDeleted =
    note &&
    userProviderId === note.ProviderID.toString() &&
    note.Topic?.toString() !== NOTE_TOPIC_ADVERSE_EVENT_ID;

  const selectedCategoryValue = note
    ? categories.find((c) => c.value === note.Topic)?.value
    : selectedCategory;

  const canLeave = blocker.state !== 'blocked';

  return (
    <div data-cy="notes-panel">
      <TrackedPageHeader
        title={note ? 'Note' : 'New note'}
        subtitle={`${patient.name} ${patient.lastname}`}
        iconButtons={
          canBeDeleted
            ? [
                {
                  variant: 'icon',
                  size: 'sm',
                  icon: <TrashCanIcon />,
                  trackLabel: 'request-delete-patient-note',
                  onClick: () => {
                    askConfirmation({
                      title: intl.formatMessage(messages.deleteNoteModalText),
                      onConfirm: () => onNoteDelete(),
                      confirmText: intl.formatMessage(messages.delete),
                      cancelText: intl.formatMessage(messages.cancel),
                      variant: 'danger',
                    });
                  },
                },
              ]
            : undefined
        }
        onNavButtonClick={!isDirty ? onReset : undefined}
        onNavButtonClickTrackLabel="cancel-patient-note-form"
        button={
          note
            ? undefined
            : {
                label: intl.formatMessage(messages.save),
                onClick: submitNewNote,
                trackLabel: 'submit-patient-note-form',
              }
        }
      />

      <div className={styles.innerContainer}>
        {!canLeave && (
          <div className={styles.warningUnsaved}>
            <p>{intl.formatMessage(messages.warning)}</p>
            <div className={styles.buttons}>
              <Button
                type="button"
                variant="danger"
                size="xs"
                onClick={() => blocker.proceed()}
                label="Proceed"
              />
              <Button
                type="button"
                size="xs"
                variant="secondary"
                onClick={() => blocker.reset()}
                label="Cancel"
              />
            </div>
          </div>
        )}
        <div className={styles.note__header}>
          <span className={styles.note__date} data-cy="new-note-date">
            {`${note ? note?.ProviderFirstName : user.firstName} ${
              note ? note?.ProviderLastName : user.lastName
            } • ${note ? toLocalizedShortDateTime(note?.CreatedDate, locale) : now}`}
          </span>
          <div className={styles.note__header__selectInput} data-cy="new-note-category-dropdown">
            <SelectInput
              options={categories}
              placeholder={categoryOption.label}
              value={selectedCategoryValue}
              onChange={(item: any) => onCategoryChange(item.target.value)}
              disabled={note ? true : false}
              invalid={isCategoryInvalid}
            />
          </div>
        </div>
        <div className={styles.note__textArea}>
          <TextArea
            className={styles.note__text}
            value={note ? note.Details : noteText}
            onChange={(e) => {
              setIsDirty(true);
              setNoteText(e.target.value);
            }}
            invalid={isTextInvalid ? true : false}
            disabled={note ? true : false}
            placeholder={placeholder}
            data-cy="new-note-text-area"
          />
        </div>
      </div>
      <ConfirmationDialog />
      <Loader isLoading={isLoading} />
    </div>
  );
}
