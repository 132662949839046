import { toLocalizedLongDateTime } from '@allurion/utils';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton, TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { MyLink as Link } from 'src/components/ui/Link';
import { Loader } from 'src/components/ui/Loader';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useLocale } from 'src/hooks/useLocale';
import { useMessageNotifications } from 'src/hooks/useMessageNotifications';
import { CarrotDown } from 'src/icons/CarrotIcons';
import { CloseIcon } from 'src/icons/CloseIcon';
import { SunIcon } from 'src/icons/SunIcon';
import * as appRoutes from 'src/utils/appRoutes';
import { KEY_CHAT } from 'src/utils/constants';

export default function MessageNotificationsPage() {
  const intl = useIntl();
  const { isLoading, notifications, hasNextPage, loadMore, removeNotification, clearAll } =
    useMessageNotifications();

  const { toPatientPage, toPreviousPage } = useAppNavigate();
  const { locale } = useLocale();

  const navigateToPatientPage = (clinicId: number, patientId: number) =>
    toPatientPage(clinicId.toString(), patientId.toString(), KEY_CHAT);

  return (
    <Container>
      <Seo
        title={intl.formatMessage({ id: 'new-messages.title', defaultMessage: 'New Messages' })}
      />
      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'new-messages.header',
          description: 'New messages page header',
          defaultMessage: 'New messages',
        })}
        button={{
          label: intl.formatMessage({
            id: 'new-messages.clear-all',
            defaultMessage: 'Clear all',
          }),
          variant: 'secondary',
          onClick: clearAll,
          trackLabel: 'clear-all-messages',
        }}
        onNavButtonClick={() => toPreviousPage()}
      />

      {isLoading && <Loader isLoading={isLoading} />}
      <InnerContainer>
        {notifications.length < 1 ? (
          <div className="no-messages">
            <SunIcon fill="#77989E" size="48" />
            <div>
              {intl.formatMessage({
                id: 'new-messages.no-messages',
                defaultMessage: 'Nothing new for now',
              })}
            </div>
          </div>
        ) : (
          <>
            <div className="messages-list">
              {notifications.map((message) => (
                <div className="message" key={message.message_id} data-cy="message">
                  <div
                    className="notification-body"
                    role="button"
                    tabIndex={0}
                    onClick={() => navigateToPatientPage(message.clinic_id, message.patient_id)}
                    onKeyDown={() => navigateToPatientPage(message.clinic_id, message.patient_id)}
                  >
                    <div className="name">{message.patient_name}</div>
                    <div className="content">
                      {message.has_attachment
                        ? intl.formatMessage({
                            id: 'message-notification.sent-attachment',
                            defaultMessage: 'Sent an attachment',
                          })
                        : message.content}
                    </div>
                    <div className="date">{toLocalizedLongDateTime(message.sent_date, locale)}</div>
                  </div>
                  <button
                    type="button"
                    className="close-btn"
                    onClick={() => removeNotification(message.message_id)}
                  >
                    <CloseIcon size="20" color="00363F" />
                  </button>
                </div>
              ))}
            </div>
            {hasNextPage && (
              <div className="show-more-container">
                <TrackedButton
                  variant="outlined"
                  label={intl.formatMessage({
                    id: 'new-messages.show-more',
                    defaultMessage: 'Show more',
                  })}
                  rightIcon={<CarrotDown />}
                  onClick={loadMore}
                  size="sm"
                  trackLabel="show-more-messages"
                />
              </div>
            )}

            <StyledLink to={appRoutes.profileRoute}>
              {intl.formatMessage({
                id: 'new-messages.notifications-preferences-link',
                defaultMessage: 'Manage notifications preferences',
              })}
            </StyledLink>
          </>
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  max-width: 450px;
  width: 450px;
  margin: 0 auto;
  ${({ theme }) => theme.media.max.mobile`
    max-width: 100%;
  `}

  .no-messages {
    margin: 40px 0 0 0;
    font-size: 18px;
    line-height: 24px;
    font-family: 'GT-America-Standard-Medium', sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.Primary};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      margin: 0 0 26px 0;
    }
  }

  .show-more-container {
    ${({ theme }) => theme.media.max.mobile`
      padding: 0 16px;
    `}

    button {
      width: 100%;
      margin: 24px 4px;
    }
  }

  .messages-list {
    color: ${({ theme }) => theme.colors.Primary};
    ${({ theme }) => theme.media.max.mobile`
      margin: 24px 0 0 0;
    `}

    .message {
      background: #fff;
      display: flex;
      justify-content: space-between;
      border: 1px solid #ccc;
      padding: 16px;
      border-radius: 4px;
      width: 100%;
      margin: 12px auto 0 auto;
      ${({ theme }) => theme.media.max.mobile`
        margin: 0;
        border-bottom: none;
        border-radius: 0;
        &:last-child {
          border-bottom: 1px solid #ccc;
        }
      `}
      .notification-body {
        cursor: pointer;
      }
      .name {
        font-family: 'GT-America-Standard-Bold', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 2px;
      }

      .content,
      .date {
        font-size: 13px;
        line-height: 20px;
      }

      .content {
        font-family: 'GT-America-Standard-Medium', sans-serif;
        font-weight: 500;
        max-width: 375px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        ${({ theme }) => theme.media.max.mobile`
        max-width: 250px;
        `}
      }

      .date {
        font-family: 'GT-America-Standard-Light', sans-serif;
        font-weight: 400;
        color: #4d4d4d;
      }

      .close-btn {
        cursor: pointer;
        float: right;
        background: none;
        margin: 0 0 0 16px;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 13px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.Primary};
  font-weight: 500;
  text-align: center;
  display: block;
  text-decoration: underline;
  margin: 16px 0;
`;
