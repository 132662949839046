/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  password: {
    id: 'reset-password.password',
    defaultMessage: 'Password',
  },
  resetPassword: {
    id: 'reset-password.resetPassword',
    defaultMessage: 'Reset Password',
  },
  email: {
    id: 'reset-password.email',
    defaultMessage: 'Your email address',
  },
  emailPlaceholder: {
    id: 'reset-password.emailPlaceholder',
    defaultMessage: 'Enter your email address',
  },
  title: {
    id: 'reset-password.title',
    defaultMessage: 'Enter your email address below to reset your password.',
  },
});
