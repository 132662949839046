import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';

import { AssignedProvidersWarning } from './AssignedProvidersWarning';

type Props = {
  providersNames: string[];
  patientsNames: string[];
  onPrev: () => void;
  onConfirm: () => void;
};

export function ConfirmProviders({ providersNames, patientsNames, onPrev, onConfirm }: Props) {
  const intl = useIntl();

  return (
    <>
      <PatientsWrapper>
        <PatientsTitle>
          {intl.formatMessage({
            id: `provider-access.patients-to-update`,
            defaultMessage: `Selected patients:`,
          })}
        </PatientsTitle>
        <PatientsList>{patientsNames.join(', ')}</PatientsList>
      </PatientsWrapper>
      <ProvidersWrapper>
        <ProvidersTitle>
          {intl.formatMessage({
            id: `provider-access.providers-to-update`,
            defaultMessage: `Providers with access:`,
          })}
        </ProvidersTitle>
        <ProvidersList>
          {providersNames.map((providerName, index) => (
            <li key={`${providerName}-${index}`}>{providerName}</li>
          ))}
        </ProvidersList>
        <AssignedProvidersWarning />
      </ProvidersWrapper>
      <ButtonsWrapper>
        <TrackedButton
          onClick={onPrev}
          label={intl.formatMessage({
            id: 'provider-access.form.back',
            defaultMessage: 'Back',
          })}
          variant="outlined"
          size="sm"
          trackLabel="back-edit-patient-providers"
        />
        <TrackedButton
          onClick={onConfirm}
          label={intl.formatMessage({
            id: 'provider-access.form.confirm',
            defaultMessage: 'Confirm Changes',
          })}
          size="sm"
          trackLabel="confirm-edit-patient-providers"
        />
      </ButtonsWrapper>
    </>
  );
}

const ButtonsWrapper = styled.div`
  button {
    margin-right: 20px;
  }
  display: flex;
  justify-content: right;
  margin-top: 30px;
`;

const ProvidersTitle = styled.h3`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-align: left;
  min-width: 150px;
`;

const ProvidersList = styled.ul`
  white-space: pre-line;
  line-height: 30px;
  text-align: left;
  min-width: 150px;
`;

const ProvidersWrapper = styled.div`
  background: ${({ theme }) => theme.colors.White};
  padding: 24px;
  margin: 20px 0 0 0;
`;

const PatientsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.White};
  padding: 24px;
`;

const PatientsList = styled.p`
  margin: 10px 0;
`;

const PatientsTitle = styled.h3`
  font-family: 'GT-America-Standard-Medium', sans-serif;
`;
