import { isDate, isString, strToDate, toDateStr, toLocalizedDate } from '@allurion/utils';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { useLocale } from 'src/hooks/useLocale';
import { CalendarIcon } from 'src/icons/CalendarIcon';

import { AllurionErrorIndicator } from './AllurionErrorIndicator';

const Container = styled.div`
  .date-picker {
    width: 100%;
    height: 55px;
    font-family: 'GT-America-Standard', sans-serif;

    > div {
      border: 1px solid #dee0e0;

      &:hover {
        border-color: ${({ theme }) => theme.colors.Primary};
      }

      &:active {
        border-width: 2px;
      }
    }

    input {
      width: 100%;
      height: 100%;
    }
  }
`;

const DateWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 50px;
  padding: 10px 25px;
  transition: opacity 0.3s;
  color: ${({ theme }) => theme.colors.Primary};
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  ${({ theme, haserror }) =>
    haserror && isString(haserror) && `border: 1px solid ${theme.colors.Alert}`};

  svg {
    position: absolute;
    right: 24px;

    html[dir='rtl'] & {
      right: unset;
      left: 24px;
    }
  }
`;

interface Props extends Partial<ReactDatePickerProps> {
  defaultDate?: string | Date;
  cyErrorLabelSelector?: string;
}

export function AllurionDatePicker({
  defaultDate,
  onSelect = () => {},
  cyErrorLabelSelector,
  ...props
}: Props) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [field, meta] = useField(props as any);
  const { error, touched } = meta || {};
  const { locale } = useLocale();

  useEffect(() => {
    if (defaultDate) {
      setStartDate(isDate(defaultDate) ? defaultDate : strToDate(defaultDate));
    }
  }, [defaultDate]);

  const onChange = (newDate: any) => {
    // @ts-ignore
    onSelect(newDate);
    setStartDate(newDate);
  };

  return (
    <Container>
      <DatePicker
        {...field}
        {...props}
        selected={startDate}
        onChange={onChange}
        wrapperClassName="date-picker"
        // TODO: i18n placeholderText
        placeholderText="MM/DD/YYYY"
        customInput={
          <DateWrapper haserror={error}>
            {startDate ? toLocalizedDate(toDateStr(startDate), locale) : ''}

            <CalendarIcon />
          </DateWrapper>
        }
      />
      {/* TODO: Remove condition after updating all forms that uses this component */}
      {error?.length && (
        <AllurionErrorIndicator
          label={error}
          show={!!(error || touched)}
          data-cy={cyErrorLabelSelector}
        />
      )}
    </Container>
  );
}
