import { defineMessages } from 'react-intl';

export default defineMessages({
  other: {
    id: 'activity-charts.other',
    defaultMessage: 'Other',
  },
  running: {
    id: 'activity-charts.running',
    defaultMessage: 'Running',
  },
  walking: {
    id: 'activity-charts.walking',
    defaultMessage: 'Walking',
  },
  cycling: {
    id: 'activity-charts.cycling',
    defaultMessage: 'Cycling',
  },
  basketball: {
    id: 'activity-charts.basketball',
    defaultMessage: 'Basketball',
  },
  soccer: {
    id: 'activity-charts.soccer',
    defaultMessage: 'Soccer',
  },
  climbing: {
    id: 'activity-charts.climbing',
    defaultMessage: 'Climbing',
  },
  average7Days: {
    id: 'activity-charts.average7Days',
    defaultMessage: '7-Day Average',
  },
});
