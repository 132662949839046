import styled from 'styled-components';

export const StyledCheckbox = styled.label`
  margin: 10px 10px 10px 14px;
  z-index: 0;
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.Primary};
  font-family: 'GT-America-Standard', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.Primary};
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition:
      opacity 0.3s,
      transform 0.2s;
  }

  span {
    display: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    cursor: pointer;
  }

  span::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    margin: 3px 11px 3px 1px;
    border: solid 2px; /* Safari */
    border-color: ${({ theme }) => theme.colors.Primary};
    border-radius: 2px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition:
      border-color 0.2s,
      background-color 0.2s;
  }

  span::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
  }

  input:checked,
  input:indeterminate {
    background-color: ${({ theme }) => theme.colors.Primary};
  }

  input:checked + span::before,
  input:indeterminate + span::before {
    border-color: ${({ theme }) => theme.colors.Primary};
    background-color: ${({ theme }) => theme.colors.Primary};
  }

  input:checked + span::after,
  input:indeterminate + span::after {
    border-color: rgb(var(--onprimary-rgb, 255, 255, 255));
  }

  input:indeterminate + span::after {
    border-left: none;
    transform: translate(4px, 3px);
  }
`;
