/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { Conversation } from '@twilio/conversations/lib/conversation';

type DeviceKind = 'audioinput' | 'audiooutput' | 'videoinput';

/**
 * Get the list of available media devices of the given kind.
 */
export function filterDevicesByKind(
  deviceInfos: MediaDeviceInfo[],
  kind: DeviceKind
): MediaDeviceInfo[] {
  return deviceInfos.filter((deviceInfo) => deviceInfo.kind === kind);
}

/**
 * Ensure that media permissions are obtained.
 */
async function ensureMediaPermissions(): Promise<void> {
  const devices = await navigator?.mediaDevices?.enumerateDevices();

  const shouldAskForMediaPermissions = devices.every(
    (device) => !(device.deviceId && device.label)
  );

  if (!shouldAskForMediaPermissions) {
    return;
  }

  const mediaStream = await navigator?.mediaDevices?.getUserMedia({ audio: true, video: true });

  mediaStream.getTracks().forEach((track) => {
    track.stop();
  });
}

/**
 * Get the list of available media devices.
 */
export async function getDeviceSelectionOptions() {
  // before calling enumerateDevices, get media permissions (.getUserMedia)
  // w/o media permissions, browsers do not return device Ids and/or labels.

  await ensureMediaPermissions();
  const deviceInfos = await navigator?.mediaDevices?.enumerateDevices();

  const kinds = ['audioinput', 'audiooutput', 'videoinput'] as DeviceKind[];

  return kinds.reduce<Record<string, MediaDeviceInfo[]>>((deviceSelectionOptions, kind) => {
    deviceSelectionOptions[kind] = filterDevicesByKind(deviceInfos, kind);

    return deviceSelectionOptions;
  }, {});
}

export async function getUnreadMessageState(patientConversation: Conversation | undefined) {
  let isUnread = false;

  if (patientConversation) {
    const unreadMessagesCount = (await patientConversation?.getUnreadMessagesCount()) || 0;
    const totalMessages = (await patientConversation?.getMessagesCount()) || 0;
    let lastReadMessageIndex = patientConversation?.lastReadMessageIndex;

    if (!lastReadMessageIndex) {
      const defaultLastReadMessageIndex = totalMessages === 0 ? 0 : totalMessages - 1;

      await patientConversation?.updateLastReadMessageIndex(defaultLastReadMessageIndex);
      // FIXME: Are we using this var? Maybe we can change it to const
      lastReadMessageIndex = defaultLastReadMessageIndex;
    }
    isUnread = unreadMessagesCount > 0;
  }

  return isUnread;
}
