export function ExitIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3561_913)">
        <path
          d="M12 10.9999L15 7.99994L12 4.99994"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.2"
          strokeLinecap="square"
        />
        <path
          d="M14 7.99994L7 7.99994"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M6 0.999939H1V14.9999H6"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_3561_913">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
