import { defineMessages } from 'react-intl';

export default defineMessages({
  errorBoundaryTitle: {
    id: 'error-boundary.title',
    defaultMessage: 'Page could not be loaded.',
  },
  errorBoundaryMessage: {
    id: 'error-boundary.message',
    defaultMessage:
      'Please try again. If you the problem persists, try clearing the cache and starting over',
  },
  errorBoundaryReturn: {
    id: 'error-boundary.return',
    defaultMessage: 'Clear Cache',
  },
  errorBoundaryHelp: {
    id: 'error-boundary.help',
    defaultMessage: 'If you need more assistance, please email help@allurion.com.',
  },
  forbiddenErrorTitle: {
    id: 'forbidden-error.title',
    defaultMessage: 'You don’t have access to this page.',
  },
  forbiddenErrorMessage: {
    id: 'forbidden-error.message',
    defaultMessage:
      'If you think this is a mistake, please contact your clinic admin or customer support at help@allurion.com.',
  },
  forbiddenErrorButton: {
    id: 'forbidden-error.button',
    defaultMessage: 'Go Home',
  },
  notFoundErrorTitle: {
    id: 'not-found-error.title',
    defaultMessage: 'The page was not found.',
  },
  notFoundErrorMessage: {
    id: 'not-found-error.message',
    defaultMessage: 'Please check the address or try visiting another page.',
  },
  notFoundErrorButton: {
    id: 'not-found-error.button',
    defaultMessage: 'Go Home',
  },
});
