import {
  CameraOffIcon,
  CameraOnIcon,
  MicOffIcon,
  MicOnIcon,
  PhoneIcon,
  useConfirmationDialog,
} from '@allurion/ui';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Participant, Room } from 'twilio-video';

import { TrackedIconButton } from 'src/analytics/TrackedUI';

import { SelectedDevice } from './video-call-types';
import messages from './video-call.messages';
import { VideoCallScreen } from './VideoCallScreen';

import styles from './PatientVideoCall.module.scss';

type Props = {
  room: Room;
  participants: Participant[];
  onLogout: Function;
  muteAudio: boolean;
  stopVideo: boolean;
  setMuteAudio: Function;
  setStopVideo: Function;
  isCallOver: boolean;
  setIsCallOver: Function;
  currTab?: string;
  device?: SelectedDevice;
  patientId: string;
};

export function VideoCallRoom({
  room,
  onLogout,
  participants,
  muteAudio,
  stopVideo,
  setMuteAudio,
  setStopVideo,
  isCallOver,
  currTab,
  setIsCallOver,
  device,
  patientId,
}: Props) {
  const location = useLocation();
  const intl = useIntl();
  const { askConfirmation, ConfirmationDialog } = useConfirmationDialog();
  const isVideoEnabledRef = useRef(false);

  const toggleAudio = useCallback(() => {
    setMuteAudio((m: boolean) => !m);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVideo = useCallback(() => {
    setStopVideo((v: boolean) => !v);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnd = useCallback(() => {
    setIsCallOver(true);
    onLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldEndCall = !location.pathname.includes('/patient/');

    if (shouldEndCall) {
      room?.localParticipant?.tracks.forEach((t: any) => t.track.stop());
      room?.disconnect();
    }

    return () => {
      room?.localParticipant?.tracks.forEach((t: any) => t.track.stop());
      room?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const enableVideo = ({ track }: { track: any }) => {
      const { kind } = track;

      if (kind === 'video') {
        track.restart();
      }
    };
    const disableVideo = ({ track }: { track: any }) => {
      const { kind } = track;

      if (kind === 'video') {
        track.stop();
      }
    };

    if (!isVideoEnabledRef.current) {
      room?.localParticipant?.tracks.forEach(enableVideo);
    }

    return () => {
      room?.localParticipant?.tracks.forEach(disableVideo);
    };
  }, [room, currTab]);

  return (
    <>
      <ConfirmationDialog />
      <div className={styles.videoCall__videoRoom}>
        <VideoCallScreen
          room={room}
          participants={participants}
          stopVideo={stopVideo}
          muteAudio={muteAudio}
          isCallOver={isCallOver}
          device={device}
          patientId={patientId}
        />
        <ControllerButtons>
          <TrackedIconButton
            onClick={toggleAudio}
            size="lg"
            variant="primary"
            icon={muteAudio ? <MicOffIcon /> : <MicOnIcon />}
            trackLabel={muteAudio ? 'unmute-video-call-audio' : 'mute-video-call-audio'}
          />
          <TrackedIconButton
            onClick={toggleVideo}
            size="lg"
            variant="primary"
            icon={stopVideo ? <CameraOffIcon /> : <CameraOnIcon />}
            trackLabel={stopVideo ? 'start-video-call-video' : 'stop-video-call-video'}
          />
          <div style={{ flex: 1 }} />
          <TrackedIconButton
            size="lg"
            variant="danger"
            icon={<PhoneIcon />}
            onClick={openEndCallModal}
            trackLabel="end-video-call"
          />
        </ControllerButtons>
      </div>
    </>
  );

  function openEndCallModal() {
    askConfirmation({
      title: intl.formatMessage(messages.endConfirmation),
      confirmText: intl.formatMessage(messages.endCall),
      cancelText: intl.formatMessage(messages.cancelCall),
      onConfirm: () => {
        onEnd();
      },
    });
  }
}

const ControllerButtons = styled.div`
  position: absolute;
  width: 300px;
  bottom: 0;
  padding: 20px;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  ${({ theme }) => theme.media.max.tablet`
    padding: 32px;
  `}
`;
