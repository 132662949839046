import { getDefaultClinicSettings } from '@allurion/domain';
import { isString } from '@allurion/utils';
import { useCallback } from 'react';

import { InsightsApi } from 'src/api/Api';
import { ClinicSettings } from 'src/domain/Clinic';

import { useUserClinicsSettings } from './useUserClinicsSettings';

export const CLINIC_SETTINGS = (clinicId: string) => `/clinics/${clinicId}/settings`;

export function useClinicSettings(clinicId?: string) {
  const { mutate, getClinicSettings, error, isValidating } = useUserClinicsSettings();

  const settings = fixSettingsFeatures(getClinicSettings(clinicId));

  const updateSettings = useCallback(
    (setting: ClinicSettings) =>
      updateClinicSettings(setting).then(() => {
        mutate();
      }),
    [mutate]
  );

  return {
    settings: settings ?? getDefaultClinicSettings(clinicId),
    error,
    isLoading: isValidating,
    updateSettings,
  };
}

function updateClinicSettings({ ClinicID, ...body }: ClinicSettings) {
  return InsightsApi.put<ClinicSettings>(CLINIC_SETTINGS(ClinicID), body);
}

function fixSettingsFeatures(s?: ClinicSettings) {
  if (!s) {
    return;
  }

  // FIXME: In case that features returns a string, we convert it into an array.
  // To be removed once backend is fixes this case.
  const features: string[] | undefined = isString(s?.features) ? [s?.features] : s?.features;
  const treatmentsOffered: number[] | undefined =
    typeof s?.treatmentsOffered === 'string' ? [s?.treatmentsOffered] : s?.treatmentsOffered;

  return { ...s, features, treatmentsOffered };
}
