import { isPatientGroupingEnabled } from '@allurion/domain';
import { PatientHead, SearchIcon } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { TrackedEmptyState } from 'src/analytics/TrackedUI';
import { ClinicSettings } from 'src/domain/Clinic';
import { isAdmin, User } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';

import clinicPatientsMessages from './clinic-patients-messages';

export function ClinicPatientsEmptyState({
  emptySearch,
  noAvailablePatients,
  settings,
  user,
  clinicId,
}: {
  noAvailablePatients: boolean;
  emptySearch: boolean;
  settings: ClinicSettings;
  user: User;
  clinicId: string;
}) {
  const intl = useIntl();
  const { toInvitePatientPage, toManagePatientsPage } = useAppNavigate();

  const title = emptySearch
    ? intl.formatMessage(clinicPatientsMessages.noPatientsFoundTitle)
    : intl.formatMessage(clinicPatientsMessages.noPatientsAvailableTitle);

  return (
    <TrackedEmptyState
      icon={emptySearch ? <SearchIcon /> : <PatientHead />}
      title={title}
      subtitle={getSubtitle()}
      buttons={getButtons()}
      trackedLabel={noAvailablePatients ? 'no-patients-available' : 'no-patients-found'}
    />
  );

  function getSubtitle() {
    if (emptySearch) {
      return intl.formatMessage(clinicPatientsMessages.noPatientsFoundSubtitle);
    }

    if (isAdmin(user)) {
      return isPatientGroupingEnabled(settings)
        ? intl.formatMessage(
            clinicPatientsMessages.noPatientsAvailableForAdminWithPatientGroupingSubtitle
          )
        : intl.formatMessage(
            clinicPatientsMessages.noPatientsAvailableForAdminWithoutPatientGroupingSubtitle
          );
    }

    return isPatientGroupingEnabled(settings)
      ? intl.formatMessage(
          clinicPatientsMessages.noPatientsAvailableForStaffWithPatientGroupingSubtitle
        )
      : intl.formatMessage(
          clinicPatientsMessages.noPatientsAvailableForStaffWithoutPatientGroupingSubtitle
        );
  }

  function getButtons() {
    if (emptySearch || !isAdmin(user)) {
      return;
    }

    const addPatientButton = {
      label: intl.formatMessage({
        id: 'invite-patient-button',
      }),
      onClick() {
        toInvitePatientPage(clinicId);
      },
    };
    const organizePatientsButton = {
      label: intl.formatMessage({
        id: 'navmenu.organize-patients',
      }),
      onClick() {
        toManagePatientsPage(clinicId);
      },
      variant: 'secondary',
    };

    if (isPatientGroupingEnabled(settings)) {
      return [addPatientButton, organizePatientsButton];
    }

    return [addPatientButton];
  }
}
