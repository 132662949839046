import { defineMessages } from 'react-intl';

export default defineMessages({
  treatmentType1: {
    id: 'treatment_type_1',
    defaultMessage: 'Allurion Balloon Program',
  },
  treatmentType2: {
    id: 'treatment_type_2',
    defaultMessage: 'Non-Allurion Balloon',
  },
  treatmentType3: {
    id: 'treatment_type_3',
    defaultMessage: 'Bariatric Surgery',
  },
  treatmentType4: {
    id: 'treatment_type_4',
    defaultMessage: 'Weight-loss medication',
  },
  treatmentType5: {
    id: 'treatment_type_5',
    defaultMessage: 'Other weight-loss program',
  },
  treatmentTypeCustom1Long: {
    id: 'treatment_type_custom_1_long',
    defaultMessage: 'Allurion Balloon Program',
  },
  treatmentType100: {
    id: 'treatment_type_custom_2_long',
    defaultMessage: 'Weight Doctors BARIATRICS® Gastric Bypass',
  },
  treatmentType100Short: {
    id: 'treatment_type_custom_2_short',
    defaultMessage: 'Gastric Bypass',
  },
  treatmentType101: {
    id: 'treatment_type_custom_3_long',
    defaultMessage: 'Weight Doctors BARIATRICS® Gastric Sleeve',
  },
  treatmentType101Short: {
    id: 'treatment_type_custom_3_short',
    defaultMessage: 'Gastric Sleeve',
  },
  treatmentType102: {
    id: 'treatment_type_custom_4_long',
    defaultMessage: 'Weight Doctors SLEEVE® OverStitch',
  },
  treatmentType102Short: {
    id: 'treatment_type_custom_4_short',
    defaultMessage: 'WD Sleeve OverStitch',
  },
  treatmentType103: {
    id: 'treatment_type_custom_5_long',
    defaultMessage: 'Weight Doctors SLEEVE® pose2',
  },
  treatmentType103Short: {
    id: 'treatment_type_custom_5_short',
    defaultMessage: 'WD Sleeve pose2',
  },
  treatmentType104: {
    id: 'treatment_type_custom_6_long',
    defaultMessage: 'Gastric Balloon for 12 months',
  },
  treatmentType104Short: {
    id: 'treatment_type_custom_6_short',
    defaultMessage: '12-month Balloon',
  },
  treatmentType105: {
    id: 'treatment_type_custom_7_long',
    defaultMessage: 'Gastric Balloon for 6 months',
  },
  treatmentType105Short: {
    id: 'treatment_type_custom_7_short',
    defaultMessage: '6-month Balloon',
  },
  treatmentType106: {
    id: 'treatment_type_custom_8_long',
    defaultMessage: 'Medication Therapy',
  },
  treatmentTypeCustom6Short: {
    id: 'treatment_type_custom_8_short',
    defaultMessage: 'Meds Therapy',
  },
  addTreatment: {
    id: 'treatment-list.add-treatment',
    defaultMessage: 'Add Treatment',
  },
  editTreatmentDialogTitle: {
    id: 'treatment-list.edit-treatment.dialog-title',
    defaultMessage: 'Edit Treatment',
  },
  treatmentType: {
    id: 'treatment-list.treatment-type',
    defaultMessage: 'Treatment Type',
  },
  treatmentStartDate: {
    id: 'treatment-list.start-date',
    defaultMessage: 'Start Date',
  },
  treatmentEndDate: {
    id: 'treatment-list.end-date',
    defaultMessage: 'End Date',
  },
  treatmentSave: {
    id: 'treatment-list.save',
    defaultMessage: 'Save',
  },
  treatmentDelete: {
    id: 'treatment-list.delete',
    defaultMessage: 'Are you sure you want to delete this treatment?',
  },
  treatmentDeleteTooltip: {
    id: 'treatment-list.delete-tooltip',
    defaultMessage: 'Delete treatment',
  },
  treatmentEditTooltip: {
    id: 'treatment-list.edit-tooltip',
    defaultMessage: 'Edit treatment',
  },
  treatmentAddTooltip: {
    id: 'treatment-list.add-tooltip',
    defaultMessage: 'Add treatment',
  },
  startDateErrorMessage: {
    id: 'add-treatment.date-out-of-range',
    defaultMessage: 'Start date must be within 90 days of the current date.',
  },
  endDateErrorMessage: {
    id: 'treatment-list.end-date-error',
    defaultMessage: 'End date cannot be earlier than start date',
  },
  treatmentTypePlaceholder: {
    id: 'treatment-list.treatment-type-placeholder',
    defaultMessage: 'Select treatment type',
  },
});
