export function VideoIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.666504"
        y="2.66667"
        width="10.6667"
        height="10.6667"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
      />
      <path d="M15 11L15 5L12 8L15 11Z" stroke="currentColor" strokeOpacity="0.9" />
    </svg>
  );
}

export default VideoIcon;
