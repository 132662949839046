import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { ActivePatient } from 'src/domain/patient/Patients';

export const ACTIVE_PATIENTS = (clinicId: string) => `/clinics/${clinicId}/active-patients`;

export function useClinicActivePatients(clinicId: string, patientFilter?: string) {
  const query = {
    search: patientFilter || '',
  };

  const url = buildUrl(ACTIVE_PATIENTS(clinicId.toString()), query);
  const { data, error, isValidating, isLoading } = useSWR(
    url,
    (_url) =>
      InsightsApi.get<{ activePatients: ActivePatient[] }>(_url).then((res) => res.activePatients),
    {
      revalidateOnFocus: false,
      suspense: true,
      dedupingInterval: ONE_MINUTE_IN_MS,
    }
  );

  return {
    activePatients: data ?? [],
    error,
    isLoading: isValidating || isLoading,
  };
}
