import { InputSelector } from '@allurion/ui';

import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import styles from './ClinicSettingsPage.module.scss';

type Props = {
  clinicId: string;
  treatmentsOffered?: number[];
  onChange: (treatmentId: number, checked: boolean) => void;
};

export function ClinicOfferedTreatments({ clinicId, treatmentsOffered, onChange }: Props) {
  const { getTreatmentTypeLabel } = useTreatmentLabels(clinicId);
  const { treatmentList } = useClinicTreatements(clinicId);

  const clinicTreatments = treatmentList.map((treatment) => ({
    label: getTreatmentTypeLabel(treatment.custom_treatment_id),
    value: parseInt(treatment.custom_treatment_id, 10),
    type: treatment.custom_treatment_name,
  }));

  return (
    <div className={styles.treatments} data-cy="clinic-settings-treatments">
      {clinicTreatments.map((treatment) => (
        <InputSelector
          type="checkbox"
          label={treatment.label}
          checked={treatmentsOffered?.includes(treatment.value)}
          key={treatment.value}
          onChange={({ currentTarget: { checked } }) => onChange(treatment.value, checked)}
        />
      ))}
    </div>
  );
}
