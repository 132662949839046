import styles from './OverlayContainer.module.scss';

type OverlayContainerProps = React.PropsWithChildren<{
  padding?: string;
}>;

export function OverlayContainer({ children, padding }: OverlayContainerProps) {
  return (
    <div
      style={{
        padding: padding || '0',
      }}
      className={styles.overlayContainer}
    >
      {children}
    </div>
  );
}
