import { defineMessages } from 'react-intl';

export default defineMessages({
  overview: {
    id: 'tab-menu.overview',
    defaultMessage: 'Overview',
  },
  weight: {
    id: 'tab-menu.scale-data',
    defaultMessage: 'Weight',
  },
  activity: {
    id: 'tab-menu.activity',
    defaultMessage: 'Activity',
  },
  video: {
    id: 'tab-menu.video',
    defaultMessage: 'Video',
  },
  chat: {
    id: 'tab-menu.chat',
    defaultMessage: 'Messages',
  },
  dailyActions: {
    id: 'tab-menu.daily-actions',
    defaultMessage: 'Daily Actions',
  },
  notes: {
    id: 'tab-menu.notes',
    defaultMessage: 'Notes',
  },
  patientAge: {
    id: 'patient-page.patientAge',
    defaultMessage: 'years old',
  },
});
