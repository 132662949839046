import { format, secondsToMinutes } from 'date-fns';
import { useMemo } from 'react';
import useSWR from 'swr';

import { InsightsApi } from 'src/api/Api';

type VideoCallSession = {
  durationSec: number;
  createTime: string;
  endTime: string;
  providerFirstName: string;
  providerLastName: string;
  roomId: string;
};

export function useVideoCallSessions(patientId: string) {
  const { data, error, isValidating } = useSWR(
    `/patients/${patientId}/video-calls`,
    (url) =>
      InsightsApi.get<{ data: VideoCallSession[] }>(url)
        .then((res) => res.data)
        .catch(() => []),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );
  const videoCallSessions = useMemo(() => parseVideoLogs(data || []), [data]);

  return { videoCallSessions, isLoading: isValidating, error };
}

function parseVideoLogs(logs: VideoCallSession[]) {
  return logs.map(
    ({ createTime, endTime, providerFirstName, providerLastName, roomId, durationSec }) => {
      const start = new Date(createTime);
      const end = new Date(endTime);
      const dateFormat = 'MMM d, yyyy';
      const timeFormat = 'hh:mm aaa';
      // TODO: format duration to `seconds (0-59 secs) -> mins (1-59 mins) -> hours (1:00 - 999:00 hours)
      // TODO: think of a better way to handle plurals (mins, hours, etc...)
      const durationMinutes = secondsToMinutes(durationSec);

      return {
        id: roomId,
        date: format(start, dateFormat),
        provider: providerFirstName ? `${providerFirstName} ${providerLastName ?? ''}` : '',
        startTime: format(start, timeFormat),
        endTime: format(end, timeFormat),
        duration: `${durationMinutes || 1} ${durationMinutes > 1 ? 'mins' : 'min'}`,
      };
    }
  );
}
