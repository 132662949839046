import styled from 'styled-components';

export const IndicatorWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const CameraContainer = styled.div`
  padding-bottom: 16px;
  label {
    padding-right: 30px;
    display: flex;
    font-family: 'GT-America-Standard-Regular', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 130%;
    svg {
      margin-right: 16px;
      position: relative;
    }
  }
`;

export const VideoWrapper = styled.div<any>`
  text-align: center;
  background-color: black;
  height: ${({ height }) => height || 150}px;
  margin-top: 1em !important;
`;

export const NoDeviceLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.Primary};
`;

export const MicContainer = styled.div`
  label {
    padding-right: 30px;
    display: flex;
    font-family: 'GT-America-Standard-Regular', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 130%;
    svg {
      margin-right: 16px;
      position: relative;
    }
  }
`;

export const Spacer = styled.div`
  height: 3em;
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 24px 20px 20px 24px;
  background-color: ${({ theme }) => theme.colors.Silver};
  top: 10px;
  color: ${({ theme }) => theme.colors.Primary};
  width: 328px;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WarningNote = styled.p`
  font-family: 'GT-America-Standard-Regular', sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.72);
`;

export const SpeakerContainer = styled.div`
  padding-bottom: 16px;
  label {
    padding-right: 30px;
    display: flex;
    font-family: 'GT-America-Standard-Regular', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 130%;
    svg {
      margin-right: 16px;
      position: relative;
    }
  }
`;
