import uniqueId from 'lodash.uniqueid';
import styled from 'styled-components';
// @ts-ignore

const ParsedContent = styled.p`
  line-height: 1.5em;
  word-break: break-word;
  a {
    font-size: 1em !important;
  }
`;

type Props = {
  message: string;
  style?: object;
};

// TODO: check how to add style without passing prop, to styled-components
export function ParseHyperlinks({ message, style = {} }: Props) {
  if (!message) {
    return null;
  }
  const httpPattern = /(\b(https|http):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  const wwwPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  const emailPattern = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;

  const content = message.split('\n').map((m) => {
    const sanitizeText = m.replace(/(<([^>]+)>)/gi, '');

    let parseText = sanitizeText;

    const httpParse = sanitizeText.replace(httpPattern, '<a href="$1" target="_blank">$1</a>');
    const wwwParse = sanitizeText.replace(
      wwwPattern,
      '$1<a href="http://$2" target="_blank">$2</a>'
    );
    const emailParse = sanitizeText.replace(
      emailPattern,
      '<a href="mailto:$1" target="_blank" rel="noreferrer">$1</a>'
    );

    if (httpParse !== sanitizeText) {
      parseText = httpParse;
    } else if (wwwParse !== sanitizeText) {
      parseText = wwwParse;
    } else if (emailParse !== sanitizeText) {
      parseText = emailParse;
    }

    return (
      <ParsedContent
        key={uniqueId()}
        dangerouslySetInnerHTML={{ __html: parseText }}
        style={{ ...style }}
      />
    );
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{content}</>;
}
