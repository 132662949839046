import { ClinicSettings, ClinicFeature, clinicHasFeatureEnabled } from '@allurion/domain';
import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { InsightsApi } from 'src/api/Api';

import { useCurrentUser } from './useCurrentUser';

export function useUserClinicsSettings() {
  const { isAuthenticated } = useCurrentUser();

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    isAuthenticated ? 'clinicsFeatures' : null,
    () => {
      return InsightsApi.get<{ settings: ClinicSettings[] }>('/auth/clinics/settings').then(
        ({ settings }) => settings
      );
    },
    {
      revalidateOnFocus: false,
      suspense: true,
      dedupingInterval: ONE_MINUTE_IN_MS,
    }
  );

  function userHasAccessToFeature(feature: ClinicFeature) {
    return Boolean(
      data?.some((clinicSettings) => clinicHasFeatureEnabled(clinicSettings, feature))
    );
  }

  function clinicHasFeature(clinicId: string, feature: ClinicFeature) {
    const clinicSettings = data?.find((clinic) => clinic.ClinicID === clinicId);

    if (!clinicSettings) {
      return false;
    }

    return clinicHasFeatureEnabled(clinicSettings, feature);
  }

  return {
    getClinicSettings(clinicId?: string) {
      if (!clinicId) {
        return;
      }

      return data?.find((clinic) => clinic.ClinicID === clinicId);
    },
    hasAccessToFeature: userHasAccessToFeature,
    clinicHasFeature,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}
