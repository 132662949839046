export const ADD = 'add';
export const EDIT = 'edit';
export const NEW = 'new';
export const RESET = 'reset';
export const SAVED = 'saved';
export const COMPLETED = 'completed';
export const MAX_MSG_LEN = 1600; // https://www.twilio.com/docs/conversations/conversations-limits
export const UPLOAD_ATTACHMENT = 'upload-attachment';
export const SEND_BUTTON = 'send-button';
export const VIDEO_SIZE = { width: 300, height: 600 };
export const KEY_OVERVIEW = 'overview';
export const KEY_CHAT = 'chat';
export const KEY_SCALE = 'scale';
export const KEY_ACTIVITY = 'activity';
export const KEY_VIDEO = 'video';
export const KEY_NOTES = 'notes';
export const KEY_LIBRARY = 'library';
export const KEY_DAILYACTIONS = 'daily-actions';
export const PATIENT_PAGE_VALID_TABS = [
  KEY_OVERVIEW,
  KEY_CHAT,
  KEY_NOTES,
  KEY_SCALE,
  KEY_ACTIVITY,
  KEY_VIDEO,
  KEY_LIBRARY,
  KEY_DAILYACTIONS,
];
export const GENERIC_TOAST_ID = 'generic-toast';
export const NOTE_TOPIC_ADVERSE_EVENT_ID = '8';
export const PILOT_GROUP = 'PilotGroup';
export const VIDEO_INPUT_ID = 'video-input';
export const AUDIO_INPUT_ID = 'audio-input';
export const AUDIO_OUTPUT_ID = 'audio-output';
export const PROVIDERS_ROLES: { [key: string]: string } = {
  1: 'Super User',
  2: 'Admin',
  3: 'Staff',
  4: 'Coach',
};
export const PATIENT_NEW_TAB = 'patient_new_tab';
export const PATIENT_COPY_LINK = 'patient_copy_link';
