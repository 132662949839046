import { useState, useEffect } from 'react';
import { LocalAudioTrack } from 'twilio-video';

import pollAudioLevel from 'src/helpers/pollAudioLevel';
import { LinearProgress } from 'src/ui/LinearProgress';

import { IndicatorWrapper } from './styles';

type Props = {
  audioTrack: LocalAudioTrack | null;
};

export function AudioLevelIndicator({ audioTrack }: Props) {
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    const getAudioLevel = (lv: number) => setAudioLevel(lv > 0 ? lv : 0);

    pollAudioLevel(audioTrack, getAudioLevel);

    return () => {
      pollAudioLevel(null);
    };
  }, [audioTrack]);

  const value = audioLevel / 10;

  return (
    <IndicatorWrapper>
      <LinearProgress value={value} />
    </IndicatorWrapper>
  );
}
