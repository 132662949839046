import { defineMessages } from 'react-intl';

export default defineMessages({
  monthsInto: {
    id: 'weight-loss-distribution.monthsInto',
    defaultMessage: 'Months into Allurion program',
  },
  Percentile: {
    id: 'weight-loss-distribution-legend.Percentile',
    defaultMessage: 'Percentile',
  },
  Median: {
    id: 'weight-loss-distribution-legend.Median',
    defaultMessage: 'Median',
  },
  Month1: {
    id: 'weight-loss-bar-chart.month1',
    defaultMessage: 'Month 1',
  },
  Month2: {
    id: 'weight-loss-bar-chart.month2',
    defaultMessage: 'Month 2',
  },
  Month3: {
    id: 'weight-loss-bar-chart.month3',
    defaultMessage: 'Month 3',
  },
  Month4: {
    id: 'weight-loss-bar-chart.month4',
    defaultMessage: 'Month 4',
  },
  Month5: {
    id: 'weight-loss-bar-chart.month5',
    defaultMessage: 'Month 5',
  },
  Month6: {
    id: 'weight-loss-bar-chart.month6',
    defaultMessage: 'Month 6',
  },
  Month7: {
    id: 'weight-loss-bar-chart.month7',
    defaultMessage: 'Month 7',
  },
  Month8: {
    id: 'weight-loss-bar-chart.month8',
    defaultMessage: 'Month 8',
  },
  Month9: {
    id: 'weight-loss-bar-chart.month9',
    defaultMessage: 'Month 9',
  },
  Month10: {
    id: 'weight-loss-bar-chart.month10',
    defaultMessage: 'Month 10',
  },
  Month11: {
    id: 'weight-loss-bar-chart.month11',
    defaultMessage: 'Month 11',
  },
  Month12: {
    id: 'weight-loss-bar-chart.month12',
    defaultMessage: 'Month 12',
  },
});
