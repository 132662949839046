import styled from 'styled-components';

type Props = {
  title: string;
  description: string;
  icon: string;
};

export function DailyActionDialog({ title, icon, description }: Props) {
  return (
    <Body>
      <img src={icon} alt={`${title} Logo`} />
      <Title>{title}</Title>
      <Content>{description}</Content>
    </Body>
  );
}

const Title = styled.h3`
  font-family: 'GT-America-Standard-Bold', sans-serif;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 20px;
  line-height: 32px;
  margin: 32px 0px 16px;
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.Silver};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 24px;

  img {
    width: 56px;
  }
`;

const Content = styled.div`
  font-family: 'GT-America-Standard-Regular', sans-serif;
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px;
  font-size: 16px;
  white-space: pre-line;
  margin-bottom: 48px;
`;
