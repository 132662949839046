import { PencilIcon, Tooltip, useDialog } from '@allurion/ui';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';

import messages from './weight-entry-messages';
import { WeightEntryDialog } from './WeightEntryDialog';

type Props = {
  patientId: string;
  afterSave?: () => void;
};

export function EditStartingWeight({ patientId, afterSave }: Props) {
  const intl = useIntl();
  const { openDialog, Dialog } = useDialog<ComponentProps<typeof WeightEntryDialog>>({
    content: ({ close, ...contentProps }) => {
      return (
        <WeightEntryDialog
          {...contentProps}
          onClose={() => close?.()}
          afterSave={() => {
            close?.();
            afterSave?.();
          }}
        />
      );
    },
  });

  const onClick = () => {
    openDialog({
      title: intl.formatMessage(messages.addWeightDialogTitle),
      contentProps: {
        patientId,
        initialValues: { isStartingWeight: true },
        onClose: () => {},
      },
    });
  };

  return (
    <>
      <Tooltip text={intl.formatMessage(messages.editStartingWeightTooltip)} placement="bottom">
        <TrackedIconButton
          icon={<PencilIcon />}
          onClick={onClick}
          variant="icon"
          size="xs"
          trackLabel="edit-starting-weight"
        />
      </Tooltip>
      <Dialog />
    </>
  );
}
