import { useIntl } from 'react-intl';

import { AtRiskDisplay } from 'src/domain/patient/Patients';
import globalTranslations from 'src/messages/translations';
import { PatientSuccessPredictorBadge } from 'src/pages/ClinicPage/ClinicPatientsPage/PatientSuccessPredictorBadge';

import styles from './SuccessPredictorCard.module.scss';

export function SuccessPredictorCard({
  isAtRisk,
  at_risk_display,
  at_risk_score,
}: {
  isAtRisk: boolean;
  at_risk_display: AtRiskDisplay;
  at_risk_score: number;
}) {
  const intl = useIntl();

  return (
    <div className={styles.successPredictor}>
      <div className={styles.content}>
        <span className={styles.title}>{intl.formatMessage(globalTranslations.atRiskAtRisk)}</span>
        <span className={styles.subtitle}>
          {isAtRisk
            ? intl.formatMessage(globalTranslations.atRiskTooltipFlag)
            : intl.formatMessage(globalTranslations.atRiskTooltipCheckmark)}
        </span>
      </div>

      <span className={styles.action}>
        <PatientSuccessPredictorBadge
          at_risk_display={at_risk_display}
          at_risk_score={at_risk_score}
        />
      </span>
    </div>
  );
}
