import { useIntl } from 'react-intl';

import { DailyActionsDoughnutChart } from 'src/components/DailyActionsDoughnutChart/DailyActionsDoughnutChart';

import { dailyActionLegend } from './daily-actions';

import styles from './DailyActions.module.scss';

type Props = {
  id: string;
  completed: number;
  not_completed: number;
  no_response: number;
};

export function DailyActionsOverview({ id, completed, not_completed, no_response }: Props) {
  const intl = useIntl();

  const unit = intl.formatMessage({
    id: 'daily-actions.overview.days',
    defaultMessage: 'days',
  });

  const data = [
    {
      value: completed,
      color: dailyActionLegend.completed.color,
      label: intl.formatMessage(dailyActionLegend.completed.message),
    },
    {
      value: not_completed,
      color: dailyActionLegend.not_completed.color,
      label: intl.formatMessage(dailyActionLegend.not_completed.message),
    },
    {
      value: no_response,
      color: dailyActionLegend.no_response.color,
      label: intl.formatMessage(dailyActionLegend.no_response.message),
    },
  ];

  return (
    <div className={styles.overview}>
      <div className={styles.content}>
        <ul>
          {data.map((d) => (
            <li key={d.label} style={{ color: d.color }}>
              <span className="value">{`${d.value} ${unit}`}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.chart}>
        <DailyActionsDoughnutChart id={id} data={data} unit={unit} />
      </div>
    </div>
  );
}
