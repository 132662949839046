import { useEffect, useRef } from 'react';

function ChatScrollToBottom() {
  const elementRef = useRef<HTMLDivElement | null>(null);

  // TODO:
  // - we should show a "new message" indicator if we are not in view
  // - if scroll is over X amount of pixels over ScrollToBottom, scroll automatically
  //    - this is to make sure when a new message arrives, for it to scroll too!
  useEffect(() => {
    elementRef?.current?.scrollIntoView();
  });

  return <div ref={elementRef} />;
}

export default ChatScrollToBottom;
