import { PageHeader } from '@allurion/ui';
import { Suspense } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Seo } from 'src/components/Seo';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { useClinicStats } from 'src/hooks/useClinicStats';

import { Container, InnerContainer } from '../shared-page-elements';

import { ClinicTrends } from './ClinicTrends/ClinicTrends';
import ClinicStatCards from './StatCards/ClinicStatsCards';

export function ClinicAnalyticsPage() {
  const intl = useIntl();
  const { clinicId } = useParams();

  const { isLoading: isLoadingClinicStats } = useClinicStats(clinicId);

  if (!clinicId) {
    throw new Error('Invalid clinic');
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'clinic-analytics.header',
          description: 'Clinic Analytics page header',
          defaultMessage: 'Analytics',
        })}
      />

      <Container>
        <Seo title="Clinic" />

        <InnerContainer>
          <Suspense fallback={<InlineLoader />}>
            <ClinicStatCards
              clinicId={clinicId}
              key={`StatsCard-${clinicId}`}
              isLoadingClinicStats={isLoadingClinicStats}
            />

            <ClinicTrends
              clinicId={clinicId}
              key={`ClinicTrends-${clinicId}`}
              isLoadingClinicStats={isLoadingClinicStats}
            />
          </Suspense>
        </InnerContainer>
      </Container>
    </>
  );
}
