import { isNull, isUndefined } from '@allurion/utils';

import { Unit } from 'src/domain/User';
import { Logger } from 'src/services/Logger';

export function cmToHeightInches(numInCm: number) {
  const inches = numInCm / 2.54;
  const feet = Math.floor(inches / 12);
  const finalInches = Math.round(inches % 12);

  return `${feet}'${finalInches}"`;
}

export const poundsPerKilogram = 2.2046226218;

export function getUnitFactor(unit: string) {
  switch (unit?.toLowerCase() as Unit) {
    case 'lb':
      return poundsPerKilogram;

    // kg is the default
    default:
      if (unit !== 'kg') {
        Logger.captureException(new Error(`Invalid unit: [${unit}]`));
      }

      return 1;
  }
}

export function convertWeight(value: any, unit: string) {
  if (isUndefined(value) || isNull(value)) {
    return 0;
  }

  const unitFactor = getUnitFactor(unit);

  return fixNumbers(value, 1, unitFactor);
}

const kgPerPoundFactor = 0.453592;

export function toKg(value: number, currentUnit: string) {
  switch (currentUnit) {
    case 'lb':
      return roundUpHalf(value * kgPerPoundFactor, 2);

    case 'stone':
      return roundUpHalf(value * 6.35, 2);

    default:
      return value;
  }
}

export function toPounds(value: number, currentUnit: string) {
  switch (currentUnit) {
    case 'kg':
      return roundUpHalf(value / kgPerPoundFactor);

    case 'stone':
      return roundUpHalf(value * 14);

    default:
      return value;
  }
}

export function toStone(value: number, currentUnit: string) {
  switch (currentUnit) {
    case 'kg':
      return roundUpHalf(value / 6.35029);

    case 'pound':
      return roundUpHalf(value / 14);

    default:
      return value;
  }
}

function roundUpHalf(value: number, decimals = 1) {
  const factor = 10 ** decimals;

  return Math.ceil(value * factor) / factor;
}

export function fixNumbers(str: string, places: number, multiplier: number) {
  const num = Number(str) * multiplier;
  const fixed = num.toFixed(places);

  const formattedZero = Number(0).toFixed(places);

  // Avoid negative zeros (for example -0.0)
  if (fixed === `-${formattedZero}`) {
    return formattedZero;
  }

  return fixed === 'NaN' ? 'N/A' : fixed;
}
