import { CalendarIcon } from '@allurion/ui';

import styles from './ValueWithCalendar.module.scss';

export function ValueWithCalendar({ value }: { value: number }) {
  return (
    <span className={styles.container}>
      <CalendarIcon />
      {value}
    </span>
  );
}
