/**
 *
 * Scale Data Menu
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    id: 'scale-data-menu.date',
    defaultMessage: 'Date',
  },
  weight: {
    id: 'scale-data-menu.weight',
    defaultMessage: 'Weight',
  },
  bmi: {
    id: 'scale-data-menu.bmi',
    defaultMessage: 'BMI',
  },
  bodyFat: {
    id: 'scale-data-menu.bodyFat',
    defaultMessage: 'Body Fat',
  },
  muscleMass: {
    id: 'scale-data-menu.muslceMass',
    defaultMessage: 'Muscle Mass',
  },
  boneMass: {
    id: 'scale-data-menu.boneMass',
    defaultMessage: 'Bone Mass',
  },
  bodyWater: {
    id: 'scale-data-menu.bodyWater',
    defaultMessage: 'Body Water',
  },
  bmr: {
    id: 'scale-data-menu.bmr',
    defaultMessage: 'BMR',
  },
  visceralFat: {
    id: 'scale-data-menu.visceralFat',
    defaultMessage: 'Visceral Fat',
  },
});
