/**
 *
 * Messages for clinic patients table
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  searchPlaceholder: {
    id: 'clinic-patients-data-table-search.searchPlaceholder',
    defaultMessage: 'Search by Name',
  },
  firstName: {
    id: 'clinic-patients-data.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'clinic-patients-data.lastName',
    defaultMessage: 'Last Name',
  },
  age: {
    id: 'clinic-patients-data.age',
    defaultMessage: 'Age',
  },
  currentWeight: {
    id: 'clinic-patients-data.currentWeight',
    defaultMessage: 'Current Weight',
  },
  lastWeighInDate: {
    id: 'clinic-patients-data.lastWeighInDate',
    defaultMessage: 'Last Weigh-in',
  },
  startingWeight: {
    id: 'clinic-patients-data.startingWeight',
    defaultMessage: 'Starting Weight',
  },
  weightLost: {
    id: 'clinic-patients-data.weightLost',
    defaultMessage: 'Weight Loss',
  },
  weightChange: {
    id: 'clinic-patients-data.weightChange',
    defaultMessage: 'Weight Change',
  },
  TBWL: {
    id: 'clinic-patients-data.TBWL',
    defaultMessage: 'TBWL %',
  },
  DaysSinceBalloon: {
    id: 'clinic-patients-data.daysSinceBalloon',
    defaultMessage: 'Days Since Balloon Placement',
  },
  treatmentType: {
    id: 'treatment-list.treatment-type',
    defaultMessage: 'Treatment Type',
  },
  resend: {
    id: 'clinic-patients-data.resend',
    defaultMessage: 'Resend',
  },
  atRisk: {
    id: 'at-risk.at-risk',
    defaultMessage: 'Success Predictor',
  },
  lastWeighIn: {
    id: 'clinic-patients-data.lastWeighIn',
    defaultMessage: 'Days Since Last Weigh-in',
  },
  // Uses same translations keys as add-patient.
  // Previous ones from `clinic-patients-data.*` has never been transalated.
  balloonType: {
    id: 'add-patient.balloon',
    defaultMessage: 'Allurion Balloon',
  },
  otherType: {
    id: 'add-patient.other',
    defaultMessage: 'Other',
  },
  invitedType: {
    id: 'clinic-patients-data.invitedType',
    defaultMessage: 'Invited',
  },
  openNewTabWindow: {
    id: 'clinic-patients-data.openNewTabWindow',
    defaultMessage: 'Open patient page in new tab/window',
  },
  copyPatientLink: {
    id: 'clinic-patients-data.copyPatientLink',
    defaultMessage: 'Copy link',
  },
  copyPatientLinkSuccess: {
    id: 'clinic-patients-data.copyPatientLink.success',
    defaultMessage: 'Patient page link has been copied to the clipboard.',
  },
  copyPatientLinkFail: {
    id: 'clinic-patients-data.copyPatientLink.fail',
    defaultMessage: 'Failed to copy patient page link.',
  },
  patientResendInvitation: {
    id: 'patient-resend-invitation',
    defaultMessage: 'Invitation has been sent to patient {name}',
  },
});
