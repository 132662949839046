/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'login.title',
    defaultMessage: 'Welcome to the Allurion Insights. Log in to your account below. ',
  },
  email: {
    id: 'login.email',
    defaultMessage: 'Enter your Email',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Enter your Password',
  },
  submitBtn: {
    id: 'login.submitBtn',
    defaultMessage: 'LOGIN',
  },
  resetPassword: {
    id: 'login.resetPassword',
    defaultMessage: 'Reset Password',
  },
});
