import { useIntl, FormatDateOptions } from 'react-intl';
import styled from 'styled-components';

import translations from '../ManageUsers.translations';

type Props = {
  hoursUntilExpires: number;
};

export function ExpirationWarning({ hoursUntilExpires }: Props) {
  const intl = useIntl();

  const expirationDateAndTime = (): Date => {
    const expireDate = new Date();

    expireDate.setHours(expireDate.getHours() + hoursUntilExpires);

    return expireDate;
  };

  const dateValue: Date = expirationDateAndTime();
  const dateOpts: FormatDateOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  return (
    <Warning>
      {intl.formatMessage(translations.expirationWarningCreatePasswordExpiration)}
      {`${intl.formatDate(dateValue, dateOpts)} ${intl.formatTime(new Date())}`}
    </Warning>
  );
}

const Warning = styled.div`
  color: ${({ theme }) => theme.colors.Alert};
  font-size: 18px;
  line-height: 30px;
`;
