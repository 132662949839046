import { useIntl } from 'react-intl';
import styled from 'styled-components';

import colors from 'src/styles/theme/colors';

import messages from './weight-loss-by-percentage-chart.messages';

export function Legend() {
  const intl = useIntl();

  return (
    <Container>
      <LegendWrapper>
        <LegendItem>
          <Circle color={colors.DarkBlue} />
          <TextSpan>{intl.formatMessage({ id: messages.Median.id })} TBWL</TextSpan>
        </LegendItem>
        <RowItems>
          <LegendItem>
            <Circle color={colors.MintGreen} />
            <TextSpan>95th {intl.formatMessage({ id: messages.Percentile.id })} TBWL</TextSpan>
          </LegendItem>
          <LegendItem>
            <Circle color={colors.GraphBlue} />
            <TextSpan>75th {intl.formatMessage({ id: messages.Percentile.id })} TBWL</TextSpan>
          </LegendItem>
        </RowItems>
        <RowItems>
          <LegendItem>
            <Circle color={colors.SeaGreen} />
            <TextSpan>25th {intl.formatMessage({ id: messages.Percentile.id })} TBWL</TextSpan>
          </LegendItem>
          <LegendItem>
            <Circle color={colors.DarkGrey} />
            <TextSpan>5th {intl.formatMessage({ id: messages.Percentile.id })} TBWL</TextSpan>
          </LegendItem>
        </RowItems>
      </LegendWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowItems = styled.div`
  display: flex;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;

  &:first-of-type {
    margin-right: 5px;
  }

  ${({ theme }) => theme.media.min.tablet`
    margin-bottom: 15px;

    &:first-of-type {
      margin-right: 25px;
    }
  `}
`;

const Circle = styled.div<any>`
  background-color: ${({ color }) => color};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 10px;

  ${({ theme }) => theme.media.min.tablet`
    width: 14px;
    height: 14px;
  `}
`;

const TextSpan = styled.span`
  font-size: 6px;
  ${({ theme }) => theme.media.min.tablet`
    font-size: 12px;
  `}
`;
