import { defineMessages } from 'react-intl';

export default defineMessages({
  clinicSettingsTitle: {
    id: 'clinic-settings.title',
    defaultMessage: 'Clinic Settings',
  },
  clinicSettingsClinicInformation: {
    id: 'clinic-settings.clinic-information',
    defaultMessage: 'Clinic Information',
  },
  clinicSettingsClinicInformationTooltip: {
    id: 'clinic-settings.clinic-information.tooltip',
    defaultMessage:
      'This is how the clinic appears when patients search for it. Please contact Customer Support to change this information.',
  },
  clinicSettingsFeatures: {
    id: 'clinic-settings.features',
    defaultMessage: 'Features',
  },
  clinicSettingsFeaturesTooltip: {
    id: 'clinic-settings.features.tooltip',
    defaultMessage:
      'This enables or disables these features for this clinic location. All disabled features are hidden for both providers and patients.',
  },
  clinicSettingsMessaging: {
    id: 'clinic-settings.messaging',
    defaultMessage: 'Messaging',
  },
  clinicSettingsMessagingUncheck: {
    id: 'clinic-settings.messaging.tooltip',
    defaultMessage:
      'Disabling this feature hides both the messaging tab and messaging history for both providers and patients.',
  },
  clinicSettingsVideo: {
    id: 'clinic-settings.video',
    defaultMessage: 'Video consults',
  },
  clinicSettingsVideoUncheck: {
    id: 'clinic-settings.video.tooltip',
    defaultMessage:
      'Disabling this feature hides both the video consult tab and video consult history for both providers and patients.',
  },
  clinicSettingsNotes: {
    id: 'clinic-settings.notes',
    defaultMessage: 'Notes',
  },
  clinicSettingsNotesUncheck: {
    id: 'clinic-settings.notes.tooltip',
    defaultMessage:
      'Disabling this feature hides both the notes tab and any previously created notes.',
  },
  clinicSettingsUnitMeasurement: {
    id: 'clinic-settings.unit-measurement',
    defaultMessage: 'Unit of Measurement Preference',
  },
  clinicSettingsUnitMeasurementTooltip: {
    id: 'clinic-settings.unit-measurement.tooltip',
    defaultMessage:
      'This sets the default unit for the clinic. Staff members can still set their individual preferences.',
  },
  clinicSettingsSave: {
    id: 'clinic-settings.save',
    defaultMessage: 'Save',
  },
  clinicSettingsSuccess: {
    id: 'clinic-settings.success',
    defaultMessage: 'Clinic settings has been updated',
  },
  clinicSettingsFailure: {
    id: 'clinic-settings.failure',
    defaultMessage: 'There has been an error, please try again',
  },
  clinicSettingsTreatments: {
    id: 'clinic-settings.treatments',
    defaultMessage: 'Treatments Offered',
  },
  clinicSettingsTreatmentsTooltip: {
    id: 'clinic-settings.treatments.tooltip',
    defaultMessage:
      "Please select the treatments offered by your clinic. This will allow you to specify each patient's treatments in their profile",
  },
  clinicSettingsTreatmentAllurion: {
    id: 'treatment_type_1',
    defaultMessage: 'Allurion Balloon Program',
  },
  clinicSettingsTreatmentNonAllurion: {
    id: 'treatment_type_2',
    defaultMessage: 'Non-Allurion Balloon',
  },
  clinicSettingsTreatmentBariatric: {
    id: 'treatment_type_3',
    defaultMessage: 'Bariatric Surgery',
  },
  clinicSettingsTreatmentWeightLoss: {
    id: 'treatment_type_4',
    defaultMessage: 'Weight-loss medication',
  },
  clinicSettingsTreatmentOther: {
    id: 'treatment_type_5',
    defaultMessage: 'Other weight-loss program',
  },
  clinicSettingsRiskAnalysis: {
    id: 'clinic-settings.risk-analysis',
    defaultMessage: 'Success Predictor',
  },
  clinicSettingsClinicLedOnboarding: {
    id: 'clinic-settings.clinic-led-onboarding',
    defaultMessage: 'Invite new patients from Allurion Insights',
  },
  clinicSettingsAutomaticContent: {
    id: 'clinic-settings.automatic-content',
    defaultMessage: 'Automatic content delivery',
  },
  clinicSettingsManualWeightEntry: {
    id: 'clinic-settings.manual-weight-entry',
    defaultMessage: 'Manual Weight Entry',
  },
  clinicSettingsExportData: {
    id: 'clinic-settings.export-data',
    defaultMessage: 'Export data',
  },
  clinicSettingsEmailNoitifications: {
    id: 'clinic-settings.email-notifications',
    defaultMessage: 'Email notifications',
  },
  clinicNameFieldLabel: {
    id: 'clinic-settings.clinic-name-field-label',
    defaultMessage: 'Name',
  },
  clinicAddressFieldLabel: {
    id: 'clinic-settings.clinic-address-field-label',
    defaultMessage: 'Address',
  },
  clinicIdFieldLabel: {
    id: 'clinic-settings.clinic-id-field-label',
    defaultMessage: 'ID',
  },
  clinicIdTooltip: {
    id: 'clinic-id.tooltip',
    defaultMessage:
      'The Clinic ID can be used by patients when installing the Allurion app so they are assigned to your clinic.',
  },
});
