import { ChevronIcon, TrashCanIcon } from '@allurion/ui';
import { toLocalizedDate } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { PatientWeight } from 'src/domain/patient/Patients';
import { convertWeight } from 'src/helpers/convertions';
import { useLocale } from 'src/hooks/useLocale';

import messages from './datatable-messages';

import styles from './PatientScaleDataCards.module.scss';

type CardsProps = {
  data: PatientWeight[];
  unitsPreference: string;
  deleteWeightEntry: (weightEntryId: string) => void;
};

type CardProps = {
  patientScaleData: PatientWeight;
  unitsPreference: string;
  deleteWeightEntry: (weightEntryId: string) => void;
};

export function PatientScaleDataCards({ data, unitsPreference, deleteWeightEntry }: CardsProps) {
  return (
    <div className={styles.cards}>
      {data.map((patientScaleData) => (
        <Card
          key={patientScaleData.UserInfoId}
          patientScaleData={patientScaleData}
          unitsPreference={unitsPreference}
          deleteWeightEntry={deleteWeightEntry}
        />
      ))}
    </div>
  );
}

function Card({ patientScaleData, unitsPreference, deleteWeightEntry, ...props }: CardProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useLocale();
  const intl = useIntl();

  return (
    <div className={styles.card} {...props}>
      <div className={styles.header}>
        <div className={styles.header__createdDate}>
          {toLocalizedDate(patientScaleData.CreatedDate, locale) || 'No Date'}
        </div>
        <div className={styles.header__content}>
          {`${convertWeight(patientScaleData.Weight, unitsPreference)} ${unitsPreference}`}
          <TrackedIconButton
            size="xs"
            variant="icon"
            onClick={() => setIsOpen(!isOpen)}
            icon={<ChevronIcon direction={isOpen ? 'up' : 'down'} />}
            trackLabel="show-patient-scale-data-details"
          />
        </div>
      </div>
      {isOpen && (
        <div className={styles.body}>
          <table>
            <tbody>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bmi)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.BMI}</span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bodyFat)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.BodyFat}</span>
                  </td>
                </td>
              </tr>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.muslceMass)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.MuscleMass}</span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.boneMass)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.Bone}</span>
                  </td>
                </td>
              </tr>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bodyWater)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.BodyWater}</span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.bmr)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.BMR}</span>
                  </td>
                </td>
              </tr>
              <tr>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>{intl.formatMessage(messages.visceralFat)}</span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.VisceralFat}</span>
                  </td>
                </td>
                <td className={styles.metric}>
                  <td>
                    <span className={styles.key}>
                      {intl.formatMessage(messages.weightSourceTypeColumn)}
                    </span>
                  </td>
                  <td>
                    <span className={styles.value}>{patientScaleData.weight_source_type}</span>
                  </td>
                </td>
              </tr>
              <div
                className={styles.actions}
                onClick={() => deleteWeightEntry(patientScaleData.UserInfoId.toString())}
              >
                <TrashCanIcon />
              </div>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
