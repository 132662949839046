import { PlusIcon, useDialog } from '@allurion/ui';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';

import messages from './weight-entry-messages';
import { WeightEntryDialog } from './WeightEntryDialog';

type Props = {
  patientId: string;
  afterSave?: () => void;
};

export function AddWeightEntry({ patientId, afterSave }: Props) {
  const intl = useIntl();

  const { openDialog, Dialog } = useDialog<ComponentProps<typeof WeightEntryDialog>>({
    content: ({ close, ...contentProps }) => {
      return (
        <WeightEntryDialog
          {...contentProps}
          onClose={() => close?.()}
          afterSave={() => {
            close?.();
            afterSave?.();
          }}
        />
      );
    },
  });

  const onClick = () => {
    openDialog({
      title: intl.formatMessage(messages.addWeightDialogTitle),
      contentProps: {
        patientId,
        initialValues: { isStartingWeight: false },
        onClose: () => {},
      },
    });
  };

  return (
    <>
      <TrackedIconButton
        size="xs"
        variant="primary"
        icon={<PlusIcon />}
        onClick={onClick}
        trackLabel="open-add-weight-entry-dialog"
      />
      <Dialog />
    </>
  );
}
