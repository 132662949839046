import { isPatientGroupingEnabled } from '@allurion/domain';
import { useIntl } from 'react-intl';

import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { usePatientOverview } from 'src/hooks/usePatientOverview';
import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { usePatientTreatments } from 'src/hooks/usePatientTreatments';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import translations from 'src/messages/translations';

import { PatientProfileCard } from './PatientProfileCard';
import { PatientProgressOverviewCard } from './PatientProgressOverviewCard';
import { PatientProvidersCard } from './PatientProvidersCard/PatientProvidersCard';
import { PatientTbwlCard } from './PatientTbwlCard';
import { PatientTreamentsCard } from './PatientTreamentsCard/PatientTreamentsCard';

import styles from './PatientOverview.module.scss';

type Props = {
  patientId: string;
  clinicId?: string;
  reloadData: () => void;
};

export function PatientOverview({ patientId, clinicId, reloadData }: Props) {
  const intl = useIntl();
  const { overviewData: patientOverviewData, isLoading: isLoadingPatientOverview } =
    usePatientOverview(patientId);
  const { settings } = useClinicSettings(clinicId);
  const { patient: patientInfo } = usePatientProfile(patientId);
  const [width] = useThrottledWindowSize();
  const { treatments, isLoading } = usePatientTreatments(patientId);
  const hasNoTreatments = treatments.length === 0 && !isLoading;

  const isMobile = width < 1024;

  const cards = [
    <PatientProgressOverviewCard
      key="progress-overview"
      patientId={patientId}
      data={patientOverviewData}
      patient={patientInfo}
      settings={settings}
      reloadData={reloadData}
    />,
    <PatientTbwlCard isLoading={isLoadingPatientOverview} data={patientOverviewData} key="tbwl" />,
    <PatientProfileCard patient={patientInfo} key="profile" />,
    <PatientTreamentsCard
      key="treatments"
      patientId={patientId}
      clinicId={clinicId!}
      cardTitle={intl.formatMessage(translations.treatmentHistory)}
    />,
    isPatientGroupingEnabled(settings) && (
      <PatientProvidersCard patientId={patientId} clinicId={clinicId} key="providers" />
    ),
  ];

  /* move the treatments card up if hasNoTreatments */
  if (isMobile && hasNoTreatments) {
    cards.splice(0, 0, cards.splice(3, 1)[0]);
  }

  return (
    <div className={styles.container}>
      {isMobile ? (
        cards
      ) : (
        <>
          <div className={styles.column}>
            {/* move the treatments card up if hasNoTreatments */}
            {hasNoTreatments ? cards[3] : cards[0]}
            {hasNoTreatments ? cards[0] : cards[3]}
            {cards[4]}
          </div>
          <div className={styles.column}>
            {cards[1]}
            {cards[2]}
          </div>
        </>
      )}
    </div>
  );
}
