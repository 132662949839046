import { Button, ButtonProps } from '../Button/Button';
import styles from './EmptyState.module.scss';

export type EmptyStateProps = {
  /** Component title */
  title: string;
  /** Component subtitle  */
  subtitle?: string;
  /** Component icon */
  icon: React.ReactNode | null;
  /** Array of buttons */
  buttons?: ButtonProps[];
};
/**
 * Primary UI component for user interaction
 */
export function EmptyState({ title, subtitle, buttons, icon, ...props }: EmptyStateProps) {
  const MAX_BUTTONS = 2;
  return (
    <div className={styles.emptyState} {...props}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <h3>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className={styles.buttonsWrapper}>
        {buttons?.slice(0, MAX_BUTTONS).map((button, index) => <Button key={index} {...button} />)}
      </div>
    </div>
  );
}
