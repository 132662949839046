import styled from 'styled-components';

export const InnerContainer = styled.div`
  padding: 16px;
  height: calc(
    100vh - 61px - 52px
  ); // 52px is the height of the navbar, 61px is the height of the header
  widht: 100%;
  overflow-y: auto;

  ${({ theme }) => theme.media.max.tablet`
    height: calc(100vh - 88px - 52px - 61px); // 52px is the height of the navbar, 88px is the height of the header
  }
  `}
`;

export const Spacer = styled.div`
  margin-top: 50px;
`;

export const Container = styled.div`
  color: #000;
  margin: 0 auto;
  width: 100%;
`;

export const PageTitle = styled.h1`
  font-size: 28px;
  font-family: 'GT-America-Standard-Medium', sans-serif;
  height: 100%;
  margin: 25px 0;
  color: ${({ theme }) => theme.colors.Primary};
`;

export const FullscreenContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.Primary};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${({ theme }) => theme.colors.White};
  height: 100vh;
  height: 100vh;
  width: 100vw;
  padding-bottom: 100px;
  overflow: auto;
  z-index: 100;
`;
