import { enhanceError } from '@allurion/utils';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Room, Participant, connect } from 'twilio-video';

import { useTrackEvent } from 'src/analytics/analytics';
import { toastError } from 'src/components/ui/toasts';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { Logger } from 'src/services/Logger';
import { sendNotification } from 'src/twilio/TwilioApi';
import { VIDEO_SIZE } from 'src/utils/constants';

import { getTwilioToken } from './twilio-token';

let timer: NodeJS.Timeout;
const timeout = 5000 * 60; // 5mins

export function useTwilioVideo({
  timeoutCallback,
  patientUsername,
}: {
  timeoutCallback?: () => void;
  patientUsername?: string;
}) {
  const intl = useIntl();

  const { user } = useCurrentUser();
  const providerUsername = user.username;
  const { trackTwilioFailedVideoCall } = useTrackEvent();

  const [room, setRoom] = useState<Room | null>(null);
  const [participants, setParticipants] = useState<Participant[] | []>([]);
  const [muteAudio, setMuteAudio] = useState<boolean>(false);
  const [stopVideo, setStopVideo] = useState<boolean>(false);
  const [remoteAudio, setRemoteAudio] = useState<boolean>(true);
  const [remoteVideo, setRemoteVideo] = useState<boolean>(true);
  const [isCallOver, setIsCallOver] = useState<boolean>(true);
  const [startCall, setStartCall] = useState<boolean>(false);

  const disconnectVideoCall = useCallback(() => {
    const isConnected = room?.localParticipant?.state === 'connected';

    if (!isConnected && !room) {
      return;
    }

    // @ts-ignore FIXME: TS says stop method doesn't exist
    room?.localParticipant.tracks.forEach((trackPub) => trackPub.track.stop());
    room?.removeAllListeners();
    room?.disconnect();

    setStartCall(false);
    setRoom(null);
    if (timer) {
      clearTimeout(timer);
    }
  }, [room]);

  useEffect(() => {
    const onParticipantConnected = (participant: Participant) => {
      clearTimeout(timer);
      setParticipants((prev) => [...prev, participant]);
      setIsCallOver(false);
    };

    const onParticipantDisconnected = (participant: Participant) => {
      setParticipants((prev) => prev.filter((p) => p !== participant));
      setIsCallOver(true);
    };

    const onParticipantReconnected = () => {
      setIsCallOver(false);
    };

    const onParticipantReconnecting = () => {
      setIsCallOver(true);
    };

    const onTrackDisabled = ({ kind }: { kind: string }) => {
      if (kind === 'video') {
        setRemoteVideo(false);
      } else if (kind === 'audio') {
        setRemoteAudio(false);
      }
    };

    const onTrackEnabled = ({ kind }: { kind: string }) => {
      if (kind === 'video') {
        setRemoteVideo(true);
      } else if (kind === 'audio') {
        setRemoteAudio(true);
      }
    };

    if (room) {
      room.on('participantConnected', onParticipantConnected);
      room.on('participantDisconnected', onParticipantDisconnected);
      room.on('participantReconnected', onParticipantReconnected);
      room.on('participantReconnecting', onParticipantReconnecting);
      room.on('trackDisabled', onTrackDisabled);
      room.on('trackEnabled', onTrackEnabled);
      room.participants?.forEach(onParticipantConnected);
      room.localParticipant?.audioTracks?.forEach(
        ({ isTrackEnabled }: { isTrackEnabled: boolean }) => setMuteAudio(!isTrackEnabled)
      );
      room.localParticipant?.videoTracks?.forEach(
        ({ isTrackEnabled }: { isTrackEnabled: boolean }) => setStopVideo(!isTrackEnabled)
      );
    }

    return () => {
      participants?.map((p) => p?.removeAllListeners());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    const beginTimeout = () => {
      timer = setTimeout(() => {
        disconnectVideoCall();
        timeoutCallback?.();
      }, timeout);
    };

    if (startCall && !timer) {
      beginTimeout();
    }
  }, [disconnectVideoCall, startCall, timeoutCallback]);

  useEffect(() => {
    const joinRoom = async (providerUsername: string) => {
      const roomConfig = {
        name: `${providerUsername}_${patientUsername}`,
        audio: true,
        video: VIDEO_SIZE,
      };
      const token = await getTwilioToken(providerUsername);
      const roomData = await connect(token, roomConfig);

      if (roomData) {
        const notification = {
          type: 1,
          identity: patientUsername,
          roomId: `${providerUsername}_${patientUsername}`,
          body: 'Incoming Video Consult', // <- see how to localize from the Allurion app
        };

        await sendNotification(notification);
        setRoom(roomData);
        setIsCallOver(false);
      }
    };

    const shouldJoinRoom = !room && patientUsername && startCall && providerUsername;

    if (shouldJoinRoom) {
      joinRoom(providerUsername).catch((error) => {
        Logger.captureException(enhanceError(error, { prefix: 'useTwilioVideo' }));
        trackTwilioFailedVideoCall({ error });
        toastError(
          intl.formatMessage({
            defaultMessage: 'Cannot join video call. Please try again.',
            id: 'twilio.failed-to-join-video-call',
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, patientUsername, startCall, providerUsername]);

  return {
    room,
    setRoom,
    participants,
    muteAudio,
    setMuteAudio,
    stopVideo,
    setStopVideo,
    isCallOver,
    setIsCallOver,
    remoteAudio,
    setRemoteAudio,
    remoteVideo,
    setRemoteVideo,
    startCall,
    setStartCall,
    disconnectVideoCall,
  };
}
