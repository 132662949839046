import { defineMessages } from 'react-intl';

export default defineMessages({
  addWeightDialogTitle: {
    id: 'weight-entry.add-weight-dialog-title',
    defaultMessage: 'Add weight data',
  },
  addWeightEntryBtn: {
    id: 'weight-entry.add-weight-entry-btn-label',
    defaultMessage: 'Add weight data',
  },
  dateLabel: {
    id: 'weight-entry.date-input-Label',
    defaultMessage: 'Date',
  },
  datePlaceholder: {
    id: 'weight-entry.date-input-placeholder',
    defaultMessage: 'Select date',
  },
  weightLabel: {
    id: 'weight-entry.weight-input-Label',
    defaultMessage: 'Weight',
  },
  weightPlaceholder: {
    id: 'weight-entry.weight-input-placeholder',
    defaultMessage: 'Type the weight',
  },
  submitBtn: {
    id: 'weight-entry.submit-btn',
    defaultMessage: 'Add',
  },
  cancelBtn: {
    id: 'weight-entry.cancel-btn',
    defaultMessage: 'Cancel',
  },
  isStartingWeightLabel: {
    id: 'weight-entry.is-starting-weight-label',
    defaultMessage: 'Set as starting weight',
  },
  addSuccessMessage: {
    id: 'weight-entry.add-weight-entry-success-message',
    defaultMessage: 'Weight data added.',
  },
  addErrorMessage: {
    id: 'weight-entry.add-weight-entry-error',
    defaultMessage: 'Not possible to add a new weight.',
  },
  deleteSuccessMessage: {
    id: 'weight-entry.delete-weight-entry-success-message',
    defaultMessage: 'Weight data removed.',
  },
  deleteErrorMessage: {
    id: 'weight-entry.delete-weight-entry-error',
    defaultMessage: 'Not possible to remove a weight data.',
  },
  requiredEntryDate: {
    id: 'weight-entry.entry-date-input-required',
    defaultMessage: 'Enter a valid date for this weight.',
  },
  requiredWeight: {
    id: 'weight-entry.weight-input-required',
    defaultMessage: 'Enter a value between {min} and {max}.',
  },
  deleteConfirmationQuestion: {
    id: 'weight-entry.delete-confirmation-question',
    defaultMessage: 'Are you sure you want to delete this weight data?',
  },
  deleteConfirmationAnswer: {
    id: 'weight-entry.delete-confirmation-answer',
    defaultMessage: 'Yes, delete',
  },
  editStartingWeightTooltip: {
    id: 'weight-entry.edit-starting-weight-tooltip',
    defaultMessage: 'Edit starting weight',
  },
  removeWeightEntryTooltip: {
    id: 'weight-entry.remove-weight-entry-tooltip',
    defaultMessage: 'Delete entry',
  },
});
