type Props = {
  stroke?: string;
};

export function Question({ stroke = '#111836' }: Props) {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6504 3.26788C6.23211 3.26788 2.65039 6.8496 2.65039 11.2679C2.65039 15.6862 6.23211 19.2679 10.6504 19.2679C15.0687 19.2679 18.6504 15.6862 18.6504 11.2679C18.6504 6.8496 15.0687 3.26788 10.6504 3.26788Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M10.7126 8.42181C10.8773 8.42181 11.0107 8.2875 11.0107 8.12181C11.0107 7.95613 10.8773 7.82181 10.7126 7.82181C10.5479 7.82181 10.4144 7.95613 10.4144 8.12181C10.4144 8.2875 10.5479 8.42181 10.7126 8.42181V8.42181"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.7104 10.7235V14.914" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="10.5" cy="10.5" r="10.5" fill={stroke} />
      <path
        d="M8.885 6.752L7.355 6.31C7.525 5.61867 7.92733 5.052 8.562 4.61C9.19667 4.15667 9.922 3.93 10.738 3.93C11.69 3.93 12.4833 4.23033 13.118 4.831C13.7527 5.43167 14.07 6.16267 14.07 7.024C14.07 7.51133 13.968 7.96467 13.764 8.384C13.56 8.792 13.3107 9.13767 13.016 9.421C12.7327 9.693 12.4437 9.93667 12.149 10.152C11.8657 10.3673 11.622 10.5713 11.418 10.764C11.214 10.9567 11.112 11.1323 11.112 11.291C11.112 11.5063 11.2593 11.7443 11.554 12.005L10.483 13.127C9.82567 12.6283 9.497 12.022 9.497 11.308C9.497 10.9227 9.599 10.5713 9.803 10.254C10.007 9.92533 10.2507 9.64767 10.534 9.421C10.8287 9.183 11.1177 8.95633 11.401 8.741C11.6957 8.51433 11.945 8.25933 12.149 7.976C12.353 7.68133 12.455 7.364 12.455 7.024C12.455 6.582 12.2907 6.21367 11.962 5.919C11.6447 5.613 11.2367 5.46 10.738 5.46C10.296 5.46 9.89933 5.58467 9.548 5.834C9.19667 6.08333 8.97567 6.38933 8.885 6.752ZM9.82 15.83C9.59333 15.592 9.48 15.3087 9.48 14.98C9.48 14.6513 9.59333 14.3737 9.82 14.147C10.058 13.909 10.3413 13.79 10.67 13.79C10.9987 13.79 11.2763 13.909 11.503 14.147C11.741 14.3737 11.86 14.6513 11.86 14.98C11.86 15.3087 11.741 15.592 11.503 15.83C11.2763 16.0567 10.9987 16.17 10.67 16.17C10.3413 16.17 10.058 16.0567 9.82 15.83Z"
        fill="white"
      />
    </svg>
  );
}

export function WhiteQuestion() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-cy="question-circle"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        d="M8.90066 15.762C8.61733 15.49 8.47566 15.1443 8.47566 14.725C8.47566 14.3057 8.61733 13.96 8.90066 13.688C9.18399 13.4047 9.53533 13.263 9.95466 13.263C10.374 13.263 10.7253 13.4047 11.0087 13.688C11.3033 13.96 11.4507 14.3057 11.4507 14.725C11.4507 15.133 11.3033 15.4787 11.0087 15.762C10.7253 16.034 10.374 16.17 9.95466 16.17C9.53533 16.17 9.18399 16.034 8.90066 15.762ZM8.90066 7.007L6.75866 6.446C6.91733 5.732 7.29699 5.137 7.89766 4.661C8.49833 4.17367 9.24066 3.93 10.1247 3.93C11.088 3.93 11.8983 4.22467 12.5557 4.814C13.2243 5.392 13.5587 6.11733 13.5587 6.99C13.5587 7.45467 13.4623 7.87967 13.2697 8.265C13.0883 8.639 12.8617 8.95067 12.5897 9.2C12.329 9.438 12.0627 9.659 11.7907 9.863C11.53 10.0557 11.3033 10.2483 11.1107 10.441C10.9293 10.6337 10.8387 10.8207 10.8387 11.002C10.8387 11.2173 10.935 11.4213 11.1277 11.614L9.41066 12.804C8.88933 12.192 8.62866 11.5743 8.62866 10.951C8.62866 10.543 8.71933 10.1747 8.90066 9.846C9.09333 9.506 9.31999 9.22267 9.58066 8.996C9.85266 8.76933 10.119 8.55967 10.3797 8.367C10.6517 8.163 10.8783 7.94767 11.0597 7.721C11.2523 7.49433 11.3487 7.262 11.3487 7.024C11.3487 6.70667 11.2353 6.45167 11.0087 6.259C10.782 6.06633 10.4873 5.97 10.1247 5.97C9.77333 5.97 9.48433 6.07767 9.25766 6.293C9.04233 6.497 8.92333 6.735 8.90066 7.007Z"
        fill="#131730"
      />
    </svg>
  );
}
