import { Provider } from 'src/domain/Provider';

import { buildUrl, InsightsApi } from './Api';

export const PROVIDER = '/providers';

export function getProviderByAuth() {
  return InsightsApi.get<Provider>('/auth/provider');
}

export function getNeedsToSignDataAgreement() {
  return InsightsApi.get<{ shouldSignDataAgreement: boolean }>('/auth/data-agreement');
}

export async function getProviderByEmail(email: string, clinicId?: string) {
  // browser doesn't allow GET with body
  return InsightsApi.post<Provider>(`/clinics/${clinicId}/provider-by-email`, {
    email,
  });
}

export function fetchProviders(clinics?: string) {
  const query: { [key: string]: string } = {};

  if (clinics) {
    query.clinicID = clinics;
  }

  const url = buildUrl(PROVIDER, query);

  return InsightsApi.get<{ providers: Provider[] }>(url).then((res) => res.providers);
}
