import { Card, LineChartIcon } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { TrackedEmptyState, TrackedSortByMenu } from 'src/analytics/TrackedUI';
import { Loader } from 'src/components/ui/Loader';
import { DailyAction } from 'src/domain/patient/Patients';
import { usePatientDailyActions } from 'src/hooks/usePatientDailyActions';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import { useTableSort } from 'src/ui/useTableSort';

import { DailyActionCard } from './DailyActionCard';
import { DailyActionsError } from './DailyActionsError';
import { DailyActionTable } from './DailyActionTable';
import { OverviewLegend } from './OverviewLegend';

import styles from './DailyActions.module.scss';

type Props = {
  patientId: string;
};

export function PatientDailyActions({ patientId }: Props) {
  const intl = useIntl();

  const { isLoading, dailyActions, hasError } = usePatientDailyActions(patientId);

  const patientHasDailyActions = dailyActions?.length > 0;

  const {
    sortedData: sortedDailyActions,
    sortBy,
    setSortBy,
  } = useTableSort<DailyAction>(dailyActions, '-total_days', {
    'total_days': 'number',
  });

  const [width] = useThrottledWindowSize();
  const isMobile = width < breakpoints.mobile;

  return (
    <Card
      title={
        <div className={styles.actions}>
          <div>
            {intl.formatMessage({
              id: 'daily-actions.history',
              defaultMessage: 'Daily Actions History',
            })}
          </div>
          <div className={styles.sortByMenu}>
            {patientHasDailyActions && (
              <TrackedSortByMenu
                trackLabel="patient-daily-actions-sort-by"
                selectedOption={sortBy}
                options={[
                  {
                    label: intl.formatMessage({
                      id: 'daily-actions-table.action',
                      defaultMessage: 'Action',
                    }),
                    value: 'title',
                  },
                  {
                    label: intl.formatMessage({
                      id: 'daily-actions-table.category',
                      defaultMessage: 'Category',
                    }),
                    value: 'category',
                  },
                  {
                    label: intl.formatMessage({
                      id: 'daily-actions-table.total-days',
                      defaultMessage: 'Total Days',
                    }),
                    value: 'total_days',
                  },
                ]}
                tooltipText={intl.formatMessage({
                  id: 'daily-actions.sort-by-tooltip',
                  defaultMessage: 'Sort daily actions',
                })}
                onChange={setSortBy}
                size="xs"
                variant="secondary"
              />
            )}
          </div>
        </div>
      }
    >
      {patientHasDailyActions && <OverviewLegend />}
      {hasError ? (
        <DailyActionsError />
      ) : (
        <>
          <Loader isLoading={isLoading} />
          {patientHasDailyActions ? (
            isMobile ? (
              <>
                {sortedDailyActions.map((dailyAction, index) => (
                  <DailyActionCard key={index} dailyAction={dailyAction} />
                ))}
              </>
            ) : (
              <DailyActionTable
                dailyActions={sortedDailyActions}
                patientId={patientId}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />
            )
          ) : (
            <TrackedEmptyState
              title={intl.formatMessage({
                id: 'daily-actions.not-started.title',
                defaultMessage: "This patient hasn't started tracking any daily actions",
              })}
              subtitle={`${intl.formatMessage({
                id: 'daily-actions.not-started.warning.item1',
                defaultMessage:
                  'We often suggest healthy daily actions for the patients to track on the Allurion App.',
              })} ${intl.formatMessage({
                id: 'daily-actions.not-started.warning.item2',
                defaultMessage: 'The actions and their progress will appear here.',
              })}`}
              icon={<LineChartIcon />}
              trackedLabel="no-patient-daily-actions-found"
            />
          )}
        </>
      )}
    </Card>
  );
}
