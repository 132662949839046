import useSWRInfinite from 'swr/infinite';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientSleep } from 'src/domain/patient/Patients';

const PATIENT_SLEEP = (patientId: string) => `/patients/${patientId}/sleep`;

export function usePatientSleep(patientId: string) {
  const {
    data = [],
    error,
    isValidating,
    size,
    setSize,
  } = useSWRInfinite(
    getKey,
    (_url) =>
      InsightsApi.get<{ sleepData: PatientSleep[] }>(_url).then(({ sleepData }) => sleepData),
    { revalidateOnFocus: false }
  );

  return {
    isLoading: isValidating,
    sleepData: data.flat(),
    error,
    fetchMore() {
      setSize(size + 1);
    },
  };

  function getKey(page: number, previousPageData: PatientSleep[]) {
    if (previousPageData && !previousPageData.length) return null; // reached the end

    return (
      patientId &&
      buildUrl(PATIENT_SLEEP(patientId), {
        page: page + 1,
        pageSize: 7,
      })
    );
  }
}
