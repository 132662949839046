import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { ClinicStats } from 'src/domain/Clinic';

export const CLINIC_STATS = (clinicId: string) => `/clinics/${clinicId}/stats`;

export function useClinicStats(clinicId?: string) {
  const url = clinicId && buildUrl(CLINIC_STATS(clinicId.toString()));
  const { data, error, isValidating } = useSWR(
    url,
    (_url: string) =>
      InsightsApi.get<{ stats: ClinicStats[]; providerStats?: ClinicStats[] }>(_url),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return {
    isLoading: isValidating,
    clinicStats: data?.stats ?? [],
    providerStats: data?.providerStats ?? [],
    error,
  };
}
