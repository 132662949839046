import styled from 'styled-components';

import { SearchIcon } from 'src/icons/SearchIcon';
import theme from 'src/styles/theme';

type Props = {
  placeholder?: string;
  value?: string;
  onChange: (evt: any) => void;
  variantType?: 'primary' | 'secondary';
  className?: string;
};

export function AllurionSearchbar({
  placeholder,
  value,
  onChange,
  variantType = 'primary',
  className,
  ...props
}: Props) {
  return (
    <Container as={containerStyles[variantType]} className={className} {...props}>
      <StyledInput
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        data-cy="searchbar"
      />
      <SearchIcon fill={theme.colors.Primary} size="26px" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 5px 20px;
`;

const StyledInput = styled.input`
  color: ${({ theme }) => theme.colors.Primary};
  background-color: inherit;
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-family: 'GT-America-Standard-Medium', sans-serif;
  border: none;
  outline: none;
  margin-right: 5px;

  &::placeholder {
    opacity: 0.7;
  }
`;

const Primary = styled(Container)`
  background-color: ${({ theme }) => theme.colors.White};
`;

const Secondary = styled(Container)`
  background-color: ${({ theme }) => theme.colors.Silver};
`;

const containerStyles = {
  primary: Primary,
  secondary: Secondary,
};
