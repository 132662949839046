import { isError } from '@allurion/utils';
import { Auth } from 'aws-amplify';

import { Logger } from 'src/services/Logger';

export const getUserSession = async () => {
  try {
    return await Auth.currentSession();
  } catch (ex: any) {
    const message = isError(ex) ? ex.message : ex;

    const shouldLogException =
      message !== 'No current user' && message !== 'Refresh token has expired';

    if (shouldLogException) {
      Logger.captureException(ex);
    } else if (isError(ex)) {
      // eslint-disable-next-line no-console
      console.log('getUserSession', ex.message);
    }

    return null;
  }
};

export const getUserSessionToken = async () => {
  const userSession = await getUserSession();

  return userSession?.getIdToken().getJwtToken();
};
