export function CalendarIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 1V4" stroke="currentColor" strokeWidth="1.1" strokeLinecap="square" />
      <path d="M5 1V4" stroke="currentColor" strokeWidth="1.1" strokeLinecap="square" />
      <path d="M2 7H14" stroke="currentColor" strokeWidth="1.1" strokeLinecap="square" />
      <path d="M1.55 2.55H14.45V14.45H1.55V2.55Z" stroke="currentColor" strokeWidth="1.1" />
    </svg>
  );
}
