import { AudioTrack } from 'twilio-video';

import { rootMeanSquare } from 'src/helpers/math';

// https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#testing-the-microphone-and-camera
const AudioContext =
  typeof window !== 'undefined' && (window.AudioContext || window.webkitAudioContext);
const audioContext = AudioContext ? new AudioContext() : null;

const pollAudioLevel = async (track: AudioTrack | null, onLevelChanged: Function = () => {}) => {
  if (!track || !audioContext) {
    await audioContext?.suspend();

    return;
  }
  await audioContext.resume();
  const analyser = audioContext.createAnalyser();
  const stream = new MediaStream([track?.mediaStreamTrack]);
  const source = audioContext.createMediaStreamSource(stream);

  analyser.fftSize = 1024;
  analyser.smoothingTimeConstant = 0.5;
  source.connect(analyser);

  const samples = new Uint8Array(analyser.frequencyBinCount);
  let level: number | null = null;

  requestAnimationFrame(function checkLevel() {
    analyser.getByteFrequencyData(samples);
    const rms = rootMeanSquare(samples);
    const log2Rms = rms && Math.log2(rms);

    // Audio level ranges from 0 (silence) to 10 (loudest).
    const newLevel = Math.ceil(12 * (log2Rms / 8));

    if (level !== newLevel) {
      level = newLevel;
      onLevelChanged(level);
    }

    // Continue calculating the level only if the audio track is live.
    const frame =
      track?.mediaStreamTrack?.readyState === 'live' ? checkLevel : () => onLevelChanged(0);

    requestAnimationFrame(frame);
  });
};

export default pollAudioLevel;
