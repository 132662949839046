import { isString } from '@allurion/utils';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import styled from 'styled-components';

import colors from 'src/styles/theme/colors';

const definedStyles = {
  option: (styles: any, { isFocused, isSelected }: any) => {
    const allStyles = {
      ...styles,
    };

    if (isSelected) {
      return {
        ...allStyles,
        backgroundColor: colors.GreyBorder,
        color: '#000',
      };
    }
    if (isFocused) {
      return {
        ...allStyles,
        backgroundColor: 'rgba(0,0,0,0.1)',
      };
    }

    return {
      ...allStyles,
      backgroundColor: 'transparent',
    };
  },
};

type Props = {
  [key: string]: any;
  options: any[];
  defaultValue?: any;
  selectStyles?: any;
  variantType?: 'primary' | 'secondary';
  className?: string;
  haserror?: any;
  isDisabled?: boolean;
};

export function AllurionSelect({
  options,
  defaultValue,
  selectStyles = {},
  isDisabled,
  variantType = 'primary',
  className,
  ...props
}: Props) {
  const intl = useIntl();

  return (
    <StyledReactSelect
      defaultValue={defaultValue}
      options={options}
      isDisabled={isDisabled}
      classNamePrefix="react-select"
      styles={{ ...definedStyles, ...selectStyles }}
      as={controlStyles[variantType]}
      className={className}
      noOptionsMessage={() =>
        intl.formatMessage({ id: 'allurion-select.no-options', defaultMessage: 'No options' })
      }
      placeholder={intl.formatMessage({
        id: 'allurion-select.placeholder',
        defaultMessage: 'Select...',
      })}
      {...props}
    />
  );
}

const StyledReactSelect = styled(ReactSelect)<Props>`
  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.DarkSilver};
    font-family: 'GT-America-Standard', sans-serif;
  }
  .react-select__input {
    outline: none;
    font-family: 'GT-America-Standard', sans-serif;
  }
  .react-select__control {
    padding: 7px;
    border: none;
    box-shadow: none;
    outline: none;
    min-width: 300px;
    max-width: 100%;
    min-height: 44px;
  }
  .react-select__single-value {
    font-family: 'GT-America-Standard-Light', sans-serif;
    padding-top: 2px;
    min-height: 26px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.Primary};
    height: 100%;
    font-size: 1.3rem;
    font-weight: 100;
  }
  .react-select__dropdown-indicator {
    color: ${({ theme }) => theme.colors.Primary};
  }
  .react-select__indicator-separator {
    display: none;
  }
  ${({ theme, haserror }) =>
    haserror && isString(haserror) && `border: 1px solid ${theme.colors.Alert}`};
`;

const Primary = styled(StyledReactSelect)`
  .react-select__control {
    background-color: ${({ theme }) => theme.colors.White};
  }
`;

const Secondary = styled(StyledReactSelect)`
  .react-select__control {
    background-color: ${({ theme }) => theme.colors.Silver};
  }
`;

const controlStyles = {
  primary: Primary,
  secondary: Secondary,
};
