export function ClinicsIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3848_33288" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.625 1L5.375 1L5.375 5.375L1 5.375L1 10.625H5.375L5.375 15L10.625 15V10.625H15L15 5.375L10.625 5.375V1Z"
        />
      </mask>
      <path
        d="M5.375 1V-0.1H4.275V1L5.375 1ZM10.625 1L11.725 1V-0.1L10.625 -0.1V1ZM5.375 5.375V6.475H6.475V5.375H5.375ZM1 5.375V4.275H-0.1V5.375H1ZM1 10.625H-0.1L-0.1 11.725H1L1 10.625ZM5.375 10.625H6.475V9.525H5.375V10.625ZM5.375 15H4.275V16.1H5.375V15ZM10.625 15V16.1H11.725V15H10.625ZM10.625 10.625V9.525H9.525V10.625H10.625ZM15 10.625V11.725H16.1V10.625H15ZM15 5.375H16.1V4.275H15V5.375ZM10.625 5.375H9.525V6.475H10.625V5.375ZM5.375 2.1L10.625 2.1V-0.1L5.375 -0.1V2.1ZM6.475 5.375L6.475 1L4.275 1V5.375L6.475 5.375ZM1 6.475L5.375 6.475L5.375 4.275H1L1 6.475ZM2.1 10.625L2.1 5.375H-0.1L-0.1 10.625H2.1ZM5.375 9.525H1L1 11.725H5.375V9.525ZM6.475 15L6.475 10.625H4.275V15H6.475ZM10.625 13.9L5.375 13.9V16.1H10.625V13.9ZM9.525 10.625V15H11.725V10.625H9.525ZM15 9.525H10.625V11.725H15V9.525ZM13.9 5.375L13.9 10.625H16.1V5.375H13.9ZM10.625 6.475L15 6.475V4.275H10.625V6.475ZM9.525 1V5.375H11.725V1L9.525 1Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_3848_33288)"
      />
    </svg>
  );
}
