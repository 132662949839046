import { VideoIcon } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { useVideoCallSessions } from 'src/hooks/useVideoCallSessions';

import messages from './video-call.messages';

import styles from './PatientVideoCall.module.scss';

type Props = {
  patientId: string;
};

export function VideoCallHistory({ patientId }: Props) {
  const intl = useIntl();

  const { videoCallSessions, isLoading } = useVideoCallSessions(patientId);

  const title = intl.formatMessage(messages.tableTitle);

  if (isLoading) {
    return <InlineLoader />;
  }

  if (videoCallSessions.length === 0) {
    return null;
  }

  return (
    <div className={styles.videoCall__history}>
      <h3>{title}</h3>
      {videoCallSessions.map((session) => (
        <div key={session.id} className={styles.videoCall__history__card}>
          <VideoIcon />
          <div className={styles.videoCall__history__card__content}>
            <h4>{session.date}</h4>
            <div className={styles.videoCall__history__card__content__data}>
              <span>{`${intl.formatMessage(messages.tableProvider)}: ${session.provider}`}</span>
              <span>{`${intl.formatMessage(messages.tableFrom)} ${
                session.startTime
              } ${intl.formatMessage(messages.tableTo)} ${session.endTime}`}</span>
              <span>{`${intl.formatMessage(messages.tableDuration)}: ${session.duration}`}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
