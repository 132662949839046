import { InputSelector } from '@allurion/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { updatePatientSubscriptions } from 'src/hooks/useContentSubscription';

import styles from './ContentSubscription.module.scss';

type Props = {
  patientId: string;
  collectionId: string;
  isSubscribed: boolean;
  reload: () => void;
  isLoading: boolean;
};

export function ContentSubscription({
  patientId,
  collectionId,
  isSubscribed,
  reload,
  isLoading,
}: Props) {
  const intl = useIntl();

  const [isUpdating, setIsUpdating] = useState(false);

  async function toggleSubscription(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      setIsUpdating(true);
      await updatePatientSubscriptions(patientId, [
        {
          'collection_id': collectionId,
          'is_active': e.target.checked,
        },
      ]);
      const message = intl.formatMessage({
        id: 'patient-profile.subscription-updated',
        defaultMessage: 'The subscription preferences have been updated.',
      });

      await reload();
      toastSuccess(message);
    } catch (error) {
      const message = intl.formatMessage({
        id: 'patient-profile.subscription-error',
        defaultMessage: 'Something went wrong, please try again.',
      });

      toastError(message);
    } finally {
      setIsUpdating(false);
    }
  }

  return (
    <div className={styles.subscribe}>
      <div className={styles.action}>
        {isUpdating || isLoading ? (
          <InlineLoader size="30px" />
        ) : (
          <InputSelector type="switch" checked={isSubscribed} onChange={toggleSubscription} />
        )}
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          {intl.formatMessage({
            id: 'patient-content-library.subscribe-title',
            defaultMessage: 'Subscribe patient',
          })}
        </div>
        <div className={styles.subtitle}>
          {intl.formatMessage({
            id: 'patient-content-library.subscribe-subtitle',
            defaultMessage:
              'Deliver these articles automatically through the Allurion App on relevant stages of the treatment.',
          })}
        </div>
      </div>
    </div>
  );
}
