import { isAwsError, isError } from '@allurion/utils';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Footer } from 'src/components/Footer/Footer';
import { Seo } from 'src/components/Seo';
import { Loader } from 'src/components/ui/Loader';
import { toastError } from 'src/components/ui/toasts';
import { LoginForm } from 'src/forms/Login/LoginForm';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import LogoImgSrc from 'src/images/wordmark-white.svg';
import messages from 'src/messages/auth.messages';
import globalMessages from 'src/messages/global.messages';
import { Logger } from 'src/services/Logger';

import { FullscreenContainer } from './shared-page-elements';

import styles from './LoginPage.module.scss';

export default function LoginPage() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const { toResetPasswordPage, toHomePage } = useAppNavigate();
  const { login, logout, user } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const shouldLogout = searchParams.get('sessionTimeout') === 'true';

  useEffect(() => {
    if (shouldLogout) {
      logout({ replace: true });

      const newSearchParams = new URLSearchParams(searchParams);

      newSearchParams.delete('sessionTimeout');

      setSearchParams(newSearchParams, { replace: true });
    }
  }, [logout, searchParams, setSearchParams, shouldLogout]);

  useEffect(() => {
    if (user.sessionToken && !shouldLogout && !isLoading) {
      toHomePage();
    }
  }, [shouldLogout, toHomePage, user, isLoading]);

  async function signIn(email: string, password: string) {
    setIsLoading(true);
    try {
      if (location.search.includes('password=')) {
        setIsLoading(false);
        toResetPasswordPage();

        return;
      }

      await login({
        email,
        password,
        redirectTo,
      });
    } catch (error) {
      if (isError(error) && error.message.startsWith('UserRoleLoginNotAllowed')) {
        const [, role, providerId] = error.message.split(':');

        toastError(`${role} access is not allowed anymore. Please contact support.`);
        Logger.captureMessage(`A user attempted to login as ${role}: ${providerId}`);
      } else {
        toastError(intl.formatMessage(messages.loginFailure));
        const shouldIgnoreError = isAwsError(error) && error.code === 'NotAuthorizedException';

        if (!shouldIgnoreError) {
          Logger.captureException(error);
        }
      }
    }
    setIsLoading(false);
  }

  return (
    <FullscreenContainer>
      <Seo title="Login" />
      <div className={styles.content}>
        <div className={styles.logoTitle}>
          <div className={styles.newText}>NEW</div>
          <img className={styles.logo} src={LogoImgSrc} alt="Allurion" />
        </div>
        <p className={styles.text}>{intl.formatMessage(globalMessages.login)}</p>
        <LoginForm
          initialValues={{
            password: '',
            email: '',
          }}
          onSubmit={(values: any) => {
            const { email, password } = values;

            signIn(email, password);
          }}
        />

        <Footer dark />
      </div>
      <Loader isLoading={isLoading} />
    </FullscreenContainer>
  );
}
