import { Popover } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { WhiteQuestion } from 'src/icons/QuestionIcon';

import messages from './password-popup.messages';

import styles from './PasswordPopup.module.scss';

export function PasswordPopup() {
  const intl = useIntl();

  return (
    <Popover
      closeOnOutsideClick
      placement="bottom"
      content={
        <div className={styles.content}>
          <p>{intl.formatMessage(messages.passwordRules)}</p>
          <ul>
            <li>{intl.formatMessage(messages.length)}</li>
            <li>{intl.formatMessage(messages.lower)}</li>
            <li>{intl.formatMessage(messages.capital)}</li>
            <li>{intl.formatMessage(messages.number)}</li>
            <li>{intl.formatMessage(messages.specialChar)}</li>
          </ul>
          <span>~!@#$%^&*()_+</span>
        </div>
      }
    >
      <WhiteQuestion />
    </Popover>
  );
}
