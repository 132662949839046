export function MailIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.2165 2.55H14.7832V13.45H1.2165V2.55Z"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
      />
      <path
        d="M1 4.66675L8 8.00008L15 4.66675"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
      />
    </svg>
  );
}
