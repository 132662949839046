/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  submit: {
    id: 'create-password.submit',
    defaultMessage: 'Create Password',
  },
  text: {
    id: 'create-password.text',
    defaultMessage:
      'Welcome to Allurion Insights. Please set up a password to complete your account.',
  },
  createPassword: {
    id: 'create-password.createPassword',
    defaultMessage: 'Create a Password',
  },
  confirmPassword: {
    id: 'confirm-password.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  passwordRules: {
    id: 'confirm-password.passwordRules',
    defaultMessage: 'Passwords should match the following rules:',
  },
  length: {
    id: 'confirm-password.length',
    defaultMessage: 'At least eight characters',
  },
  lower: {
    id: 'confirm-password.lower',
    defaultMessage: 'At least one lowercase letter',
  },
  capital: {
    id: 'confirm-password.capital',
    defaultMessage: 'At least one uppercase letter',
  },
  number: {
    id: 'confirm-password.number',
    defaultMessage: 'At least one number',
  },
  specialChar: {
    id: 'confirm-password.specialChar',
    defaultMessage: 'At least one special character',
  },
});
