import { enhanceError } from '@allurion/utils';
import { Message } from '@twilio/conversations/lib/message';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEvent } from 'src/analytics/analytics';
import { toastError } from 'src/components/ui/toasts';
import { ParsedMessageNotification } from 'src/domain/Conversations';
import { Logger } from 'src/services/Logger';

import TwilioConversationsService from './TwilioConversationsService';

export function useTwilioIncommingMessages(identity?: string) {
  const intl = useIntl();

  const [incomingMessage, setIncomingMessage] = useState<ParsedMessageNotification | undefined>(
    undefined
  );
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const { trackTwilioChatFailure } = useTrackEvent();

  useEffect(() => {
    if (!identity) {
      return;
    }

    Logger.debug(`[twilio] Subscribing to incoming messages ${identity}`);

    TwilioConversationsService.subscribe(identity, (message: Message) => {
      const { body, conversation, author, attributes } = message || {};

      const { name: title } = attributes as any;
      const { patientId } = conversation?.attributes as any;
      // This will work as long as the created by of the conversations is the patient id?
      const isPatientMessage = author === conversation.createdBy;

      const parsedMessage: ParsedMessageNotification = {
        title,
        body,
        author,
        patientId,
        isPatientMessage,
      };

      setIncomingMessage(parsedMessage);
    }).catch((error) => {
      Logger.captureException(enhanceError(error, { prefix: 'useTwilioIncommingMessages' }));
      setHasConnectionError(true);
      trackTwilioChatFailure({ error });
      toastError(
        intl.formatMessage({
          defaultMessage:
            'Cannot receive incoming messages. If you want to be notified of new messages, please reload the page.',
          id: 'twilio.failed-to-subscribe-to-incoming-messages',
        })
      );
    });

    return () => {
      TwilioConversationsService.unsubscribe(identity);
    };
  }, [identity, intl, trackTwilioChatFailure]);

  const clearIncomingMessage = () => {
    setIncomingMessage(undefined);
  };

  return {
    clearIncomingMessage,
    incomingMessage,
    hasConnectionError,
  };
}
