import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientOverview } from 'src/domain/patient/Patients';

export const PATIENT_OVERVIEW = (patientId: string) => `/patients/${patientId}/overview`;

export function usePatientOverview(patientId?: string) {
  const url = patientId && buildUrl(PATIENT_OVERVIEW(patientId));
  const { data, error, isValidating, mutate } = useSWR(
    url,
    (_url) => InsightsApi.get<PatientOverview>(_url),
    { revalidateOnFocus: false }
  );

  return {
    isLoading: isValidating,
    error,
    overviewData: data,
    reload: mutate,
  };
}
