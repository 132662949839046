import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { Clinic } from 'src/domain/Clinic';

import { useCurrentUser } from './useCurrentUser';

export const CLINICS = '/clinics';

export function useUserClinics({ suspense = true } = {}) {
  const { isAuthenticated } = useCurrentUser();

  const url = buildUrl('/auth/clinics');
  const { data, error, isValidating } = useSWR(
    isAuthenticated && url,
    (_url: string) => InsightsApi.get<{ clinics: Clinic[] }>(_url).then((res) => res.clinics),
    { revalidateOnFocus: false, suspense, dedupingInterval: ONE_MINUTE_IN_MS }
  );

  // Filter out clinics that don't have an ID
  const validClinics = data?.filter((clinic) => clinic.ID);

  return { clinics: validClinics ?? [], isLoading: isValidating, error };
}
