import { isPatientGroupingEnabled } from '@allurion/domain';
import { PageHeader } from '@allurion/ui';
import { isError, toLocalizedLongDate } from '@allurion/utils';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedButton } from 'src/analytics/TrackedUI';
import { invitePatient } from 'src/api/PatientApi';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { getUserLanguage } from 'src/helpers/locale';
import { useClinic } from 'src/hooks/useClinic';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useLocale } from 'src/hooks/useLocale';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';
import { EditIcon } from 'src/icons/EditIcon';
import { AddPatientData } from 'src/pages/InvitePatientPage/AddPatient.types';
import { Logger } from 'src/services/Logger';

import { Container, InnerContainer } from '../shared-page-elements';

import translations from './AddPatientPage.translations';

type Props = {
  data: AddPatientData;
  next: Function;
  prev: Function;
  clinicId: string;
};

export function InvitePatientDataReview({ data, clinicId, next, prev }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const { clinic } = useClinic(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const { locale } = useLocale();
  const lang = getUserLanguage(locale);
  const { getTreatmentTypeLabel } = useTreatmentLabels(clinicId);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  useEffect(() => {
    sessionStorage.setItem('add-patient-form', JSON.stringify(data));
  }, [data]);

  const handleNext = async () => {
    setIsLoading(true);

    try {
      const { providers, collections, ...patientData } = data;
      const payload = {
        ...patientData,
        lang,
        clinic: clinicId,
        providers: providers?.map((provider) => ({
          providerID: JSON.parse(provider.value).providerID,
        })),
        collections: collections?.map((collection) => ({
          collection_id: collection,
          is_active: true,
        })),
      };

      await invitePatient(payload);
      // NOTE: Re-use of translation label, email === name
      const email = `${data.firstName} ${data.lastName}`;

      toastSuccess(intl.formatMessage(translations.invitation, { email }));
      trackFormSuccess('invite-patient');
      next();
      sessionStorage.removeItem('add-patient-form');
    } catch (error: any) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      toastError(errorMessage);
      trackFormError('invite-patient', { error: errorMessage });
    }
    setIsLoading(false);
  };

  const handlePrev = () => {
    prev();
  };

  const patientDetails = [
    { label: intl.formatMessage(translations.name), value: `${data.firstName} ${data.lastName}` },
    { label: intl.formatMessage(translations.email), value: data.email },
    {
      label: intl.formatMessage(translations.treatment),
      value: getTreatmentTypeLabel(data.treatmentType) || 'N/A',
    },
    {
      label: intl.formatMessage(translations.startDate),
      value: toLocalizedLongDate(data.startDate, locale),
    },
    { label: intl.formatMessage(translations.clinic), value: clinic?.post_title },
  ];

  return (
    <>
      <PageHeader
        title={intl.formatMessage(translations.reviewDetails)}
        onNavButtonClick={handlePrev}
      />

      <Container>
        <InnerContainer>
          <DataReviewContainer>
            {patientDetails?.map(({ label, value }) => (
              <div className="content" key={`patient-detail_${label}`} data-cy="patientData">
                <p className="label">{label}</p>
                <p className="value">{value}</p>
              </div>
            ))}

            {isPatientGroupingEnabled(settings) && (
              <div className="content">
                <p className="label">{intl.formatMessage(translations.providerAccess)}</p>
                <p className="content-description">
                  ({intl.formatMessage(translations.providerAccessDescription)})
                </p>
                <ul className="provider-list">
                  {data?.providers?.map(({ label, value }) => (
                    <li className="provider-name" key={JSON.parse(value).providerID}>
                      {label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="button-wrapper">
              <TrackedButton
                label={intl.formatMessage(translations.confirm)}
                onClick={handleNext}
                disabled={isLoading}
                size="sm"
                trackLabel="confirm-patient-invitation"
              />
              <TrackedButton
                label={intl.formatMessage(translations.makeChanges)}
                onClick={handlePrev}
                leftIcon={<EditIcon />}
                variant="outlined"
                size="sm"
                disabled={isLoading}
                trackLabel="make-changes-patient-invitation"
              />
            </div>
            <Loader isLoading={isLoading} />
          </DataReviewContainer>
        </InnerContainer>
      </Container>
    </>
  );
}

const DataReviewContainer = styled.div`
  .title {
    font-weight: bolder;
    font-size: 28px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.Primary};
    margin-bottom: 35px;
    margin-top: 25px;
  }
  .content {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .label {
    color: ${({ theme }) => theme.colors.Primary};
    padding-bottom: 15px;
    font-size: 20px;
    font-family: 'GT-America-Standard-Medium', sans-serif;
  }
  .content-description {
    margin: 5px 0;
  }
  .provider-list {
    margin: 10px 0 0 0;
  }
  .provider-name {
    margin: 10px 0;
  }

  .value {
    font-family: 'GT-America-Standard-Light', sans-serif;
  }

  .button-wrapper {
    display: flex;
    item-align: center;
    margin-top: 32px;
    gap: 20px;
  }
`;
