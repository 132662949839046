import { AnalyticsIcon, Card } from '@allurion/ui';
import { useIntl } from 'react-intl';

import { TrackedEmptyState } from 'src/analytics/TrackedUI';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import translations from 'src/messages/translations';

import { TotalBodyWeightLossChart } from './TotalBodyWeightLossBarChart/TotalBodyWeightLossChart';

export function PatientTbwlCard({ data, isLoading }: { data: any; isLoading: boolean }) {
  const intl = useIntl();

  const hasOverviewData =
    data?.perc_weight_loss_mo1 ||
    data?.perc_weight_loss_mo2 ||
    data?.perc_weight_loss_mo3 ||
    data?.perc_weight_loss_mo4 ||
    data?.perc_weight_loss_mo5 ||
    data?.perc_weight_loss_mo6;

  return (
    <Card title={intl.formatMessage(translations.clinicOverviewTBWL)}>
      {isLoading ? (
        <div
          style={{
            minHeight: '200px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InlineLoader />
        </div>
      ) : hasOverviewData ? (
        <TotalBodyWeightLossChart id="total-body-weight-loss" data={data} />
      ) : (
        <TrackedEmptyState
          title={intl.formatMessage({
            id: 'patient-profile.tbwl-chart.empty-state.title',
            defaultMessage: 'TBWL progress will appear here',
          })}
          subtitle={intl.formatMessage({
            id: 'patient-profile.tbwl-chart.empty-state.subtitle',
            defaultMessage:
              'Ask the patient to weigh in on a connected scale to see the TBWL chart progress here.',
          })}
          icon={<AnalyticsIcon />}
          trackedLabel="no-patient-tbwl-data-found"
        />
      )}
    </Card>
  );
}
