import { useEffect, useRef, useState } from 'react';

export function useElementOnScreen<T extends HTMLElement>(options: IntersectionObserverInit) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<T>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [containerRef, options]);

  return { containerRef, isVisible };
}
