import { useConfirmationDialog } from '@allurion/ui';
import { FIVE_MINUTES_IN_MS, ONE_MINUTE_IN_MS, THIRTY_MINUTES_IN_MS } from '@allurion/utils';
import { useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';

import { getUserSession } from 'src/helpers/userSession';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useInterval } from 'src/hooks/useInterval';
import globalMessages from 'src/messages/global.messages';
import { Logger } from 'src/services/Logger';

const refreshSessionIntervalInMs = FIVE_MINUTES_IN_MS;
const timeoutConfirmationInMs = ONE_MINUTE_IN_MS;
const inactivityTimeoutInMs = THIRTY_MINUTES_IN_MS;

export function SessionTimeout() {
  const intl = useIntl();
  const { logout } = useCurrentUser();
  const sessionTimeout = useRef<NodeJS.Timeout | null>(null);

  useInterval(
    async () => {
      try {
        // Keep alive session (refresh token if needed) every 5 minutes
        await getUserSession();
      } catch (e) {
        logout({ showSessionTimeoutWarning: true });
      }
    },
    { delay: refreshSessionIntervalInMs, triggerOnSchedule: true }
  );

  const { ConfirmationDialog, askConfirmation } = useConfirmationDialog();

  const { reset } = useIdleTimer({
    timeout: inactivityTimeoutInMs,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    startOnMount: true,
    crossTab: true,
  });

  useEffect(() => {
    return () => {
      log('umounting session timeout');
      cancelSessionTimeout();
    };
  }, []);

  return <ConfirmationDialog />;

  function handleOnIdle() {
    log('user is idle');
    log(`setup timeout to logout in ${timeoutConfirmationInMs}ms`);
    sessionTimeout.current = setTimeout(() => {
      log('logging out due to session timeout');
      logout({ showSessionTimeoutWarning: true });
    }, timeoutConfirmationInMs);

    askConfirmation({
      title: intl.formatMessage({
        id: 'session-timeout.warning-message',
        defaultMessage: `Your session is about to expire. Would you like to continue?`,
      }),
      onConfirm: handleOnConfirm,
      confirmText: intl.formatMessage(globalMessages.confirm),
      cancelText: intl.formatMessage(globalMessages.cancel),
      onCancel: handleOnCancel,
    });
  }

  function handleOnAction() {
    log('action was taken, canceling session timeout');

    cancelSessionTimeout();
    reset();
  }

  function cancelSessionTimeout() {
    if (!sessionTimeout.current) {
      return;
    }
    log('cleaning up session timeout');
    clearTimeout(sessionTimeout.current);
  }

  function handleOnConfirm() {
    log('user confirmed to continue session');

    cancelSessionTimeout();
    reset();
  }

  function handleOnCancel() {
    log('user proceeded to logout');

    cancelSessionTimeout();
    logout({ showSessionTimeoutWarning: true });
  }
}

function log(message: string) {
  Logger.debug(`[session-timeout]: ${message}`);
}
