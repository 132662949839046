/**
 *
 * User validation
 *
 * */

import { IntlShape } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

export const validate = (isEditForm: boolean, intl: IntlShape) => (values: any) => {
  const errors: any = {};

  if (isEditForm && values.role && values.role === 'delete') {
    return errors;
  }

  if (!values.clinics || values.clinics.length === 0) {
    errors.clinics = intl.formatMessage(globalMessages.selectClinic);
  }

  if (!values.firstName) {
    errors.firstName = intl.formatMessage(globalMessages.required);
  }

  if (!values.lastName) {
    errors.lastName = intl.formatMessage(globalMessages.required);
  }

  if (!values.email) {
    errors.email = intl.formatMessage(globalMessages.notValidEmail);
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = intl.formatMessage(globalMessages.notValidEmail);
  }

  if (!values.role) {
    errors.role = intl.formatMessage(globalMessages.required);
  }

  return errors;
};

export default validate;
