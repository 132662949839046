import { forwardRef } from 'react';
import styles from './TextInput.module.scss';

interface TextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  type?: 'email' | 'password' | 'text' | 'number' | 'date';
  size?: 'sm' | 'md' | 'lg';
  invalid?: boolean;
  className?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  { className, type = 'text', size = 'sm', invalid, ...props }: TextInputProps,
  ref
) {
  const classNames = [
    styles['text-input'],
    props.disabled ? styles['text-input-disabled'] : '',
    invalid ? styles['text-input-invalid'] : '',
    styles[`text-input-${size}`],
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return <input ref={ref} type={type} {...props} className={classNames} />;
});
