/**
 *
 * Scale Data Table
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  weight: {
    id: 'scale-data-table.weight',
    defaultMessage: 'Weight',
  },
  bmi: {
    id: 'scale-data-table.bmi',
    defaultMessage: 'BMI',
  },
  bodyFat: {
    id: 'scale-data-table.bodyFat',
    defaultMessage: 'Body Fat',
  },
  muslceMass: {
    id: 'scale-data-table.muslceMass',
    defaultMessage: 'Muscle Mass',
  },
  boneMass: {
    id: 'scale-data-table.boneMass',
    defaultMessage: 'Bone Mass',
  },
  bodyWater: {
    id: 'scale-data-table.bodyWater',
    defaultMessage: 'Body Water',
  },
  bmr: {
    id: 'scale-data-table.bmr',
    defaultMessage: 'BMR',
  },
  visceralFat: {
    id: 'scale-data-table.visceralFat',
    defaultMessage: 'Visceral Fat',
  },
  actionsColumn: {
    id: 'scale-data-table.actions',
    defaultMessage: 'Delete',
  },
  weightSourceTypeColumn: {
    id: 'scale-data-table.weight-source-type',
    defaultMessage: 'Source',
  },
  clinicWeightSourceType: {
    id: 'scale-data-table.clinic-weight-source-type',
    defaultMessage: 'Clinic Entry',
  },
  patientWeightSourceType: {
    id: 'scale-data-table.patient-weight-source-type',
    defaultMessage: 'Patient Entry',
  },
  scaleWeightSourceType: {
    id: 'scale-data-table.scale-weight-source-type',
    defaultMessage: 'Scale Entry',
  },
  apple_health_kitWeightSourceType: {
    id: 'scale-data-table.apple-health-kit-weight-source-type',
    defaultMessage: 'Apple HealthKit',
  },
  fitbitWeightSourceType: {
    id: 'scale-data-table.fitbit-weight-source-type',
    defaultMessage: 'Fitbit',
  },
});
