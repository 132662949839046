import { useIntl } from 'react-intl';
import styled from 'styled-components';

import currentEnv from 'src/utils/env';

import messages from './footer.messages';

const appVersion = `Build ${currentEnv.APP_BUILD_NUMBER}`;

type Props = {
  dark?: boolean;
};

export function Footer({ dark }: Props) {
  const intl = useIntl();

  const copyright = '© 2023 Allurion Technologies';
  const privacyLink = intl.formatMessage(messages.privacyPolicyLink);

  const email = 'help@allurion.com';
  const subject = 'Insights Assistance';
  const mailToLink = `mailto:${email}?subject=${subject}`;
  const darkbg: 'true' | 'false' = `${!!dark}`;

  return (
    <Container darkbg={darkbg} style={{ marginTop: 45 }}>
      <FooterText darkbg={darkbg} data-cy="footer">
        <Copyright>{copyright}</Copyright>
        <Separator darkbg={darkbg} />
        <PrivacyPolicyLink href={privacyLink} target="_blank" rel="noreferrer" darkbg={darkbg}>
          {intl.formatMessage(messages.privacyPolicy)}
        </PrivacyPolicyLink>
        <Separator darkbg={darkbg} />
        <MailLink href={mailToLink} target="_blank" rel="noreferrer" darkbg={darkbg}>
          {email}
        </MailLink>
        <AppVersionWrapper>{appVersion}</AppVersionWrapper>
      </FooterText>
    </Container>
  );
}

const Container = styled.div<any>`
  padding-top: 12px;
  text-align: center;
  background-color: ${({ theme, darkbg }) =>
    darkbg === 'true' ? theme.colors.Primary : theme.colors.Ivory};
  z-index: 999; // NOTE: Some table columns bleeds over the footer, we make sure footer is the highest
`;

const Separator = styled.span<any>`
  border-right: 2px solid;
  margin-left: 4px;
  margin-right: 4px;
  border-color: ${({ theme, darkbg }) =>
    darkbg === 'true' ? theme.colors.White : theme.colors.Primary};
  ${({ theme }) => theme.media.max.mobile`
    display: block;
    padding-bottom: 12px;
    border: 0;
  `}
`;

const AppVersionWrapper = styled.p`
  padding: 1em;
  font-size: 14px !important;
`;

const FooterText = styled.h2<any>`
  color: ${({ theme, darkbg }) => (darkbg === 'true' ? theme.colors.White : theme.colors.Primary)};
`;

const PrivacyPolicyLink = styled.a<any>`
  color: ${({ theme, darkbg }) => (darkbg === 'true' ? theme.colors.White : theme.colors.LinkBlue)};
  font-size: 14px !important;
`;

const MailLink = styled.a<any>`
  color: ${({ theme, darkbg }) => (darkbg === 'true' ? theme.colors.White : theme.colors.LinkBlue)};
  display: inline-block;
  font-size: 14px !important;
`;

const Copyright = styled.span`
  // NOTE: While Footer is being used by other components, it may overrite the fontsize of the links
  font-size: 14px !important;
`;
