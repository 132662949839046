import { InsightsApi } from 'src/api/Api';
import { Provider } from 'src/domain/Provider';

export function updatePatientProviders(providers: { providerID: string }[], patientID: string) {
  return InsightsApi.put<{ providers: Provider[] }>(`/patients/${patientID}/providers`, {
    providers,
  }).then((res) => res.providers);
}

export function fetchPatientProviders(patientId: string) {
  return InsightsApi.get<{ providers: Provider[] }>(`/patients/${patientId}/providers`);
}
