import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AlertCircleIcon } from 'src/icons/AlertCircleIcon';

export function DailyActionsError() {
  const intl = useIntl();

  return (
    <Container>
      <ContentWapper>
        <AlertCircleIcon stroke="#A66565" />
        <Title>
          {intl.formatMessage({
            id: 'daily-actions.error',
            defaultMessage: 'Something went wrong while loading this page, try refreshing',
          })}
        </Title>
      </ContentWapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  ${({ theme }) => theme.media.max.mobile`
    padding: 4px;
    margin-top: 32px;
    `}
`;

const ContentWapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;

  color: ${({ theme }) => theme.colors.Primary};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.DarkSilver};
`;
const Title = styled.h3`
  font-family: 'GT-America', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #a66565;
  margin-top: 24px;
  min-height: 48px;
  min-width: 278px;
`;
