import styled from 'styled-components';

import { SendIcon } from 'src/icons/SendIcon';
import theme from 'src/styles/theme';
import { SEND_BUTTON } from 'src/utils/constants';

const selector = {
  container: 'chat-send-button',
};

type Props = {
  inputValue: string;
  onClick: Function;
  file: File | null;
};

export function ChatSendButton({ inputValue, onClick, file }: Props) {
  const handleClick = (event: Event) => {
    event.preventDefault();
    onClick();
  };

  const hide = !file && !inputValue;

  return (
    <StyledButton
      type="button"
      data-cy={selector.container}
      id={SEND_BUTTON}
      onClick={handleClick}
      hide={hide.toString()}
    >
      <SendIcon color={theme.colors.Primary} />
    </StyledButton>
  );
}

const StyledButton = styled.button<any>`
  cursor: ${({ hide }) => (hide === 'false' ? 'pointer' : 'default')};
  opacity: ${({ hide }) => (hide === 'false' ? 1 : 0)};
  background-color: transparent;
  border: none;
`;
