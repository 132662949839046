export function MicOffIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5054_167)">
        <path
          d="M0.666748 0.666748L15.3334 15.3334"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M6 5.9999V7.9999C6.00035 8.39521 6.11783 8.78154 6.33762 9.11012C6.55741 9.43869 6.86964 9.69477 7.23487 9.846C7.60011 9.99723 8.00197 10.0368 8.3897 9.95981C8.77743 9.88278 9.13364 9.69258 9.41333 9.41323M10 6.22657V2.66657C10.0005 2.17058 9.81669 1.69211 9.48425 1.32403C9.15181 0.955952 8.69447 0.724526 8.201 0.674681C7.70753 0.624835 7.21314 0.760127 6.81381 1.05429C6.41448 1.34845 6.1387 1.7805 6.04 2.26657"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M11.3334 11.3001C10.6826 11.9644 9.84813 12.4191 8.93712 12.6058C8.0261 12.7926 7.08006 12.7029 6.22037 12.3483C5.36068 11.9936 4.62655 11.3903 4.11216 10.6155C3.59776 9.84078 3.32657 8.93002 3.33338 8.00008V6.66675M12.6667 6.66675V8.00008C12.6665 8.27504 12.6419 8.54944 12.5934 8.82008"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8 12.6667V15.3334"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M5.33325 15.3333H10.6666"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5054_167">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
