import { useEffect, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { readURL, getReadableFileSize, imageTypes } from 'src/helpers/file';
import { CancelAltIcon } from 'src/icons/CancelAltIcon';
import audioIconSrc from 'src/images/audio-icon.png';
import pdfIconSrc from 'src/images/pdf-icon.png';
import colors from 'src/styles/theme/colors';

type Props = {
  file: File | null;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
};

export function FilePreview({ file, onDelete }: Props) {
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    const preview = async () => {
      const url: any = await readURL(file!);

      setImgUrl(url);

      return url;
    };

    if (file) {
      preview();
    }
  }, [file]);

  const { type, size, name } = file || {};
  const humanReadableSize = getReadableFileSize(size!);

  const isImage = imageTypes.includes(type!);
  const isPdf = type === 'application/pdf';
  const isAudio = type === 'audio/x-m4a' || type === 'audio/mpeg';
  const isSupportedType = isImage || isPdf || isAudio;

  if (!isSupportedType) {
    return null;
  }

  return (
    <PreviewContainer>
      <CloseBtn onClick={onDelete}>
        <CancelAltIcon color={colors.Primary} />
      </CloseBtn>

      {isImage ? (
        <Thumbnail src={imgUrl} alt="images" />
      ) : isPdf ? (
        <PDFIcon src={pdfIconSrc} alt="pdf" />
      ) : (
        <AudioIcon src={audioIconSrc} alt="audio" />
      )}
      <p>{humanReadableSize}</p>
      <p>{name}</p>
    </PreviewContainer>
  );
}

const PreviewContainer = styled.div`
  text-align: center;
  position: relative;
  display: inline-block;
  padding-left: 30px;
`;

const CloseBtn = styled.button`
  cursor: pointer;
  position: absolute;
  background: transparent;
  border: none;
  display: inline-block;
  top: -5px;
  right: -5px;
`;

const PDFIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const Thumbnail = styled.img`
  width: auto;
  height: 64px;
`;

const AudioIcon = styled.img`
  width: 64px;
  height: 64px;
`;
