import { defineMessages } from 'react-intl';

export default defineMessages({
  instructions: {
    id: 'video-call.instructions',
    defaultMessage:
      'You can start a video consult with {name} here. Once you start the consult, {name} will be notified that the consult is in progress.',
  },
  startCall: {
    id: 'video-call.startCall',
    defaultMessage: 'Start a video consult',
  },
  tableDate: {
    id: 'video-call.table-date',
    defaultMessage: 'Date',
  },
  tableEndTime: {
    id: 'video-call.table-endTime',
    defaultMessage: 'End Time',
  },
  tableDuration: {
    id: 'video-call.table-duration',
    defaultMessage: 'Duration',
  },
  tableFrom: {
    id: 'video-call.table-from',
    defaultMessage: 'From',
  },
  tableTo: {
    id: 'video-call.table-to',
    defaultMessage: 'to',
  },
  tableProvider: {
    id: 'video-call.table-provider',
    defaultMessage: 'Provider',
  },
  tableStartTime: {
    id: 'video-call.table-startTime',
    defaultMessage: 'Start Time',
  },
  tableTitle: {
    id: 'video-call.table-title',
    defaultMessage: 'Video Consult History',
  },
  waiting: {
    id: 'video-call.waiting',
    defaultMessage: 'Waiting for {name} to join',
  },
  muted: {
    id: 'video-call.muted',
    defaultMessage: 'You are muted',
  },
  cameraOff: {
    id: 'video-call.camera-off',
    defaultMessage: 'Your camera is off',
  },
  endConfirmation: {
    id: 'video-call.end-confirmation',
    defaultMessage: 'Do you want to end this call?',
  },
  endCall: {
    id: 'video-call.end-call',
    defaultMessage: 'End call',
  },
  cancelCall: {
    id: 'video-call.cancel-call',
    defaultMessage: 'Cancel',
  },
  leftCall: {
    id: 'video-call.left-call',
    defaultMessage: ' has left the call.',
  },
  lostSignal: {
    id: 'video-call.lost-signal',
    defaultMessage: ' might have lost signal or hung up.',
  },
  rejoinCall: {
    id: 'video-call.rejoin-call',
    defaultMessage: ' will be able to rejoin until you end the call',
  },
  timeout: {
    id: 'video-call.timeout',
    defaultMessage: 'Video Consult has timed out after 5 minutes!',
  },
  saveSettings: {
    id: 'video-call.save-settings',
    defaultMessage: 'Save preferences',
  },
  cancelSettings: {
    id: 'video-call.cancel-settings',
    defaultMessage: 'Cancel',
  },
  titleSettings: {
    id: 'video-call.title-settings',
    defaultMessage: 'Audio/Video Preferences',
  },
  noDevices: {
    id: 'video-call.noDevice',
    defaultMessage: 'Device not found',
  },
  microphone: {
    id: 'video-call.microphone',
    defaultMessage: 'Select your audio input device (microphone)',
  },
  speakers: {
    id: 'video-call.speakers',
    defaultMessage: 'Select your audio output device (speakers)',
  },
  camera: {
    id: 'video-call.camera',
    defaultMessage: 'Select your video input device (camera)',
  },
  audioLevel: {
    id: 'video-call.audioNote',
    defaultMessage:
      'If you hear a reverb sound your microphone is picking up the output of your speakers/headset. Lower the volume and/or move the microphone further away from your speakers/headset.',
  },
  openSettings: {
    id: 'video-call.open-settings',
    defaultMessage: 'Camera and microphone settings',
  },
  noCamera: {
    id: 'video-call.permission.noCamera',
    defaultMessage:
      'Insights does not have permission to access your camera. Please go to your browser settings and enable your camera for insights.allurion.com',
  },
  askMicrophone: {
    id: 'video-call.permission.askMicrophone',
    defaultMessage:
      'Please go to your browser settings and enable the microphone for insights.allurion.com',
  },
  askCamera: {
    id: 'video-call.permission.askCamera',
    defaultMessage:
      'Please go to your browser settings and enable your camera and microphone for insights.allurion.com',
  },
  disabledCamera: {
    id: 'video-call.permission.disabledCamera',
    defaultMessage: 'Your camera is disabled',
  },
  microphoneSelectorPlaceholder: {
    id: 'video-call.microphoneSelectorPlaceholder',
    defaultMessage: 'Select',
  },
  speakersSelectorPlaceholder: {
    id: 'video-call.speakersSelectorPlaceholder',
    defaultMessage: 'Select',
  },
  cameraSelectorPlaceholder: {
    id: 'video-call.cameraSelectorPlaceholder',
    defaultMessage: 'Select',
  },
});
