import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { PasswordPopup } from 'src/components/PasswordPopup/PasswordPopup';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';

import messages from './create-password.messages';
import validate from './create-passwrod-validate';

import styles from './CreatePasswordForm.module.scss';

type Props = {
  initialValues: any;
  onSubmit: (values: any, formikBag: any) => void;
};

export function CreatePasswordForm({ initialValues, onSubmit }: Props) {
  const intl = useIntl();

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <AllurionTextField
            darkbg
            name="password"
            type="password"
            label={
              <div className={styles.passwordLabel}>
                {intl.formatMessage(messages.createPassword)}
                <PasswordPopup />
              </div>
            }
            placeholder={intl.formatMessage(messages.createPassword)}
          />

          <AllurionTextField
            darkbg
            name="confirmPassword"
            type="password"
            label={intl.formatMessage(messages.confirmPassword)}
            placeholder={intl.formatMessage(messages.confirmPassword)}
          />

          <TrackedButton
            type="submit"
            label={intl.formatMessage(messages.submit)}
            disabled={isSubmitting}
            variant="secondary"
            trackLabel="submit-create-password"
          />
        </form>
      )}
    </Formik>
  );
}
