import { getJwtTokenExpirationDate, isJwtTokenExpired } from '@allurion/utils';
import Client from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';

import { Logger } from 'src/services/Logger';

import { clearTwilioToken, getTwilioToken } from './twilio-token';
import { fetchTwilioToken } from './TwilioApi';

class TwilioConversationsService {
  client: Client | null = null;
  identity = '';
  subscriptions: Record<string, Function> = {};
  hasConnectionError = false;
  isConnecting = false;
  private token: string | null = null;
  private tokenIsDirty = false;
  clientPromise: Promise<Client> | null = null;
  conversations: Conversation[] = [];

  private canReuseClient(identity: string) {
    return (
      this.client?.connectionState === 'connected' &&
      identity === this.identity &&
      !this.tokenIsDirty
    );
  }

  private async connect(identity: string) {
    if (this.isConnecting) {
      await this.waitForClientConnection();
    }

    this.log('connect', identity);

    //if identity is the same we can just return the client
    if (this.client && this.canReuseClient(identity) && !this.isTokenExpired()) {
      return this.client;
    }

    this.identity = identity;
    this.isConnecting = true;
    this.log('connecting', identity);
    const token = await this.getToken();

    this.log(`token valid until ${getJwtTokenExpirationDate(token)}`);

    this.client = await Client.create(token).catch((err) => {
      this.hasConnectionError = true;
      this.log('error', err);
      Logger.captureException(err);
      clearTwilioToken();
      throw err;
    });
    this.log('client created');
    this.conversations = await this.getSubscribedConversations();
    this.log('subcribed conversations', this.conversations.length.toString());
    this.isConnecting = false;
    this.tokenIsDirty = false;
    this.log('connected');

    this.client
      .removeAllListeners()
      .on('tokenAboutToExpire', this.onRenewToken)
      .on('tokenExpired', this.onRenewToken)
      .on('messageAdded', (payload: any) =>
        Object.values(this.subscriptions).forEach((fn) => fn(payload))
      )
      .on('error', (error: any) => {
        this.log('error', error);
        Logger.captureException(error);
      });

    return this.client;
  }

  private async waitForClientConnection() {
    return new Promise((resolve) => {
      let maxAttempts = 20;
      const interval = setInterval(() => {
        if (!this.isConnecting) {
          clearInterval(interval);
          resolve(true);
        }

        maxAttempts--;

        if (maxAttempts === 0) {
          clearInterval(interval);
          resolve(false);
        }
      }, 1000);
    });
  }

  private isTokenExpired() {
    if (!this.token) {
      this.log('token not found');

      return true;
    }

    const isExpired = isJwtTokenExpired(this.token);

    if (isExpired) {
      this.log('token expired');
    }

    return isExpired;
  }

  private async getToken() {
    if (this.isTokenExpired()) {
      this.token = null;
    }

    if (!this.token) {
      this.log('getting a token');
      this.token = await getTwilioToken(this.identity);
      this.tokenIsDirty = true;
    }

    if (!this.token) {
      throw new Error('Twilio token not found');
    }

    return this.token;
  }

  private async onRenewToken() {
    clearTwilioToken();
    const renewed = await getTwilioToken(this.identity);

    this.client?.updateToken(renewed);
  }

  async disconnect() {
    this.log('disconnecting');
    this.identity = '';
    this.isConnecting = false;
    this.token = null;

    const client = this.client;

    if (!client) {
      return;
    }

    this.client = null;

    client.removeAllListeners();

    await client.shutdown();
  }

  private async getSubscribedConversations() {
    const client = this.client!;

    const getNextPage = async (
      paginator: any,
      pageConversations: Conversation[]
    ): Promise<Conversation[]> => {
      if (paginator.hasNextPage) {
        const nextPageConversations = await paginator.nextPage();
        const combinedConversations = pageConversations.concat(nextPageConversations.items);

        return getNextPage(nextPageConversations, combinedConversations);
      }

      return pageConversations;
    };

    const result = await client.getSubscribedConversations();
    const pageConversations = result.items;

    return getNextPage(result, pageConversations);
  }

  async subscribe(identity: string, fn: Function) {
    await this.connect(identity);
    this.subscriptions[identity] = fn;
  }

  unsubscribe(identity: string) {
    delete this.subscriptions[identity];
  }

  private async getConversationByUniqueName(identity: string, patientIdentity: string) {
    this.log('getConversationByUniqueName', identity, patientIdentity);
    const client = await this.connect(identity);

    return client.getConversationByUniqueName(patientIdentity).catch(() => {
      return null;
    });
  }

  async waitForConversation(identity: string, patientIdentity: string): Promise<Conversation> {
    const client = await this.connect(identity);

    return new Promise((resolve, reject) => {
      let maxAttempts = 10;
      const interval = setInterval(async () => {
        const conversation = await client
          .getConversationByUniqueName(patientIdentity)
          .catch((err) => {
            Logger.captureException(err);
            reject(err);
          });

        if (conversation) {
          clearInterval(interval);
          resolve(conversation);
        }

        maxAttempts--;

        if (maxAttempts === 0) {
          clearInterval(interval);
          reject(new Error('Conversation not found'));
        }
      }, 1000);
    });
  }

  private async invitePatient({
    providerName,
    patientIdentity,
    identity,
  }: {
    identity: string;
    patientIdentity: string;
    providerName: string;
  }) {
    this.log('invitePatient', identity, patientIdentity, providerName);
    const inviteToken = await fetchTwilioToken(patientIdentity);

    const client = await Client.create(inviteToken);

    let conversation = await client.getConversationByUniqueName(patientIdentity).catch((e) => {
      Logger.captureException(e);
    });

    if (conversation) {
      this.log('Conversation found, adding participant', identity, patientIdentity, providerName);
      // In case of error lets consider that is no participants to avoid crashes
      const convParticipants = await conversation.getParticipants().catch((e) => {
        Logger.captureException(e);

        return [];
      });
      const filtered = convParticipants.filter((participant) => participant.identity === identity);

      if (!filtered?.length && identity) {
        await conversation.add(identity, { name: providerName });
      }
    } else {
      this.log('Conversation not found, creating new one', identity, patientIdentity, providerName);
      conversation = await client.createConversation({
        uniqueName: patientIdentity,
        friendlyName: patientIdentity,
      });
      await conversation.add(identity, { name: providerName });
      await conversation.add(patientIdentity);
    }

    await client.shutdown();

    return conversation;
  }

  private async updatePatientAttributes({
    patientIdentity,
    attributes,
  }: {
    patientIdentity: string;
    attributes: Record<string, string>;
  }) {
    const token = await fetchTwilioToken(patientIdentity);

    const client = await Client.create(token);

    const conv = await client.getConversationByUniqueName(patientIdentity);

    await conv.updateAttributes(attributes);

    await client.shutdown();
  }

  async setupConversation({
    identity,
    patientIdentity,
    patientId,
    providerName,
  }: {
    identity: string;
    patientIdentity: string;
    patientId: string;
    providerName: string;
  }) {
    this.log(
      '[setupConversation] Checking conversation',
      identity,
      patientIdentity,
      patientId,
      providerName
    );
    let conversation = await this.getConversationByUniqueName(identity, patientIdentity);

    if (!conversation) {
      this.log(
        '[setupConversation] Conversation not found, inviting patient',
        identity,
        patientIdentity,
        providerName
      );
      conversation = await this.invitePatient({ identity, patientIdentity, providerName });
    }
    {
      this.log('[setupConversation] Conversation found', identity, patientIdentity);
    }

    await this.updateConversationAttributes(conversation, patientIdentity, patientId);
  }

  private async updateConversationAttributes(
    conversation: Conversation,
    patientIdentity: string,
    patientId: string
  ) {
    const attributes = await conversation.getAttributes();

    if (!attributes?.patientId) {
      await this.updatePatientAttributes({
        patientIdentity,
        attributes: {
          patientId,
        },
      });
    }
  }

  log(...messages: string[]) {
    Logger.debug(`[twilio]: ${messages.join(' ')}`);
  }
}

export default new TwilioConversationsService();
