export const capitalize = (word: string) => {
  const lower = word.toLowerCase();

  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const toTitleCase = (phrase: string) =>
  phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// NOTE: Gets initials from a name
// if name +3 words, this will get the first and very last name
// will keep initials into 2 digits, and we won't need to re-adjust for +3 intials
export const getInitials = (s: string) =>
  s
    ?.match(/(\b\S)?/g)
    ?.join('')
    ?.match(/(^\S|\S$)?/g)
    ?.join('')
    .toUpperCase();

export const joinClinicNames = (arr: string[], delim = 'and') => {
  if (arr.length > 2) {
    return `${arr.slice(0, -1).join(', ')} ${delim} ${arr.slice(-1)}`;
  }
  if (arr.length === 2) {
    return arr.join(` ${delim} `);
  }

  return arr[0];
};
