import { Table } from '@allurion/ui';
import { toLocalizedDate } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Pagination } from 'src/components/Pagination';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Article } from 'src/domain/patient/Patients';
import { useLocale } from 'src/hooks/useLocale';

import { SendArticleButton } from './SendArticleButton';

type Props = {
  patientId: string;
  articles: Article[];
  isLoading: boolean;
  reload: () => Promise<any>;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  isSubscribed: boolean;
};

export function ContentArticlesTable({
  patientId,
  articles,
  isLoading,
  reload,
  sortBy,
  setSortBy,
  isSubscribed,
}: Props) {
  const intl = useIntl();
  const { locale } = useLocale();

  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const paginatedArticles = articles.slice((page - 1) * pageSize, page * pageSize);
  const lastPage = Math.ceil(articles.length / pageSize);

  function openArticlePreview(article: Article) {
    window.open(article.url, '_blank');
  }

  return (
    <>
      <Table
        columns={[
          {
            title: intl.formatMessage({
              id: 'content-library.articles-table.title',
              defaultMessage: 'Title',
            }),
            field: 'title',
            formatter: (value, article) => (
              <a
                href=""
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openArticlePreview(article);
                }}
              >
                {value}
              </a>
            ),
          },
          {
            title: intl.formatMessage({
              id: 'content-library.articles-table.scheduled',
              defaultMessage: 'Scheduled',
            }),
            field: 'notificationDate',
            formatter: (value) => toLocalizedDate(value, locale) || '-',
            align: 'center',
            hide: !isSubscribed,
          },
          {
            title: intl.formatMessage({
              id: 'content-library.articles-table.sent',
              defaultMessage: 'Sent',
            }),
            field: 'sentDate',
            formatter: (value) => toLocalizedDate(value, locale) || '-',
            align: 'center',
          },
          {
            title: intl.formatMessage({
              id: 'content-library.articles-table.viewed',
              defaultMessage: 'Viewed',
            }),
            field: 'viewDate',
            formatter: (value) => toLocalizedDate(value, locale) || '-',
            align: 'center',
          },
          {
            title: '',
            field: 'id',
            formatter: (_, article) => (
              <div style={{ display: 'inline-flex', gap: '10px' }}>
                <SendArticleButton patientID={patientId} article={article} reload={reload} />
              </div>
            ),
            align: 'right',
            sortable: false,
          },
        ]}
        data={paginatedArticles}
        sortBy={sortBy}
        onSortChange={setSortBy}
      />
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        defaultPageSize={defaultPageSize}
        setPageSize={setPageSize}
        lastPage={lastPage}
        pageSizeLabel={intl.formatMessage({
          id: 'patients.page-size-label',
          defaultMessage: 'Patients per page',
        })}
      />

      {isLoading && <InlineLoader />}
    </>
  );
}
