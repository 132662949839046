import { useField } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { compareArrays } from 'src/helpers/shared';
import { InfoOutlinedIcon } from 'src/icons/InfoOutlinedIcon';

import { AllurionErrorIndicator } from './AllurionErrorIndicator';

type Props = {
  row?: boolean;
  options: {
    label: string;
    value: string;
    uncheckMessage?: string;
    disabled?: boolean;
    hide?: boolean;
  }[];
  onChecked: Function;
  className?: string;
  preSelected?: string[];
  cyErrorLabelSelector?: string;
  name: string;
};

export function AllurionCheckboxes({
  options,
  onChecked,
  className,
  preSelected = [],
  cyErrorLabelSelector,
  ...props
}: Props) {
  const [selectedValues, setSelectedValues] = useState<string[]>(preSelected);
  const [prevSelectedValues, setPrevSelectedValues] = useState<Record<string, boolean>>(
    preSelected.reduce((acc, val) => ({ ...acc, [val]: true }), {})
  );
  const [, meta] = useField(props as any);
  const { error, touched } = meta || {};

  const hasValueBeenSelected = (v: string) => prevSelectedValues[v] && !selectedValues?.includes(v);

  const isSelected = (v: string) => {
    return Boolean(selectedValues?.includes(v));
  };

  const handleSelectedValues: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { checked, name, value },
    } = event;

    const nextSelectedValues = selectedValues?.filter((val) => val !== value);

    if (checked) {
      nextSelectedValues.push(value);
    }

    setSelectedValues(nextSelectedValues);
    onChecked(nextSelectedValues);
    setPrevSelectedValues({ ...prevSelectedValues, [name]: true });
  };

  useEffect(() => {
    if (preSelected.length > 0 && !compareArrays(selectedValues, preSelected)) {
      setSelectedValues(preSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelected]);

  const err = !!(error || touched);

  return (
    <Container className={className}>
      <div>
        {options?.map(({ label, value, uncheckMessage, hide = false }) => (
          <CheckboxContainer key={value} hide={hide} data-cy="checkbox">
            <StyledCheckbox>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name={`${props.name}-${value.toString()}`}
                  checked={isSelected(value)}
                  value={value.toString()}
                  onChange={handleSelectedValues}
                  aria-label={label}
                />
                <span />
              </div>
            </StyledCheckbox>
            <span className="input-label">{label}</span>
            {uncheckMessage && hasValueBeenSelected(value) && (
              <UncheckMessage>
                <InfoOutlinedIcon />
                <span>{uncheckMessage}</span>
              </UncheckMessage>
            )}
          </CheckboxContainer>
        ))}
      </div>
      {/* TODO: Remove condition after updating component in all forms used  */}
      {error?.length && (
        <AllurionErrorIndicator label={error} show={err} data-cy={cyErrorLabelSelector} />
      )}
    </Container>
  );
}

export const StyledCheckbox = styled.label<any>`
  z-index: 0;
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors.Primary};
  font-family: 'GT-America-Standard', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;

  .checkbox-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      z-index: -1;
      position: absolute;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: ${({ theme }) => theme.colors.Primary};
      box-shadow: none;
      outline: none;
      opacity: 0;
      transform: scale(1);
      pointer-events: none;
      transition:
        opacity 0.3s,
        transform 0.2s;
    }

    span {
      display: inline-block;
      width: 100%;
      cursor: pointer;
    }

    span::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 2px; /* Safari */
      border-color: ${({ theme }) => theme.colors.Primary};
      border-radius: 2px;
      width: 18px;
      height: 18px;
      vertical-align: top;
      transition:
        border-color 0.2s,
        background-color 0.2s;
    }

    span::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 1px;
      width: 10px;
      height: 5px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(3px, 4px) rotate(-45deg);
    }

    input:checked,
    input:indeterminate {
      background-color: ${({ theme }) => theme.colors.Primary};
    }

    input:checked + span::before,
    input:indeterminate + span::before {
      border-color: ${({ theme }) => theme.colors.Primary};
      background-color: ${({ theme }) => theme.colors.Primary};
    }

    input:checked + span::after,
    input:indeterminate + span::after {
      border-color: rgb(var(--onprimary-rgb, 255, 255, 255));
    }

    input:indeterminate + span::after {
      border-left: none;
      transform: translate(4px, 3px);
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    font-family: 'GT-America-Standard', sans-serif;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.Primary};
    input {
      margin-left: 0;
    }
  }
  .input-label {
    margin: 5px;
    position: relative;
    top: -8px;
  }
`;

const UncheckMessage = styled.p`
  margin-left: 30px;
  vertical-align: middle;
  font-family: 'GT-America-Standard-Light', sans-serif;
  margin-bottom: 24px;
  min-width: 100%;
  line-height: 130%;

  svg {
    margin-bottom: -2px;
    margin-right: 6px;
  }
`;

export const CheckboxContainer = styled.span<{ hide?: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;
