export function PatientHead() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.833 4V14.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
      <path
        d="M20.5 9.33398H31.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12.3137 30.666V27.0138H11.0624C8.98928 27.0138 7.30865 25.3787 7.30865 23.3617V19.7095H3.79259H2.96484L3.19699 18.9133C5.78836 11.1086 7.59018 2.66602 17.6316 2.66602M24.8264 30.666V24.1055C26.5569 22.8775 27.8779 21.2663 28.7273 19.459"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
    </svg>
  );
}
