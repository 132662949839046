const ONE_SECOND_IN_MS = 1000;
export const FIFTHTEEN_SECONDS_IN_MS = ONE_SECOND_IN_MS * 15;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const FIVE_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 5;
export const THIRTY_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 30;
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;

export function secondsToHHMMSS(valueInSeconds: number) {
  const hours = withLeadingZero(Math.floor(valueInSeconds / 3600));
  const minutes = withLeadingZero(Math.floor((valueInSeconds % 3600) / 60));
  const seconds = withLeadingZero(Math.round(valueInSeconds % 60));

  return `${hours}:${minutes}:${seconds}`;
}

export function minutesToHMM(valueInMinutes: number) {
  const hours = Math.floor(valueInMinutes / 60);
  const minutes = withLeadingZero(Math.round(valueInMinutes % 60));

  return `${hours}:${minutes}`;
}

function withLeadingZero(value: number) {
  return value.toString().padStart(2, '0');
}
