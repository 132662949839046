import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Legend } from 'src/components/WeightLossByPercentageChart/Legend';
import { WeightLossByPercentageChart } from 'src/components/WeightLossByPercentageChart/WeightLossByPercentageChart';

type Props = {
  data: null | any[];
};

export function WeightLossGraph({ data }: Props) {
  const formattedData = useMemo(
    () =>
      data?.reduce((acc: any, item: any) => {
        acc['5th Percentile'] = [...(acc['5th Percentile'] || []), Number(item['5th Percentile'])];
        acc['25th Percentile'] = [
          ...(acc['25th Percentile'] || []),
          Number(item['25th Percentile']),
        ];
        acc.Median = [...(acc.Median || []), Number(item.Median)];
        acc['75th Percentile'] = [
          ...(acc['75th Percentile'] || []),
          Number(item['75th Percentile']),
        ];
        acc['95th Percentile'] = [
          ...(acc['95th Percentile'] || []),
          Number(item['95th Percentile']),
        ];

        return acc;
      }, {}),
    [data]
  );

  return (
    <Container>
      <TopWrapper>
        <Title>
          <FormattedMessage
            id="weight-loss-distribution"
            defaultMessage="Weight Loss Distribution by Percentile"
          />
        </Title>
        <Legend />
      </TopWrapper>
      <div
        style={{
          width: '95%',
          height: '90%',
        }}
      >
        {formattedData && (
          <WeightLossByPercentageChart id="weight-loss-distribution-graph" data={formattedData} />
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  margin-top: 14px;
  padding-top: 15px;
  /* padding-left: 15px; */
  padding-bottom: 15px;

  ${({ theme }) => theme.media.min.tablet`
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 47px;
    padding-bottom: 40px;
  `}
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  ${({ theme }) => theme.media.min.tablet`
    padding-right: 40px;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 11px;
  line-height: 16px;
  padding-left: 22px;
  padding-right: 2px;

  ${({ theme }) => theme.media.min.tablet`
    font-size: 20px;
    line-height: 19.6px;
    padding: 0;
    margin-bottom: 40px;
    margin-left: 60px;
  `}
`;
