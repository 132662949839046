import { Popover } from '../Popover/Popover';
import styles from './Menu.module.scss';

type Props = {
  options: { value: string; label: React.ReactNode }[];
  selectedOption: string;
  onChange: (value: string) => void;
  children: JSX.Element;
};

export function Menu({ options, selectedOption, onChange, children }: Props) {
  const menuContent = (
    <ul className={styles.container}>
      {options.map(({ value, label }) => (
        <li
          key={value}
          onClick={(e) => handleOptionClick(e, value)}
          className={[styles.item, selectedOption === value && styles.active]
            .filter(Boolean)
            .join(' ')}
        >
          {label}
        </li>
      ))}
    </ul>
  );

  return (
    <Popover content={menuContent} placement="bottom" closeOnOutsideClick>
      {children}
    </Popover>
  );

  function handleOptionClick(e: React.MouseEvent<HTMLLIElement>, field: string) {
    e.preventDefault();
    e.stopPropagation();
    onChange(field);
  }
}
