import { defineMessages } from 'react-intl';

export default defineMessages({
  // Activities
  steps: {
    id: 'activity-charts.steps',
    defaultMessage: 'Steps',
  },
  numberSteps: {
    id: 'activity-charts.number-steps',
    defaultMessage: '{steps} steps',
  },
  sleep: {
    id: 'activity-charts.sleep',
    defaultMessage: 'Sleep',
  },
  amountSleep: {
    id: 'activity-charts.amountSleep',
    defaultMessage: 'Sleep {format}',
  },
  excercise: {
    id: 'activity-charts.excercise',
    defaultMessage: 'Exercise',
  },
  activityLabel: {
    id: 'activity-charts.activityLabel',
    defaultMessage: 'Activity',
  },
  time: {
    id: 'activity-charts.time',
    defaultMessage: 'Time',
  },
  noExerciseDataTitle: {
    id: 'activity-charts.noExerciseDataTitle',
    defaultMessage: 'Exercise data will appear here',
  },
  noExerciseDataSubtitle: {
    id: 'activity-charts.noExerciseDataSubtitle',
    defaultMessage: 'Ask the patient to track their exercise with a compatible app or device.',
  },
  noStepsDataTitle: {
    id: 'activity-charts.noStepsDataTitle',
    defaultMessage: 'Steps data will appear here',
  },
  noStepsDataSubtitle: {
    id: 'activity-charts.noStepsDataSubtitle',
    defaultMessage: 'Ask the patient to track their steps with a compatible app or device.',
  },
  noSleepDataTitle: {
    id: 'activity-charts.noSleepDataTitle',
    defaultMessage: 'Sleep data will appear here',
  },
  noSleepDataSubtitle: {
    id: 'activity-charts.noSleepDataSubtitle',
    defaultMessage: 'Ask the patient to track their steps with a compatible app or device.',
  },
});
