import { defineMessage } from 'react-intl';

export const dailyActionLegend = {
  completed: {
    color: '#BADE39',
    message: defineMessage({
      id: 'daily-actions.overview.done',
      defaultMessage: 'Done',
    }),
  },
  not_completed: {
    color: '#CB7E7E',
    message: defineMessage({
      id: 'daily-actions.overview.didnt-do',
      defaultMessage: "Didn't do",
    }),
  },
  no_response: {
    color: '#77989E',
    message: defineMessage({
      id: 'daily-actions.overview.no-response',
      defaultMessage: 'No Response',
    }),
  },
};
