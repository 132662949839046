import { PageHeader } from '@allurion/ui';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { getProviderByEmail } from 'src/api/ProviderApi';
import { MyLink as Link } from 'src/components/ui/Link';
import { Loader } from 'src/components/ui/Loader';
import { toastSuccess, toastError } from 'src/components/ui/toasts';
import { ADMIN_ROLE_ID, STAFF_ROLE_ID } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { ArrowBack } from 'src/icons/ArrowBackIcon';
import { CancelIcon } from 'src/icons/CancelIcon';
import { Container, InnerContainer } from 'src/pages/shared-page-elements';
import { Logger } from 'src/services/Logger';
import * as appRoutes from 'src/utils/appRoutes';

import translations from '../ManageUsers.translations';

export function ResendInvitationPage() {
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);
  const [clinicName, setClinicName] = useState('');
  const location = useLocation();
  const { toClinicProvidersPage } = useAppNavigate();
  const user = (location.state as any)?.user;

  const email = user?.EmailAddress;
  const firstName = user?.FirstName;
  const lastName = user?.LastName;
  const clinicID = user?.ClinicID;
  const locationClinicLabel = user?.ClinicLabel;

  const intl = useIntl();
  const adminLabel = intl.formatMessage(translations.manageUsersAdmin);
  const staffLabel = intl.formatMessage(translations.manageUsersStaff);

  const roleMap = {
    2: { label: adminLabel, value: ADMIN_ROLE_ID },
    3: { label: staffLabel, value: STAFF_ROLE_ID },
  };
  const roleID: keyof typeof roleMap = user?.RoleID.toString();
  const role = roleMap[roleID];

  useEffect(() => {
    (() => {
      if (locationClinicLabel) {
        const labels = locationClinicLabel.split('\n').join(', ');

        setClinicName(labels);
      }
    })();
  }, [locationClinicLabel]);

  const handleResendInvite = async () => {
    setIsLoading(true);
    try {
      const provider = await getProviderByEmail(email, clinicID[0]);
      const username = provider?.CognitoUserName;

      await Auth.resendSignUp(username);
      toastSuccess(intl.formatMessage(translations.resendInvitationResendSuccess));
      toClinicProvidersPage(clinicID);
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(translations.resendInvitationResendFailure));
    }
    setIsLoading(false);
  };

  const handleCloseBtn = () => {
    toClinicProvidersPage(clinicID);
    setHasDeleted(false);
  };

  const onClickLink = () => {
    setIsDeletingUser(false);
  };

  const listItems = [
    {
      label: intl.formatMessage(translations.resendInvitationNameAndLastName),
      value: `${firstName} ${lastName}`,
    },
    { label: intl.formatMessage(translations.resendInvitationClinic), value: clinicName },
    { label: intl.formatMessage(translations.resendInvitationEmail), value: email },
    { label: intl.formatMessage(translations.resendInvitationRole), value: role.label },
  ];

  const btnsList = [
    {
      show: hasDeleted,
      label: intl.formatMessage(translations.resendInvitationClose),
      onClick: handleCloseBtn,
    },
    {
      show: !hasDeleted && !isDeletingUser,
      label: intl.formatMessage(translations.resendInvitationResendInvite),
      onClick: handleResendInvite,
    },
  ];

  let title = translations.resendInvitationTitle;

  if (hasDeleted) {
    title = translations.resendInvitationHasBeenDeletedUserTitle;
  } else if (isDeletingUser) {
    title = translations.resendInvitationDeleteUserTitle;
  }

  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'resend-invitation.header',
          defaultMessage: 'Resend Invitation',
          description: 'Resend Invitation page header',
        })}
      />
      <Container>
        <InnerContainer>
          <Title>{intl.formatMessage(title)}</Title>
          <List>
            {listItems.map(({ label, value }) => (
              <li key={`resend-invitation_${label}`}>
                <Label>{`${label}:`}</Label>
                <Value>{value}</Value>
              </li>
            ))}
          </List>
          {isDeletingUser && (
            <WarningText>
              {intl.formatMessage(translations.resendInvitationDeleteUserWarning)}
            </WarningText>
          )}
          <Controls>
            {btnsList.map(
              ({ show, label, onClick }) =>
                show && (
                  <TrackedButton
                    key={`resend-invitation_${label}`}
                    disabled={isLoading}
                    label={label}
                    onClick={onClick}
                    trackLabel="resend-invitation"
                  />
                )
            )}
          </Controls>
          {!hasDeleted && (
            <LinksWrapper>
              {isDeletingUser && (
                <StyledSimpleLink onClick={onClickLink}>
                  <CancelIcon />
                  <span>{intl.formatMessage(translations.resendInvitationCancelNoDelete)}</span>
                </StyledSimpleLink>
              )}
              {!isDeletingUser && (
                <StyledLink to={appRoutes.manageUsers}>
                  <ArrowBack />
                  <span>{intl.formatMessage(translations.resendInvitationCancelGoBack)}</span>
                </StyledLink>
              )}
            </LinksWrapper>
          )}
          <Loader isLoading={isLoading} />
        </InnerContainer>
      </Container>
    </>
  );
}

const Title = styled.p`
  font-size: 20px;
  line-height: 32px;
  max-width: 710px;
`;

const List = styled.ul`
  list-style: none;
  margin-top: 30px;

  li {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgrey;
  }
`;

const Label = styled.label`
  display: inline-block;
  font-size: 20px;
  margin-bottom: 25px;
`;

const Value = styled.div`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 20px;
`;

const WarningText = styled.div`
  color: ${({ theme }) => theme.colors.Alert};
  font-size: 18px;
  margin-top: 30px;
`;

const Controls = styled.div`
  margin-top: 60px;
  button {
    margin-right: 2em;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #888;
  cursor: pointer;

  span {
    margin-left: 15px;
    padding-bottom: 3px;
    border-bottom: 2px solid grey;
  }
`;

const StyledSimpleLink = styled.a`
  display: flex;
  align-items: center;
  color: #888;
  cursor: pointer;

  span {
    margin-left: 15px;
    padding-bottom: 3px;
    border-bottom: 2px solid grey;
  }
`;

const LinksWrapper = styled.div`
  margin-top: 50px;
`;
