import { useUserClinics } from './useUserClinics';

export const CLINIC = (clinicId: string) => `/clinics/${clinicId}`;

export function useClinic(clinicId?: string) {
  const { clinics, isLoading, error } = useUserClinics();
  const clinic = clinicId ? clinics.find((clinic) => clinic.ID === clinicId) : null;

  return { isLoading, clinic, error };
}
