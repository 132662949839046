import { isNumber, toDateStr, getPreviousDate, strToDate } from '@allurion/utils';

import { PatientExercice, PatientSleep, PatientSteps } from 'src/domain/patient/Patients';

export interface ExerciseMapValue {
  activityDuration: number;
  activityName: string;
}

export interface ActivityItem {
  date: Date;
  activityDuration: number;
}

export const getStepDataByDay = (stepsData: PatientSteps[]) => {
  const stepsByDay = new Map<string, number>();

  if (!stepsData) return stepsByDay;

  stepsData.forEach((s) => {
    const date = s.StepsCreatedDate;

    if (!stepsByDay.has(date)) {
      const stepsOnDay = parseInt(s.Steps, 10);

      stepsByDay.set(date, stepsOnDay);
    }
  });

  return stepsByDay;
};

export const getSleepDataByDay = (sleepData: PatientSleep[]) => {
  const sleepByDay = new Map<string, number>();

  if (!sleepData) return sleepByDay;

  sleepData.forEach((s) => {
    const date = toDateStr(s.SleepsCreatedDate);
    const sleepOnDay = parseInt(s.Sleep, 10);
    const existingValue = sleepByDay.get(date);
    const value = existingValue && existingValue > sleepOnDay ? existingValue : sleepOnDay;

    sleepByDay.set(date, value);
  });

  return sleepByDay;
};

export const getExerciseDataByDay = (exerciseData: PatientExercice[]) => {
  const exerciseByDay = new Map<string, Array<ExerciseMapValue>>();
  let currentExercise: ExerciseMapValue[];

  if (!exerciseData) return exerciseByDay;

  exerciseData.forEach((e) => {
    // Ignore exercise data that doesn't have a date
    if (!e.CreatedDate && !e.ActivityCreatedDate) {
      return;
    }
    const date = toDateStr(e.ActivityCreatedDate);
    const activityName = e.ActivityName;
    const activityDuration = parseInt(e.ActivityDuration, 10);

    // is this a valid date?  i.e. does not contain 1970 as the year
    if (date.includes('1970') && e.CreatedDate) {
      const dateAlt = toDateStr(e.CreatedDate);

      currentExercise = exerciseByDay.get(dateAlt) || [];
    } else {
      currentExercise = exerciseByDay.get(date) || [];
    }

    currentExercise.push({ activityName, activityDuration });
    exerciseByDay.set(date, currentExercise);
  });

  return exerciseByDay;
};

export const convertToOrderedArrayByDateAsc = (
  activityByDay: Map<string, number> | Map<string, Array<ExerciseMapValue>>
) => {
  const result: Array<ActivityItem> = [];

  if (!activityByDay) return result;

  activityByDay.forEach((activity, stringActivityDate) => {
    const date = strToDate(stringActivityDate);
    const activityDuration = isNumber(activity)
      ? activity
      : activity.reduce((acc, exercise) => acc + exercise.activityDuration, 0);

    result.push({
      date,
      activityDuration,
    });
  });

  return result.sort((a, b) => b.date.getTime() - a.date.getTime());
};

export const getMaxDate = (allActivitiesByDay: Array<Array<ActivityItem>>) => {
  let mergedArray: Array<ActivityItem> = [];

  mergedArray = mergedArray.concat(...allActivitiesByDay);
  const maxDate = new Date(
    Math.max(...mergedArray.map((activity) => new Date(activity.date).getTime()))
  );

  return maxDate;
};

/**
 * Given an array items with dates and values and an start date
 * it creates a consecutive array of dates from the start date
 * to daysCount constant currently(7) behind
 * if any day from start date to 7 days behind has corresponding
 * data in the activityDays array it will add them to the result
 * if no corresponding day in activityDays it will fill it
 * with an item of value 0
 * any date in the activitydays that is greater than the start date
 * will be skipped
 * @param activityDays
 * @param startDate
 * @returns
 */
export const fillMissingDays = (activityDays: Array<ActivityItem>, startDate: Date) => {
  const filledDays: Array<ActivityItem> = [];

  if (activityDays.length === 0) return filledDays;
  const daysCount = 7;

  let currentDate = startDate;
  let index = 0;

  while (filledDays.length < daysCount) {
    const day = activityDays[index];
    const isDateGreaterThanStartDate = day && day.date.getTime() > startDate.getTime();

    if (isDateGreaterThanStartDate) {
      // Skip any date greater than start date
      index += 1;
    } else {
      const isCurrentDay = day && day.date.getTime() === currentDate.getTime();

      if (isCurrentDay) {
        filledDays.push(day);
        currentDate = getPreviousDate(day.date);
        index += 1;
      } else {
        filledDays.push({
          date: currentDate,
          activityDuration: 0,
        });
        currentDate = getPreviousDate(currentDate);
      }
    }
  }

  return filledDays;
};

export const calculateActivityAverage = (data: Array<ActivityItem>) => {
  if (data.length === 0) return 0;

  const dataElements = data.filter((d) => d.activityDuration > 0);

  if (dataElements.length === 0) return 0;

  const sum = dataElements.reduce((acc, element) => acc + element.activityDuration, 0);

  return sum / dataElements.length;
};
