import styles from './ClinicsNavbarMobile.module.scss';
import { TabProps } from '../../Tabs/Tabs';

type ClinicsNavbarMobileProps = {
  clinic: {
    id: string;
    name: string;
  };
  setIsCollapsed?: (isCollapsed: boolean) => void;
  clinicTabs: {
    id: string;
    label: string;
    icon: React.ReactNode;
    link?: string;
    props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement> & {
      'data-cy'?: string;
    };
  }[];
  selectedTab: string;
  onTabChange: (tab: TabProps) => void;
  tabTemplate?: (tab: TabProps, className: (isActive: boolean) => string[]) => JSX.Element;
};

export function ClinicsNavbarMobile({
  clinic,
  clinicTabs,
  selectedTab,
  onTabChange,
  tabTemplate,
}: ClinicsNavbarMobileProps) {
  const classNames = (isActive: boolean) => [
    styles['navbarTab__content']!,
    styles[`navbarTab${isActive ? '__active' : ''}`]!,
  ];

  return (
    <div className={styles.navbarWrapper}>
      <div className={styles.navbarClinicName}>{clinic.name}</div>
      <nav className={styles.navbar}>
        <ul className={styles.navbar__tabs}>
          {clinicTabs?.map((clinicTab) => (
            <li
              className={`${styles.navbarTab}`}
              key={clinicTab.id}
              onClick={() => onTabChange(clinicTab)}
              {...clinicTab.props}
            >
              {tabTemplate ? (
                tabTemplate(clinicTab, classNames)
              ) : (
                <div className={classNames(clinicTab.id === selectedTab).join(' ')}>
                  {clinicTab.icon}
                  <span className={styles.navbar__label}>{clinicTab.label}</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
