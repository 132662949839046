import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Seo } from 'src/components/Seo';
import { scrollToTop } from 'src/helpers/shared';
import { AddPatientData } from 'src/pages/InvitePatientPage/AddPatient.types';

import { InvitePatientDataReview } from './InvitePatientDataReview';
import { InvitePatientForm } from './InvitePatientForm';
import { InvitePatientSuccess } from './InvitePatientSuccess';

const initialValues: AddPatientData = {
  firstName: '',
  lastName: '',
  email: '',
  patientType: '2',
  treatmentType: 0,
  startDate: '',
  lang: '',
  providers: [],
};

export default function InvitePatientPage() {
  const { clinicId } = useParams();
  const [data, setData] = useState<AddPatientData>({ ...initialValues });
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = (newData: AddPatientData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
    scrollToTop();
  };

  const handlePrevStep = (newData: AddPatientData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
    scrollToTop();
  };

  if (!clinicId) {
    return null;
  }

  const steps = [
    <InvitePatientForm key="form-step" data={data} clinicId={clinicId} next={handleNextStep} />,
    <InvitePatientDataReview
      key="review-step"
      data={data}
      clinicId={clinicId}
      next={handleNextStep}
      prev={handlePrevStep}
    />,
    <InvitePatientSuccess key="success-step" data={data} />,
  ];

  return (
    <>
      <Seo title="Add Patient" />
      {steps[currentStep]}
    </>
  );
}
