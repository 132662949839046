import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  margin-bottom: 30px;
  padding-bottom: 30px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.media.MAX_WIDTH}px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 18px;
  line-height: 24px;
  padding: 25px;
`;

export const ScrollContent = styled.div`
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.media.MAX_WIDTH}px;
  padding-left: 25px;
`;

export const Label = styled.label`
  display: inline-block;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
`;

export const TabContentMessage = styled.p`
  color: #8e8e8e;
  font-weight: 600;
  line-height: 3;
  display: block;
  text-align: center;
  vertical-align: middle;
  background: ${({ theme }) => theme.colors.White};
  height: 655px;
  padding-top: 150px;
`;
