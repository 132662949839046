import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Footer } from 'src/components/Footer/Footer';
import { Seo } from 'src/components/Seo';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import emailMessages from 'src/forms/ValidateEmailCode/validate-email-code-messages';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import LogoImgSrc from 'src/images/wordmark-white.svg';

import messages from '../messages/auth.messages';

import { FullscreenContainer } from './shared-page-elements';

export default function ResetPasswordPage() {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { toValidateEmailPage, toLoginPage } = useAppNavigate();

  async function forgotPassword(userEmail: string) {
    setEmail(userEmail);
    setIsLoading(true);

    Auth.forgotPassword(userEmail)
      .then(() => {
        setIsLoading(false);
        toastSuccess(intl.formatMessage(messages.passwordResetRequestSuccess));
        toValidateEmailPage();
      })
      .catch((err) => {
        setIsLoading(false);
        toastError(intl.formatMessage(messages.passwordResetRequestFailure));
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  async function forgotPasswordSubmit(code: string, password: string) {
    setIsLoading(true);

    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        setIsLoading(false);
        toastSuccess(intl.formatMessage(messages.passwordResetSuccess));
        toLoginPage(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
          toastError(intl.formatMessage(emailMessages.incorrectCode));
        } else {
          toastError(intl.formatMessage(messages.passwordResetFailure));
        }
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  return (
    <FullscreenContainer>
      <Seo title="Reset Password" />
      <Content>
        <LogoTitle>
          <Logo src={LogoImgSrc} alt="Allurion" />
        </LogoTitle>

        <Outlet context={{ forgotPassword, forgotPasswordSubmit, email }} />

        <Footer dark />
      </Content>
      <Loader isLoading={isLoading} />
    </FullscreenContainer>
  );
}

const LogoTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: inherit;
`;

const Logo = styled.img`
  margin-bottom: 3em;
  height: 72px;
  width: auto;
`;

const Content = styled.div`
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  ${({ theme }) => theme.media.min.mobile`
     margin-top: 100px;
   `}
`;
