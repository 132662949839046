import {
  ChevronIcon,
  EmptyState,
  InputGroup,
  InputSelector,
  NoteIcon,
  PlusIcon,
  Popover,
  SearchIcon,
} from '@allurion/ui';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedButton, TrackedEmptyState, TrackedPageHeader } from 'src/analytics/TrackedUI';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { PatientNote } from 'src/domain/patient/Patients';
import { useNoteTopics } from 'src/hooks/useNoteTopics';

import { Note } from './Note';
import messages from './notes-messages';

import styles from './PatientNotes.module.scss';

type Props = {
  onSelectNote: (note: PatientNote) => void;
  notes: PatientNote[];
  onNewNote: () => void;
  handleCloseClick: () => void;
  isLoading: boolean;
};

export function NotesList({ notes, isLoading, onSelectNote, onNewNote, handleCloseClick }: Props) {
  const [selectedCategory, setSelectedCategory] = useState('-1');
  const [searchText, setSearchText] = useState('');

  const intl = useIntl();

  const { categories } = useNoteTopics();

  categories.unshift({
    value: '-1',
    label: intl.formatMessage(messages.categoryAll),
    id: 'All Categories',
  });

  const filteredNotes = useMemo(() => {
    let notesHolder = notes;

    if (selectedCategory) {
      if (selectedCategory !== '-1')
        notesHolder = notesHolder?.filter((note) => note.Topic.toString() === selectedCategory);
    }

    if (searchText) {
      notesHolder = notesHolder?.filter((note) =>
        note.Details.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return notesHolder;
  }, [notes, searchText, selectedCategory]);

  const onSelectCategoryChange = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    value: string
  ) => {
    e.stopPropagation();
    setSelectedCategory(value);
  };

  const hasNotes = notes.length > 0 && !isLoading;
  const selectedCategoryLabel = categories.find((c) => c.value === selectedCategory)?.label;

  return (
    <>
      <div className={styles.notesList__header}>
        <TrackedPageHeader
          title={intl.formatMessage({
            id: 'patient-notes-panel.title',
            defaultMessage: 'Notes',
          })}
          navButtonVariant="close"
          onNavButtonClick={handleCloseClick}
          iconButtons={[
            {
              variant: 'icon',
              size: 'sm',
              icon: <PlusIcon />,
              onClick: onNewNote,
              trackLabel: 'new-patient-note',
            },
          ]}
        />
        {hasNotes && (
          <div className={styles.notesList__header__notesOptions}>
            <div className={styles.notesList__header__notesOptions__search}>
              <InputGroup
                textInput={{
                  placeholder: 'Search',
                  onChange: (e) => setSearchText(e.target.value),
                  type: 'text',
                  size: 'sm',
                }}
                prefix={<SearchIcon />}
              />
            </div>

            <Popover
              placement="bottom"
              closeOnOutsideClick
              content={
                <div className={styles.notesList__header__notesOptions__popover}>
                  <ul>
                    {categories.map(({ value, label }) => (
                      <li key={value} onClick={(e) => onSelectCategoryChange(e, value)}>
                        <InputSelector
                          type="radio"
                          label={label}
                          checked={selectedCategory === value}
                          onChange={() => {}}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              }
            >
              <TrackedButton
                variant="secondary"
                size="sm"
                label={selectedCategoryLabel ?? intl.formatMessage(messages.categoryAll)}
                rightIcon={<ChevronIcon direction="down" />}
                trackLabel="change-notes-category-filter"
              />
            </Popover>
          </div>
        )}
      </div>
      <div className={styles.innerContainer}>
        {isLoading ? (
          <InlineLoader />
        ) : hasNotes ? (
          <>
            {filteredNotes.length > 0 ? (
              <div className={styles.notesList} data-cy="notes-list">
                {filteredNotes.map((note) => (
                  <Note note={note} key={note.NoteID} handleClick={() => onSelectNote(note)} />
                ))}
              </div>
            ) : (
              <EmptyState
                title={intl.formatMessage(messages.nothingFoundTitle)}
                subtitle={intl.formatMessage(messages.nothingFoundSubtitle)}
                icon={<SearchIcon />}
              />
            )}
          </>
        ) : (
          <TrackedEmptyState
            title={intl.formatMessage(messages.emptyStateTitle)}
            subtitle={intl.formatMessage(messages.emptyStateSubtitle)}
            trackedLabel="no-patient-notes-found"
            icon={<NoteIcon />}
          />
        )}
      </div>
    </>
  );
}
