import { defineMessage, MessageDescriptor } from 'react-intl';

interface IDbMessageDict {
  [key: number]: MessageDescriptor;
}
/**
 * Function to get message descriptor associated to a Database Id (DbId)
 * if no corresponding message descriptor exist for a Database Id this will generate a
 * message descriptor on the fly using the defaultLabel parameter
 * See note topics implementation on Patien notes for more
 * @param messages Dictionary containing associacion between DbId and message descriptor
 * eg: DbId: 5
 * {
 *    5: {
 *        id: 'one.locale.id',
 *        defaultMessage: 'The message that translates'
 *    }
 * }
 * @param dbId
 * @param defaultLabel
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getMessageFromDbId = (messages: IDbMessageDict, dbId: any, defaultLabel: string) => {
  const messageDescriptor = messages[dbId];

  if (messageDescriptor) {
    return messageDescriptor;
  }

  const dynamicMessageId = `dynamic.id.${defaultLabel.toLowerCase().substr(0, 2)}${dbId}`;
  const dynamicMessageDescriptor = defineMessage({
    id: dynamicMessageId,
    defaultMessage: defaultLabel,
  });

  return dynamicMessageDescriptor;
};

interface ICognitoLocale {
  [key: string]: string;
}

const CognitoLocale: ICognitoLocale = {
  ar: 'ar',
  ca: 'ca',
  da: 'da',
  de: 'de',
  en: 'en',
  en_gb: 'enGB',
  es_419: 'es',
  es_es: 'esES',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  pt_br: 'pt',
  sv: 'sv',
  tr: 'tr',
  zh_cn: 'zhCN',
  zh_tw: 'zhTW',
};

const PREFERRED_LANGUAGES_MAPPING: ICognitoLocale = {
  es: 'es',
  pt: 'pt',
  zh: 'zhCN',
};

export const getUserLanguage = (userLocale: string) => {
  const cleanedLocale = userLocale.replace('-', '_').toLowerCase();
  const correspondingLocale = CognitoLocale[cleanedLocale];

  if (correspondingLocale) {
    return correspondingLocale;
  }

  const isTwoLetterLocale = cleanedLocale.length === 2;

  if (isTwoLetterLocale) {
    // Lets try to find it on the preferred language
    const correspondingPreferredLocale = PREFERRED_LANGUAGES_MAPPING[cleanedLocale];

    if (correspondingPreferredLocale) return correspondingPreferredLocale;
  }

  if (!isTwoLetterLocale) {
    /*
     Lets see if we have a base translation for a locale that includes region eg: language code is: fr-fr but we only have fr
     we take just the first two letters and try to match that with our translation
    */
    const firstTwoLettersLocale = cleanedLocale.substr(0, 2);
    const correspondingBaseLanguages = CognitoLocale[firstTwoLettersLocale];

    if (correspondingBaseLanguages) return correspondingBaseLanguages;
  }

  // If all else fails return English
  return 'en';
};
