import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { userSlice } from 'src/state/userSlice';
import { mode } from 'src/utils/env';

const persistConfig = {
  key: 'root',
  storage,
};

const isDev = mode === 'dev';

const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function createStore(preloadedState = {}) {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    devTools: isDev,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'SET_CLINICS_ERROR'],
        },
      }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type CreateStoreReturnType = ReturnType<typeof createStore>;
export type AppDispatch = CreateStoreReturnType['store']['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
