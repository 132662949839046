import { Badge, Table } from '@allurion/ui';
import { toLocalizedDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { DailyAction } from 'src/domain/patient/Patients';
import { useLocale } from 'src/hooks/useLocale';

import { DailyActionInfoModal } from './DailyActionInfoModal/DailyActionInfoModal';
import { DailyActionsOverview } from './DailyActionsOverview';

export function DailyActionTable({
  dailyActions,
  patientId,
  sortBy,
  setSortBy,
}: {
  dailyActions: DailyAction[];
  patientId: string;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
}) {
  const intl = useIntl();
  const { locale } = useLocale();

  return (
    <Table
      data={dailyActions}
      sortBy={sortBy}
      onSortChange={setSortBy}
      columns={[
        {
          title: intl.formatMessage({
            id: 'daily-actions-table.action',
            defaultMessage: 'Action',
          }),
          field: 'title',
          formatter: (_, dailyAction: DailyAction) => (
            <DailyActionInfoModal
              title={dailyAction.title}
              patientId={patientId}
              icon={dailyAction.icon}
              description={dailyAction.description}
            />
          ),
        },
        {
          title: intl.formatMessage({
            id: 'daily-actions-table.category',
            defaultMessage: 'Category',
          }),
          field: 'category',
        },
        {
          title: intl.formatMessage({
            id: 'daily-actions-table.status',
            defaultMessage: 'Status',
          }),
          field: 'end_date',
          sortable: false,
          formatter: (_, row: DailyAction) =>
            !row.end_date ? (
              <Badge
                label={intl.formatMessage({
                  id: 'daily-actions-table.currently-tracking',
                  defaultMessage: 'Currently tracking',
                })}
                variant="success"
                size="sm"
              />
            ) : (
              <Badge
                label={intl.formatMessage({
                  id: 'daily-actions-table.stopped-period',
                  defaultMessage: 'Stopped tracking',
                })}
                variant="secondary"
                size="sm"
              />
            ),
        },
        {
          title: intl.formatMessage({
            id: 'daily-actions-table.tracking-period',
            defaultMessage: 'Tracking Period',
          }),
          field: 'start_date',
          sortable: false,
          formatter(_, row) {
            return (
              <>
                {`${toLocalizedDate(row.start_date, locale)} - `}
                {row.end_date
                  ? toLocalizedDate(row.end_date, locale)
                  : intl.formatMessage({
                      id: 'daily-actions-table.tracking-period.today',
                      defaultMessage: 'Today',
                    })}
              </>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: 'daily-actions-table.total-days',
            defaultMessage: 'Total Days',
          }),
          field: 'total_days',
          formatter(value) {
            return (
              <>
                {value}
                {` ${intl.formatMessage({
                  id: 'daily-actions-table.days',
                  defaultMessage: 'days',
                })}`}
              </>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: 'daily-actions-table.progress-overview',
            defaultMessage: 'Progress overview',
          }),
          field: 'completed',
          sortable: false,
          formatter: (_, row) => (
            <DailyActionsOverview
              id={row.daily_action_id}
              completed={row.completed}
              not_completed={row.not_completed}
              no_response={row.no_response}
            />
          ),
        },
      ]}
    />
  );
}
