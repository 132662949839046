import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { LinearProgress } from 'src/ui/LinearProgress';

import messages from './chat.messages';

const selector = {
  messageTitle: 'chat-error-title',
  messageContainer: 'chat-error-container',
};

type Props = {
  uniqueName: string;
};

export function ChatError({ uniqueName }: Props) {
  const intl = useIntl();
  const [retryLabel, setRetryLabel] = useState(true);

  useEffect(() => {
    const time = 5000;
    const timeout = setTimeout(() => {
      if (!uniqueName) {
        setRetryLabel(false);
      }
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [uniqueName, setRetryLabel]);

  const label = intl.formatMessage(retryLabel ? messages.errorRetry : messages.errorNotInstalled);
  const isVisible = !uniqueName || retryLabel;

  return (
    <ErrorMessageContainer data-cy={selector.messageContainer}>
      <MessageTitle isVisible={isVisible} data-cy={selector.messageTitle}>
        {label}
      </MessageTitle>
      {uniqueName && retryLabel && <LinearProgress />}
    </ErrorMessageContainer>
  );
}

const ErrorMessageContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  height: 150px;
`;

const MessageTitle = styled.p<{ isVisible: boolean }>`
  color: #8e8e8e;
  font-weight: 600;
  line-height: 3;
  display: ${({ isVisible }) => (!isVisible ? 'none' : 'block')};
`;
