import { isAwsError } from '@allurion/utils';
import { Component, PropsWithChildren } from 'react';

import { Logger } from 'src/services/Logger';

import { ErrorBoundaryMessage } from './ErrorBoundaryMessage';

type errorState = {
  hasError: boolean;
  kind?: 'forbidden' | 'notFound' | 'unknown' | 'unauthorized';
};

export class ErrorBoundary extends Component<PropsWithChildren<{}>, errorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError = (error: any) => ({
    hasError: true,
    kind: this.getErrorKind(error),
  });

  private static getErrorKind(error: any) {
    if (error.message === 'Forbidden') {
      return 'forbidden';
    }

    if (
      error.message === 'Unauthorized' ||
      (isAwsError(error) && error.code === 'NotAuthorizedException') ||
      error.message.includes('Refresh Token has expired')
    ) {
      return 'unauthorized';
    }

    return 'unknown';
  }

  componentDidCatch(error: any) {
    const { kind } = this.state;

    switch (kind) {
      case 'forbidden':
        //ignore
        break;

      case 'unauthorized':
        //ignore
        break;

      default:
        Logger.captureException(error);
        break;
    }
  }

  render() {
    const { children } = this.props;
    const { hasError, kind } = this.state;

    return hasError ? <ErrorBoundaryMessage kind={kind} /> : children;
  }
}
