type Props = {
  size?: string | number;
  color?: string;
  style?: any;
};

export function CancelIcon({ size = '20', color = '#111836', style = {} }: Props) {
  return (
    <svg
      width={size}
      height={size}
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke={color} />
      <line
        x1="4.08418"
        y1="4.90579"
        x2="15.1943"
        y2="14.7814"
        stroke={color}
        strokeWidth="0.641471"
      />
      <line
        x1="15.1945"
        y1="5.21921"
        x2="4.08437"
        y2="15.0949"
        stroke={color}
        strokeWidth="0.641473"
      />
    </svg>
  );
}
