export function SortIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2685_26753)">
        <path
          d="M12 10.4999L12 2.31812"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M9 4.5L12 1.5L15 4.5"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
        />
        <path
          d="M4 5.50007L4 13.6819"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M7 11.5L4 14.5L1 11.5"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2685_26753">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
