import { ArrowDownIcon, ArrowUpIcon, SortIcon } from '../../icons';
import { IconButton } from '../IconButton/IconButton';
import { Menu } from '../Menu/Menu';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './SortByMenu.module.scss';

type Props = {
  options: { value: string; label: string }[];
  selectedOption: string;
  onChange: (value: string) => void;
  variant?: 'icon' | 'secondary';
  size?: 'xs' | 'sm';
  tooltipText?: string;
};

export function SortByMenu({
  selectedOption,
  options,
  onChange,
  variant = 'icon',
  size = 'sm',
  tooltipText,
}: Props) {
  const unsignedSelectedOption =
    selectedOption.startsWith('-') || selectedOption.startsWith('+')
      ? selectedOption.slice(1)
      : selectedOption;

  const menuOptions = options.map(({ value, label }) => ({
    value,
    label: (
      <span className={styles.option}>
        <span className={styles.icon}>
          {unsignedSelectedOption === value ? (
            selectedOption.startsWith('-') ? (
              <ArrowUpIcon />
            ) : (
              <ArrowDownIcon />
            )
          ) : null}
        </span>
        <span className={styles.text}>{label}</span>
      </span>
    ),
  }));

  return (
    <Menu options={menuOptions} selectedOption={unsignedSelectedOption} onChange={onSortByUpdate}>
      {tooltipText ? (
        <Tooltip text={tooltipText} placement="bottom">
          <IconButton icon={<SortIcon />} variant={variant} size={size} />
        </Tooltip>
      ) : (
        <IconButton icon={<SortIcon />} variant={variant} size={size} />
      )}
    </Menu>
  );

  function onSortByUpdate(value: string) {
    const isSameField = selectedOption.endsWith(value);
    const isDesc = selectedOption.startsWith('-');

    onChange(isDesc || !isSameField ? `+${value}` : `-${value}`);
  }
}
