import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientNote } from 'src/domain/patient/Patients';

export const CREATE_NOTE = (patientId: string) => `/patients/${patientId}/notes`;
export const PATIENT_NOTES = (patientId: string) => `/patients/${patientId}/notes`;
export const PATIENT_NOTE = (patientId: string, noteId: string) =>
  `/patients/${patientId}/notes/${noteId}`;

export function usePatientNotes(patientId?: string) {
  const url = patientId && buildUrl(PATIENT_NOTES(patientId));
  const {
    data: notes = [],
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(
    url,
    (_url) => InsightsApi.get<{ notes: PatientNote[] }>(_url).then(({ notes }) => notes),
    { revalidateOnFocus: false }
  );

  function saveNote(
    patientId: string,
    providerId: string,
    clinicId: string,
    topicId: number,
    details: string
  ) {
    const body = {
      ProviderID: providerId,
      ClinicID: clinicId,
      Topic: topicId,
      Details: details,
      isUpdate: false,
    };

    return InsightsApi.post<PatientNote>(buildUrl(CREATE_NOTE(patientId)), body).then((res) => {
      mutate();

      return res;
    });
  }

  function deleteNote(patientId: string, noteId: string) {
    return InsightsApi.delete(buildUrl(PATIENT_NOTE(patientId, noteId))).then(() => {
      mutate();
    });
  }

  return { isLoading: isLoading || isValidating, notes, error, saveNote, deleteNote };
}
