/**
 *
 * Terms validation
 *
 * */

import { IntlShape } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

export const validate = (intl: IntlShape) => (values: any) => {
  const errors: any = {};

  switch (true) {
    case !values.terms:
      errors.terms = intl.formatMessage(globalMessages.required);
      break;

    case !values.firstName:
      errors.firstName = intl.formatMessage(globalMessages.required);
      break;

    case !values.lastName:
      errors.lastName = intl.formatMessage(globalMessages.required);
      break;

    case !values.date:
      errors.date = intl.formatMessage(globalMessages.required);
      break;

    default:
  }

  return errors;
};

export default validate;
