import { useIntl } from 'react-intl';

import { useClinicTreatements } from './useClinicCustomTreatments';

const treatmentTypesMap: Record<string, string> = {
  'Allurion Balloon Program': '1',
  'Weight Doctors BARIATRICS® Gastric Bypass': '2',
  'Weight Doctors BARIATRICS® Gastric Sleeve': '3',
  'Weight Doctors SLEEVE® OverStitch': '4',
  'Weight Doctors SLEEVE® pose2': '5',
  'Gastric Balloon for 12 months': '6',
  'Gastric Balloon for 6 months': '7',
  'Medication Therapy': '8',
  'Nutricare 3 months': '10',
  'Nutricare 6 months': '11',
  'Nutricare 12 months': '12',
  'Allurion Balloon Program 6 months': '13',
  'Allurion Balloon Program 12 months': '14',
  'Allurion Balloon Program 18 months': '15',
};

export function useTreatmentLabels(clinicID: string) {
  const intl = useIntl();
  const { treatmentList } = useClinicTreatements(clinicID);

  return {
    getTreatmentTypeLabel(treatmentTypeId: number | string, type?: string) {
      const translationKeySize = type === 'short' ? 'short' : 'long';
      const treatmentType = treatmentList.find(
        ({ custom_treatment_id }) => custom_treatment_id === `${treatmentTypeId}`
      );

      if (!treatmentType || Number(treatmentTypeId) <= 5) {
        // for backward-copatibility only
        const defaultTreatments = ['1', '2', '3', '4', '5'];

        // for backward-copatibility only until translation keys follow a standard
        if (defaultTreatments.includes(treatmentTypeId.toString())) {
          const translationKeyId = `treatment_type_${treatmentTypeId}_${translationKeySize}`;

          return intl.formatMessage({
            id: translationKeyId,
          });
        }

        return '-';
      }

      const keyName = type === 'short' ? 'custom_treatment_short_name' : 'custom_treatment_name';

      const translationKeyId = `treatment_type_custom_${
        treatmentTypesMap[treatmentType.custom_treatment_name]
      }_${translationKeySize}`;

      const label = intl.formatMessage({
        id: translationKeyId,
        defaultMessage: treatmentType?.[keyName],
      });

      return label;
    },
  };
}
