export function SleepIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.5 19.5629C28.0653 20.2456 25.4927 20.268 23.0465 19.6277C20.6002 18.9874 18.3685 17.7075 16.5805 15.9195C14.7925 14.1315 13.5126 11.8998 12.8723 9.45355C12.232 7.00731 12.2544 4.43473 12.9371 2C10.5367 2.66812 8.35321 3.9535 6.60416 5.7281C4.8551 7.5027 3.60151 9.70458 2.96826 12.1144C2.33501 14.5243 2.34419 17.058 2.99489 19.4632C3.6456 21.8684 4.91511 24.0611 6.67699 25.823C8.43886 27.5849 10.6316 28.8544 13.0368 29.5051C15.442 30.1558 17.9757 30.165 20.3856 29.5317C22.7954 28.8985 24.9973 27.6449 26.7719 25.8958C28.5465 24.1468 29.8319 21.9633 30.5 19.5629Z"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="round"
      />
    </svg>
  );
}
