import styles from './ClinicsNavbar.module.scss';

import { Tabs, TabProps } from '../../Tabs/Tabs';
import { ChevronIcon } from '../../../icons/ChevronIcon';
import { Tooltip } from '../../Tooltip/Tooltip';
import { BurgerIcon } from '../../../icons';
import { IconButton } from '../../../components/IconButton/IconButton';

type ClinicTab = {
  id: string;
  label?: string;
  icon: React.ReactNode;
  link?: string;
};
interface ClinicsNavbarDesktopProps {
  clinics: {
    id: string;
    name: string;
    tabs: ClinicTab[];
  }[];
  isCollapsed?: boolean;
  selectedClinic?: string;
  selectedTab: string;
  footerInfo?: {
    build: string;
    privacyLink: string;
    privacyText: string;
    email: string;
    subject?: string;
  };
  onClinicChange: (clinicId: string) => void;
  menu?: {
    label: string;
    onMenuClick: () => void;
    icon?: React.ReactNode;
  };
  onTabChange?: (tabId: TabProps) => void;
  tabTemplate?: (tab: TabProps, className: (isActive: boolean) => string[]) => JSX.Element;
}

export function ClinicsNavbarDesktop({
  clinics,
  isCollapsed = false,
  selectedClinic,
  selectedTab,
  onClinicChange,
  onTabChange,
  menu,
  footerInfo,
  tabTemplate,
}: ClinicsNavbarDesktopProps) {
  return (
    <div className={styles.navbarWrapper}>
      <nav className={styles.navbar ? styles.navbar : ''}>
        <div className={styles.navbar__header}>
          <IconButton
            size="sm"
            variant="iconDark"
            icon={menu?.icon || <BurgerIcon />}
            onClick={menu?.onMenuClick}
            data-cy="sidebar-toggle"
          />
          {!isCollapsed && <span>{menu?.label}</span>}
        </div>
        <div className={styles.navbar__clinicsWrapper}>
          {clinics.map((clinic) => (
            <ul
              key={clinic.id}
              className={`${styles.navbar__clinics} ${
                clinic.id === selectedClinic ? styles.navbar__clinicActive : ''
              }`}
            >
              <li className={styles.navbar__tabs} data-cy="sidebar-items">
                <Tooltip text={isCollapsed ? clinic.name : ''} placement="right">
                  <div
                    onClick={() => onClinicChange(clinic.id)}
                    className={`${styles.navbar__clinic}`}
                    data-cy={clinic.id}
                  >
                    {clinic.id === selectedClinic ? (
                      <ChevronIcon direction="up" />
                    ) : (
                      <ChevronIcon direction="down" />
                    )}
                    {!isCollapsed && (
                      <span className={styles.navbar__clinics__name}>{clinic.name}</span>
                    )}
                  </div>
                </Tooltip>
                {clinic.id === selectedClinic && (
                  <ul className={styles.navbar__clinicDropdown}>
                    <Tabs
                      direction="column"
                      tabs={
                        isCollapsed
                          ? clinic.tabs?.map(({ label, ...tab }) => {
                              return tab;
                            })
                          : clinic.tabs
                      }
                      variant="contentDark"
                      size="lg"
                      selectedTabId={selectedTab}
                      onTabChange={onTabChange}
                      tabTemplate={tabTemplate}
                      data-cy="sidebar-tabs"
                    />
                  </ul>
                )}
              </li>
            </ul>
          ))}
        </div>
      </nav>
      {!isCollapsed && (
        <footer className={styles.footer} data-cy="main-footer">
          <p>© {new Date().getFullYear()} Allurion Technologies</p>
          <p>
            <a href={footerInfo?.privacyLink}>{footerInfo?.privacyText}</a> •{' '}
            <a href={`mailto:${footerInfo?.email}?subject=${footerInfo?.subject}`}>
              {footerInfo?.email}
            </a>
          </p>
          <p>Build {footerInfo?.build}</p>
        </footer>
      )}
    </div>
  );
}
