type Props = {
  size?: string | number;
};

export function SettingsIcon({ size = '16' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.39 15L6.04 12.795C5.81833 12.7133 5.585 12.6025 5.34 12.4625C5.095 12.3225 4.87917 12.1767 4.6925 12.025L2.6275 12.97L1 10.1L2.89 8.7175C2.86667 8.6125 2.85208 8.49292 2.84625 8.35875C2.84042 8.22458 2.8375 8.105 2.8375 8C2.8375 7.895 2.84042 7.77542 2.84625 7.64125C2.85208 7.50708 2.86667 7.3875 2.89 7.2825L1 5.9L2.6275 3.03L4.6925 3.975C4.87917 3.82333 5.095 3.6775 5.34 3.5375C5.585 3.3975 5.81833 3.2925 6.04 3.2225L6.39 1H9.61L9.96 3.205C10.1817 3.28667 10.4179 3.39458 10.6687 3.52875C10.9196 3.66292 11.1325 3.81167 11.3075 3.975L13.3725 3.03L15 5.9L13.11 7.2475C13.1333 7.36417 13.1479 7.48958 13.1538 7.62375C13.1596 7.75792 13.1625 7.88333 13.1625 8C13.1625 8.11667 13.1596 8.23917 13.1538 8.3675C13.1479 8.49583 13.1333 8.61833 13.11 8.735L15 10.1L13.3725 12.97L11.3075 12.025C11.1208 12.1767 10.9079 12.3254 10.6687 12.4713C10.4296 12.6171 10.1933 12.725 9.96 12.795L9.61 15H6.39Z"
          stroke="currentColor"
          strokeOpacity="0.9"
          strokeWidth="1.2"
        />
      </g>
    </svg>
  );
}

<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;
