type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
  strokeWidth?: string | number;
};

export function VolumeIcon({
  width = '20',
  height = '20',
  stroke = '#00363F',
  strokeWidth = 1.5,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5L6 9H2V15H6L11 19V5Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.07 4.93005C20.9447 6.80533 21.9979 9.34841 21.9979 12.0001C21.9979 14.6517 20.9447 17.1948 19.07 19.0701M15.54 8.46005C16.4774 9.39769 17.004 10.6692 17.004 11.9951C17.004 13.3209 16.4774 14.5924 15.54 15.5301"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
