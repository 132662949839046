import { Badge, ChevronIcon } from '@allurion/ui';
import { toLocalizedShortDateTime } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { PatientNote } from 'src/domain/patient/Patients';
import { getMessageFromDbId } from 'src/helpers/locale';
import { useLocale } from 'src/hooks/useLocale';
import { NOTE_TOPIC_ADVERSE_EVENT_ID } from 'src/utils/constants';

import { noteTopicMessages } from './notes-messages';

import styles from './PatientNotes.module.scss';

type Props = {
  note: PatientNote;
  handleClick: any;
};

export function Note({ note, handleClick }: Props) {
  const intl = useIntl();
  const { locale } = useLocale();

  const DETAILS_MAX_LENGHT = 37;

  const { CreatedDate, TopicName, NoteID, Topic } = note;
  const [isAdverse] = useState(Topic === NOTE_TOPIC_ADVERSE_EVENT_ID);

  const providerName = `${note.ProviderFirstName ?? ''} ${note.ProviderLastName ?? ''}`;

  const onClick = () => handleClick(NoteID);
  const userTime = toLocalizedShortDateTime(CreatedDate, locale);

  return (
    <div className={styles.notesList__note} data-cy="adverse-event-note-card">
      <div className={styles.notesList__note__content}>
        <div className={styles.notesList__note__content__title}>
          <h4>
            {note.Details.length <= 37
              ? note.Details
              : `${note.Details.slice(0, DETAILS_MAX_LENGHT)}...`}
          </h4>
          <span>{`${providerName} • ${userTime}`}</span>
        </div>
        <TrackedIconButton
          size="xs"
          variant="icon"
          icon={<ChevronIcon direction="right" />}
          onClick={onClick}
          trackLabel="show-patient-note-details"
        />
      </div>
      <Badge
        variant={isAdverse ? 'danger' : 'secondary'}
        label={intl.formatMessage(getMessageFromDbId(noteTopicMessages, Topic, TopicName))}
        size="sm"
      />
    </div>
  );
}
