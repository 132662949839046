import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'invite-user.title',
    defaultMessage: 'Invite New User',
  },
  selection: {
    id: 'invite-user.table-selection',
    defaultMessage: 'Selection',
  },
  clinicName: {
    id: 'invite-user.table-clinicName',
    defaultMessage: 'Clinics',
  },
  country: {
    id: 'invite-user.table-country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'invite-user.table-city',
    defaultMessage: 'City',
  },
  addButton: {
    id: 'invite-user.add-button',
    defaultMessage: 'Invite User',
  },
  cancelButton: {
    id: 'invite-user.cancel-button',
    defaultMessage: 'Cancel and Go Back',
  },
  searchPlaceholder: {
    id: 'invite-user.search-placeholder',
    defaultMessage: 'Search',
  },
  selectClnic: {
    id: 'invite-user.select-clinic-label',
    defaultMessage: 'Clinics',
  },
});
