export function ExcerciseIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3563_5464)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3335 7C9.99035 7 11.3335 5.65685 11.3335 4C11.3335 2.34315 9.99035 1 8.3335 1C6.67664 1 5.3335 2.34315 5.3335 4C5.3335 5.65685 6.67664 7 8.3335 7Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.10439 15V11.6164C4.10574 11.2711 3.98716 10.9411 3.77774 10.7073C1.86174 8.56699 1.93915 8.49057 1.63291 8.11899C1.23349 7.634 1.23372 6.84795 1.63343 6.36331C2.03313 5.87866 2.68096 5.87893 3.08038 6.36392C3.3121 6.64137 3.81951 7.19235 5.55483 9.14435C7.12107 10.9075 9.54265 10.9075 11.1089 9.14435L13.617 6.33544C14.0303 5.8696 14.6765 5.89844 15.0604 6.39984C15.4443 6.90125 15.4206 7.68536 15.0073 8.1512L12.8952 10.7048C12.6831 10.9395 12.5623 11.2718 12.5624 11.6201V15"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3563_5464">
          <rect width="16" height="16" fill="white" transform="translate(0.333496)" />
        </clipPath>
      </defs>
    </svg>
  );
}
