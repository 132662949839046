import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import styled, { css } from 'styled-components';

import colors from 'src/styles/theme/colors';

type Props = {
  isLoading: boolean;
  type?: string;
  color?: string;
  height?: string | number;
  width?: string | number;
  cover?: boolean;
  className?: string;
};

export function Loader({
  isLoading = false,
  type = 'spokes',
  color = colors.Ivory,
  height = '100%',
  width = '100%',
  cover = true,
  className = '',
}: Props) {
  const [localIsLoading, setLocalIsLoading] = useState(isLoading);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoading) {
      setLocalIsLoading(true);
    } else {
      timeout = setTimeout(() => {
        setLocalIsLoading(false);
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  const loader = (
    <ReactLoading
      type={type as any}
      color={color}
      height={height}
      width={width}
      className={className}
    />
  );

  if (!isLoading) {
    return null;
  }

  if (cover) {
    return (
      <Container isLoading={localIsLoading}>
        <LoaderWrapper>{loader}</LoaderWrapper>
      </Container>
    );
  }

  return localIsLoading ? loader : null;
}

const Container = styled.div<any>`
  background-color: rgba(42, 42, 42, 0.42);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  z-index: 9999;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 1;
      pointer-events: all;
      transition: opacity 0s;
    `}
  svg {
    fill: inherit;
  }
`;

const LoaderWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate3d(-50%, -50%, 0);
`;
