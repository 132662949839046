import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { FilePreview } from 'src/components/FilePreview';
import { encodeFile } from 'src/helpers/file';
import { Logger } from 'src/services/Logger';
import { sendNotification } from 'src/twilio/TwilioApi';
import { MAX_MSG_LEN } from 'src/utils/constants';

import messages from './chat.messages';
import { ChatAttachButton } from './ChatAttachButton';
import { ChatSendButton } from './ChatSendButton';

const minHeightInput = 45;
const maxHeightInput = 95;

const selector = {
  container: 'chat-input-container',
  input: 'chat-input-inner',
};

type Props = {
  uniqueName: string;
  onMessageSent: Function;
  providerName: string;
  sendMessage: Function;
};

export function ChatInput({
  uniqueName = '',
  onMessageSent = (x: boolean) => x,
  providerName,
  sendMessage,
}: Props) {
  const [inputValue, setInputValue] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const [inputHeight, setInputHeight] = useState<number>(minHeightInput);
  const intl = useIntl();
  const { patientId } = useParams<{ patientId: string }>();
  const { trackChatMessageSent } = useTrackEvent();

  const onInputChange = (event: Event & { target: HTMLInputElement; key: string }) => {
    const {
      target: { value, scrollHeight, clientHeight },
    } = event;
    const offset = 5;
    const height = scrollHeight + offset >= clientHeight ? scrollHeight : minHeightInput;

    setInputHeight(height > maxHeightInput ? maxHeightInput : height);
    setInputValue(value);
  };

  const onSend = async () => {
    if ((inputValue || attachmentFile) && !isSending) {
      setIsSending(true);
      try {
        let idx;
        const name = providerName;

        if (attachmentFile) {
          const file = encodeFile(attachmentFile);

          idx = await sendMessage(file, { name, isProvider: true, msgWithText: inputValue });
          setAttachmentFile(null);
        } else {
          idx = await sendMessage(inputValue, { name, isProvider: true });
        }
        await sendNotification({
          identity: uniqueName,
          roomId: uniqueName,
          body: inputValue || ' ',
          filename: attachmentFile?.name,
        });
        trackChatMessageSent({
          patientId: patientId || '',
          messageIndex: idx,
          conversationId: uniqueName,
        });
        onMessageSent(true);
        setInputValue('');
      } catch (error) {
        onMessageSent(false);
        Logger.captureException(error);
      }
      setIsSending(false);
    }
  };

  const onAttachment = (file: File | null) => {
    setAttachmentFile(file);
  };

  const deleteAttachment = () => {
    setAttachmentFile(null);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const { key, shiftKey } = event;

    if (key === 'Enter' && !shiftKey) {
      onSend();
    }
  };

  const placeholder = intl.formatMessage({ id: messages.placeholder.id });
  const sendPlaceholder = '...⏳';
  const value = !isSending ? inputValue : sendPlaceholder;

  return (
    <>
      <FilePreview file={attachmentFile} onDelete={deleteAttachment} />
      <Container data-cy={selector.container}>
        {!attachmentFile && <ChatAttachButton onClick={onAttachment} />}
        <StyledInput
          value={value}
          onChange={onInputChange}
          placeholder={placeholder}
          maxlength={MAX_MSG_LEN}
          onKeyDown={onKeyDown}
          inputHeight={value && value !== sendPlaceholder ? inputHeight : minHeightInput}
          data-cy={selector.input}
        />
        <ChatSendButton inputValue={inputValue} onClick={onSend} file={attachmentFile} />
      </Container>
    </>
  );
}

const StyledInput = styled.textarea<any>`
  border: solid 1px #cecece;
  border-radius: 2.5px;
  padding: 0.5em;
  width: 100%;
  resize: none;
  height: ${({ inputHeight }) => inputHeight}px;
  font-size: 16px;
  overflow-y: ${({ inputHeight }) => (inputHeight === maxHeightInput ? 'auto' : 'hidden')};
  word-wrap: break-word;
  font-size: 16px;
  line-height: 130%;
  min-height: 6em;
  font-family: 'GT-America-Standard', sans-serif;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
`;
