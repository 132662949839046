export enum ClinicFeature {
  ClinicLedOnboarding = 'add_patient',
  RiskAnalysis = 'at_risk',
  LibraryContent = 'library',
  ExportData = 'download_data',
  Messages = 'messages',
  Notes = 'notes',
  Video = 'video',
  DailyActions = 'daily_actions',
  ManualWeightEntry = 'manual_weight_entry',
  PatientGrouping = 'patient_grouping',
  EmailNotifications = 'email_notifications',
}

export type ClinicSettings = {
  ClinicID: string;
  features?: string[];
  weightUnit?: string;
  treatmentsOffered?: number[];
};

export const isValidClinicFeature = (x: string): x is ClinicFeature =>
  Object.values(ClinicFeature).includes(x as ClinicFeature);

export function clinicHasFeatureEnabled(
  settings: Partial<ClinicSettings>,
  feature: ClinicFeature
): boolean {
  return Boolean(settings?.features?.includes(feature));
}

export function isDailyActionsEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.DailyActions));
}

export function isMessagesEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.Messages));
}

export function isVideoEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.Video));
}

export function isNotesEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.Notes));
}

export function isClinicLedOnboardingEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.ClinicLedOnboarding));
}

export function isRiskAnalyisEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.RiskAnalysis));
}

export function isLibraryContentEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.LibraryContent));
}

export function isManualWeightEntryEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.ManualWeightEntry));
}

export function isExportDataEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.ExportData));
}

export function isPatientGroupingEnabled(settings?: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.PatientGrouping));
}

export function isEmailNotificationsEnable(settings: ClinicSettings) {
  return Boolean(settings?.features?.includes(ClinicFeature.EmailNotifications));
}

export const DEFAULT_WEIGHT_UNIT = 'kg';

export function getDefaultClinicSettings(clinicId: string | undefined): ClinicSettings {
  return {
    weightUnit: DEFAULT_WEIGHT_UNIT,
    features: [ClinicFeature.Notes],
    ClinicID: clinicId || '',
    treatmentsOffered: [1, 5],
  };
}

export function findMostUsedWeightUnit(clinicsSettings: ClinicSettings[]) {
  const unitsVotes = clinicsSettings.reduce(
    (votes, s) => {
      if (!s.weightUnit) {
        return votes;
      }

      const unit = s.weightUnit;

      const unitVotes = votes[unit] ?? 0;

      return {
        ...votes,
        [unit]: unitVotes + 1,
      };
    },
    {} as { [k: string]: number }
  );

  if (Object.keys(unitsVotes).length === 0) {
    return DEFAULT_WEIGHT_UNIT;
  }

  const [unit] = Object.entries(unitsVotes).reduce((mostVoted, unitVotes) => {
    if (unitVotes[1] > mostVoted[1]) {
      return unitVotes;
    }

    return mostVoted;
  });

  return unit;
}
