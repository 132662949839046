import styles from './Button.module.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /** How the button style should be? */
  variant?:
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'success'
    | 'danger'
    | 'primaryDark'
    | 'outlinedDark';
  /** How large should the button be? */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /** Button contents */
  label: string;
  /** Left icon component */
  leftIcon?: React.ReactNode | null;
  /** Right icon component */
  rightIcon?: React.ReactNode | null;
  /** Disable the button */
  disabled?: boolean;
};

/**
 * Primary UI component for user interaction
 */
export function Button({
  variant = 'primary',
  disabled = false,
  size = 'md',
  label,
  leftIcon,
  rightIcon,
  ...props
}: ButtonProps) {
  const btnColor = variant.charAt(0).toUpperCase() + variant.slice(1);
  const btnSize = size.charAt(0).toUpperCase() + size.slice(1);

  return (
    <button
      disabled={disabled}
      type="button"
      className={[styles['btn'], styles[`btn${btnSize}`], styles[`btn${btnColor}`]].join(' ')}
      {...props}
    >
      {leftIcon}
      <span>{label}</span>
      {rightIcon}
    </button>
  );
}
