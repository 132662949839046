import { isString } from '@allurion/utils';
import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';

import { StyledSelect } from 'src/components/ui/ReactSelect';
import { VideoOutlinedIcon } from 'src/icons/VideoIcon';
import { Label } from 'src/styles/components';
import { VIDEO_INPUT_ID, VIDEO_SIZE } from 'src/utils/constants';

import { DeviceOption } from '../video-call-types';
import messages from '../video-call.messages';

import { CameraContainer, VideoWrapper, NoDeviceLabel } from './styles';

type Props = {
  cameras: MediaDeviceInfo[];
  onSelection: (x: any) => void;
  isCallOver?: boolean;
  onPermissionError: (x: any) => void;
};

export function CameraSelector({
  cameras,
  onSelection = () => {},
  isCallOver,
  onPermissionError = () => {},
}: Props) {
  const [camera, setCamera] = useState<DeviceOption | null>(null);
  const cameraRef = useRef();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>(null);
  const intl = useIntl();

  const { width: videoWidth, height: videoHeight } = VIDEO_SIZE;
  const width = videoWidth / 2.5;
  const height = videoHeight / 2.5;

  const onChange = (device: DeviceOption) => {
    setCamera(device);
  };

  useEffect(() => {
    if (camera) {
      const selectedDevice = {
        ...camera,
        kind: 'videoinput',
      };

      // TODO: wrap onSelection father prop in a callback hook
      onSelection(selectedDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera]);

  const message = intl.formatMessage(messages.camera);
  const selectPlaceholder = intl.formatMessage(messages.cameraSelectorPlaceholder);
  const noDeviceMessage = intl.formatMessage(messages.noDevices);
  const isDisabled = cameras?.length < 1;
  const options = cameras?.map(({ deviceId, label }) => ({
    id: deviceId,
    value: deviceId,
    label,
  }));

  useEffect(() => {
    const showLocalVideo = async () => {
      const localVideoTrack = await createLocalVideoTrack({ width, height });

      setVideoTrack(localVideoTrack);
    };

    if (camera) {
      try {
        showLocalVideo();
      } catch (error: any) {
        onPermissionError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, camera]);

  useEffect(() => {
    const videoRef = cameraRef.current;

    videoTrack?.attach(videoRef);

    return () => {
      videoTrack?.stop();
      videoTrack?.disable();
      videoTrack?.detach();
    };
  }, [videoTrack]);

  useEffect(() => {
    const updateCamera = async () => {
      videoTrack?.detach();
      const localVideoTrack = await createLocalVideoTrack({ deviceId: camera?.id, width, height });

      localVideoTrack?.attach(cameraRef.current);
      setVideoTrack(localVideoTrack);
    };

    if (camera) {
      try {
        updateCamera();
      } catch (error: any) {
        onPermissionError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera]);

  const value = camera || noDeviceMessage;
  const noCameraMessage = intl.formatMessage(messages.noCamera);
  const isCameraAvailable = !!cameras.length;
  const showCameraPreview = !isString(value) && isCallOver && isCameraAvailable;

  return (
    <CameraContainer>
      <Label htmlFor={VIDEO_INPUT_ID}>
        <VideoOutlinedIcon color="#00363F" size={16} strokeWidth={2.5} />
        {message}
      </Label>
      {!isCameraAvailable ? (
        <NoDeviceLabel>{noCameraMessage}</NoDeviceLabel>
      ) : (
        <StyledSelect
          id={VIDEO_INPUT_ID}
          data-testid={VIDEO_INPUT_ID}
          isDisabled={isDisabled}
          onChange={onChange}
          options={options}
          isSearchable={false}
          value={value}
          placeholder={selectPlaceholder}
        />
      )}
      {showCameraPreview && (
        <VideoWrapper height={height}>
          {/* @ts-ignore  */}
          <video ref={cameraRef} width={width} height={height} autoPlay />
        </VideoWrapper>
      )}
    </CameraContainer>
  );
}
