import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import checkmarkIconSrc from 'src/images/at-risk-checkmark-clinic.svg';
import flagIconSrc from 'src/images/at-risk-flag-clinic.svg';
import bulletSrc from 'src/images/bullet.svg';
import translations from 'src/messages/translations';

export function AtRiskHelpContent() {
  const intl = useIntl();
  const videoSrc =
    'https://player.vimeo.com/video/711432133?h=0f8f961c23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
  const footerLink = 'https://app.bigtincan.com/';

  return (
    <AtRiskHelpContentContainer>
      <HelpContentSection>
        <HelpContentVideoWrapper>
          <HelpContentVideo
            allowFullScreen
            src={videoSrc}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            title="Success Predictor Overview"
          />
        </HelpContentVideoWrapper>
        <HelpContentTitle>{intl.formatMessage(translations.atRiskModalContent1)}</HelpContentTitle>
        <HelpContentDescription>
          {intl.formatMessage(translations.atRiskModalContent1Paragraph1)}
        </HelpContentDescription>
        <HelpContentDescription>
          {intl.formatMessage(translations.atRiskModalContent1Paragraph2)}
        </HelpContentDescription>
        <HelpContentList>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph2Item1)}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph2Item2)}
          </HelpContentListItem>
        </HelpContentList>
        <HelpContentDescription>
          {intl.formatMessage(translations.atRiskModalContent1Paragraph3)}
        </HelpContentDescription>
        <HelpContentList>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph3Item1)}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph3Item2)}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph3Item3)}
          </HelpContentListItem>
        </HelpContentList>
      </HelpContentSection>
      <HelpContentSection>
        <HelpContentTitle>{intl.formatMessage(translations.atRiskModalContent2)}</HelpContentTitle>
        <HelpContentDescription>
          <RiskIcon src={flagIconSrc} alt="flag" />
          {intl.formatMessage(translations.atRiskModalContent2Paragraph1)}
        </HelpContentDescription>
        <HelpContentList>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph1Item1)}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph1Item2)}
          </HelpContentListItem>
        </HelpContentList>
        <HelpContentDescription>
          {intl.formatMessage(translations.atRiskModalContent2Paragraph2)}
        </HelpContentDescription>
        <HelpContentList>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph2Item1, {
              b: (chunks: ReactNode[]) => <CustomBold>{chunks}</CustomBold>,
            })}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph2Item2, {
              b: (chunks: ReactNode[]) => <CustomBold>{chunks}</CustomBold>,
            })}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph2Item3, {
              b: (chunks: ReactNode[]) => <CustomBold>{chunks}</CustomBold>,
            })}
          </HelpContentListItem>
        </HelpContentList>
        <HelpContentDescription>
          <RiskIcon src={checkmarkIconSrc} alt="checkmark" />
          {intl.formatMessage(translations.atRiskModalContent2Paragraph3)}
        </HelpContentDescription>
        <HelpContentList>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph3Item1)}
          </HelpContentListItem>
          <HelpContentListItem>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph3Item2)}
          </HelpContentListItem>
        </HelpContentList>
      </HelpContentSection>
      <HelpContentSection>
        <HelpContentTitle>{intl.formatMessage(translations.atRiskModalContent3)}</HelpContentTitle>
        <HelpContentDescription>
          {intl.formatMessage(translations.atRiskModalContent3Paragraph1)}
        </HelpContentDescription>
        <HelpContentDescription>
          {intl.formatMessage(translations.atRiskModalContent3Paragraph2)}
        </HelpContentDescription>
      </HelpContentSection>
      <HelpContentSection>
        <HelpContentFooter>
          {intl.formatMessage(translations.atRiskModalFooterLink, {
            a: (chunks: ReactNode[]) => (
              <a href={footerLink} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          })}
        </HelpContentFooter>
      </HelpContentSection>
    </AtRiskHelpContentContainer>
  );
}

const AtRiskHelpContentContainer = styled.div`
  max-width: 775px;
  max-height: 635px;
`;

const HelpContentVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HelpContentVideoWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  margin-bottom: 24px;
`;

const HelpContentSection = styled.div`
  margin-bottom: 24px;
`;

const HelpContentTitle = styled.h4`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Primary};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 15px;
`;

const HelpContentFooter = styled.p`
  text-align: center;
  padding-bottom: 24px;
`;

// NOTE: user agent stylesheet overwrites bold style, we set out own with font-weight
const CustomBold = styled.span`
  font-weight: bold;
`;

const HelpContentDescription = styled.p`
  position: static;
  left: 0px;
  top: 36px;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Primary};
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 15px 0px;
`;

const HelpContentList = styled.ul`
  list-style-type: disclosure-closed;
  margin-left: 42px;
`;

const HelpContentListItem = styled.li`
  position: static;
  left: 57px;
  top: 0px;
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Primary};
  flex: none;
  order: 2;
  flex-grow: 1;
  margin: 0px 10px;
  display: list-item;
  padding-bottom: 6px;
  list-style-image: url(${bulletSrc});
  padding-left: 6px;
`;

const RiskIcon = styled.img`
  padding-right: 12px;
`;
