import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  to: string;
  children: any;
  style?: any;
  className?: string;
  state?: any;
  onClick?: (evt: any) => void;
  target?: string;
};

export function MyLink({ to, children, style, className, state, ...props }: Props) {
  return (
    <StyledLink to={to} style={style} className={className} state={state} {...props}>
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.75;
  }
`;
