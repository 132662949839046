export function BurgerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path
        d="M1 5H13"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1 1H13"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1 9H13"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
