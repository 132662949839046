/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  firstName: {
    id: 'termsAndConditions.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'termsAndConditions.lastName',
    defaultMessage: 'Last Name',
  },
  todaysDate: {
    id: 'termsAndConditions.todaysDate',
    defaultMessage: "Today's Date",
  },
  submit: {
    id: 'termsAndConditions.submit',
    defaultMessage: 'AGREE & SUBMIT ELECTRONIC SIGNATURE',
  },
  terms: {
    id: 'termsAndConditions.terms',
    defaultMessage:
      'By signing, I, <First Name Last Name>, agree to the Data Sharing Agreement on behalf of <Clinic Name>, and I represent that I have the authority to bind <Clinic Name> to these Data Sharing Terms.',
  },
  instructions: {
    id: 'termsAndConditions.instructions',
    defaultMessage:
      'Provide your electronic signature by entering your name and today’s date below:',
  },
});
