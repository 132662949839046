export function UserIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="5" r="3.45" stroke="currentColor" strokeOpacity="0.9" strokeWidth="1.1" />
      <path
        d="M13 15C13 12.5454 10.2727 11 8 11C5.72727 11 3 12.5454 3 15"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
      />
    </svg>
  );
}
