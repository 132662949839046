export type User = {
  sessionToken?: string;
  username?: string;
  email?: string;
  emailVerified?: boolean;
  roleId?: string;
  firstName?: string;
  lastName?: string;
  unitsPreference?: string;
  providerId?: string;
  notificationsEnabled?: boolean;
  notificationsFrequency?: string;
};
//FIXME: replace with domain package
export const SUPER_ADMIN_ROLE_ID = '1';
export const ADMIN_ROLE_ID = '2';
export const STAFF_ROLE_ID = '3';
export const COACH_ROLE_ID = '4';

export const isAdmin = (user: User) => user?.roleId === ADMIN_ROLE_ID;

export const isStaff = (user: User) => user?.roleId === STAFF_ROLE_ID;

export type Unit = 'lb' | 'kg' | 'stone';
