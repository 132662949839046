import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { InsightsApi, buildUrl } from 'src/api/Api';
import { ClinicTreatment } from 'src/domain/Clinic';

export const CLINIC_TREATMENTS = (clinicId: string) => `/clinics/${clinicId}/treatments`;

export function useClinicTreatements(clinicId?: string) {
  const url = clinicId && buildUrl(CLINIC_TREATMENTS(clinicId));
  const { data, error, isValidating } = useSWR(
    url,
    (_url: string) =>
      InsightsApi.get<{ treatments: ClinicTreatment[]; treatmentList: ClinicTreatment[] }>(
        _url
      ).then(({ treatmentList, treatments }) => {
        treatmentList.sort((first, second) =>
          first.treatment_type_id < second.treatment_type_id ? -1 : 1
        );
        treatments.sort((first, second) =>
          first.treatment_type_id < second.treatment_type_id ? -1 : 1
        );

        return {
          treatments,
          treatmentList,
        };
      }),
    { revalidateOnFocus: false, dedupingInterval: ONE_MINUTE_IN_MS }
  );

  return {
    treatments: data?.treatments ?? [],
    treatmentList: data?.treatmentList ?? [],
    isLoading: isValidating,
    error,
  };
}
