import { AllurionIcon } from '../../icons/AllurionIcon';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './AccountNavbar.module.scss';

export type AccountNavbarProps = {
  /** Nav Button action  */
  onMenuButtonClick?: () => void;
  /** Logo click action */
  onLogoClick?: () => void;
  /** Array of icon buttons */
  iconButtons?: NavbarItem[];
};

type NavbarItem = IconButtonProps & {
  id: string;
  label?: string;
};

/**
 * Primary UI component for user interaction
 */
export function AccountNavbar({
  iconButtons,
  onMenuButtonClick,
  onLogoClick,
  ...props
}: AccountNavbarProps) {
  return (
    <div className={styles.accountNavbar} data-cy="navbar" {...props}>
      <div className={styles.title}>
        <IconButton
          variant="iconDark"
          size="sm"
          icon={<AllurionIcon />}
          onClick={onMenuButtonClick}
          data-cy="logo-buttom"
        />
        <span className={styles.title__text}>Insights</span>
      </div>
      <div className={styles.buttonsWrapper} data-cy="navbar-items">
        {iconButtons?.map(({ id, ...button }) => (
          <Tooltip text={button.label!} key={id} placement="bottom">
            <IconButton key={id} variant="iconDark" {...button} data-cy={id} />
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
