import { IntlProvider } from 'react-intl';

import { useLocale } from 'src/hooks/useLocale';

type Props = {
  children: any;
};

export function LanguageWrapper({ children }: Props) {
  const { configLocale, messages } = useLocale();

  // eslint-disable-next-line no-console
  const onError = (error: any) => console.debug('[ERROR] LanguageWrapper: ', error);

  return (
    <IntlProvider locale={configLocale} defaultLocale="en" messages={messages} onError={onError}>
      {children}
    </IntlProvider>
  );
}
