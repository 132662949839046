import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import { MyLink as Link } from 'src/components/ui/Link';
import * as appRoutes from 'src/utils/appRoutes';

import validate from './login-validate';
import messages from './login.messages';

type Props = {
  initialValues: any;
  onSubmit: (values: any, formikBag: any) => void;
};

export function LoginForm({ initialValues, onSubmit }: Props) {
  const intl = useIntl();

  return (
    <Formik initialValues={initialValues} validate={validate(intl)} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <AllurionTextField
            darkbg
            name="email"
            type="email"
            label={intl.formatMessage(messages.email)}
            placeholder={intl.formatMessage(messages.email)}
            cyErrorLabelSelector="email-error"
          />
          <AllurionTextField
            darkbg
            name="password"
            type="password"
            label={intl.formatMessage(messages.password)}
            placeholder={intl.formatMessage(messages.password)}
          />
          <Controls>
            <TrackedButton
              label={intl.formatMessage(messages.submitBtn)}
              type="submit"
              variant="secondary"
              trackLabel="submit-login-form"
            />
            <StyedLink to={appRoutes.resetPassword}>
              {intl.formatMessage(messages.resetPassword)}
            </StyedLink>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}

const Form = styled.form`
  max-width: 420px;
  margin: 0 auto;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 15px;

  button {
    width: 100%;
  }
`;

const StyedLink = styled(Link)`
  color: #fff;
  font-family: 'GT-America-Standard-Light', sans-serif;
  border-bottom: 2px solid #fff;
  margin-top: 40px;
`;
