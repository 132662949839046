import styled from 'styled-components';

import { getInitials } from 'src/helpers/string';
import { DarkSilver } from 'src/styles/theme/colors';

type Props = {
  name: string;
  color?: string;
};

export function UserAvatar({ name, color = DarkSilver }: Props) {
  const initials = getInitials(name);

  return <StyledAvatar color={color}>{initials}</StyledAvatar>;
}

const StyledAvatar = styled.div`
  color: ${({ theme }) => theme.colors.Primary} !important;
  font-size: 1em !important;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
