/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'termsAndConditions.title',
    defaultMessage: 'Data Sharing Agreement',
  },
  instructions: {
    id: 'termsAndConditions.instructions',
    defaultMessage:
      'Provide your electronic signature by entering your name and today’s date below:',
  },
  agreeTerms: {
    id: 'termsAndConditions.agreeTerms',
    defaultMessage:
      'Please read and sign to agree to the Data Sharing Agreement. This agreement is required for sharing customer data between your practice and Allurion.',
  },
  agreeTermsSuccess: {
    id: 'termsAndConditions.agreeTermsSuccess',
    defaultMessage: 'Successfully Sent Agreement',
  },
  agreeTermsFailure: {
    id: 'termsAndConditions.agreeTermsFailure',
    defaultMessage: 'Failed To Send Agreement',
  },
});
