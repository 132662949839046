/* eslint-disable import/prefer-default-export */

export const rootMeanSquare = (samples: Uint8Array) => {
  const sumSq = samples.reduce((oldSumSq, sample) => oldSumSq + sample * sample, 0);

  return Math.sqrt(sumSq / samples.length);
};

export const parseWithPrecision = (num: string, precision = 1) =>
  parseFloat(num).toFixed(precision);
