import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientWeight } from 'src/domain/patient/Patients';

export const PATIENT_WEIGHT = (patientId: string, weightEntryId?: string) =>
  `/patients/${patientId}/weight${weightEntryId ? `/${weightEntryId}` : ''}`;

export function usePatientWeight(patientId?: string) {
  const url = patientId && buildUrl(PATIENT_WEIGHT(patientId));
  const { data, error, isValidating, mutate } = useSWR(
    url,
    (_url: string) =>
      InsightsApi.get<{ weightData: PatientWeight[] }>(_url).then((res) => res.weightData),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  function createPatientWeightData(patientId: string, body: object) {
    return InsightsApi.post<{ weightData: PatientWeight[] }>(
      buildUrl(PATIENT_WEIGHT(patientId)),
      body
    ).then((res) => {
      mutate();

      return res;
    });
  }

  function deletePatientWeightData(patientId: string, weightEntryId: string) {
    return InsightsApi.delete<{ weightData: PatientWeight[] }>(
      buildUrl(PATIENT_WEIGHT(patientId, weightEntryId))
    ).then((res) => {
      mutate();

      return res;
    });
  }

  return {
    isLoading: isValidating,
    weightData: data ?? [],
    error,
    createPatientWeightData,
    deletePatientWeightData,
  };
}
