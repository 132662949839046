import { useState } from 'react';
import { useIntl } from 'react-intl';
import { components } from 'react-select';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { AllurionSelect } from 'src/components/ui/AllurionSelect';
import { toastError } from 'src/components/ui/toasts';
import { Provider } from 'src/domain/Provider';
import globalMessages from 'src/messages/global.messages';

import styles from './PatientProvidersForm.module.scss';

type Props = {
  providers?: Provider[];
  onCancel: () => void;
  onConfirm: (selectedProvider?: Provider[]) => void;
  allProviders?: Provider[];
};

function Input({ ...props }) {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: 'provider-access.form.input-placeholder',
    defaultMessage: 'Search or select...',
  });

  // @ts-ignore
  return <components.Input placeholder={placeholder} {...props} />;
}

export function PatientProvidersForm({
  allProviders = [],
  providers = [],
  onCancel,
  onConfirm,
}: Props) {
  const [selectedProviders, setSelectedProviders] = useState<Provider[]>(providers);
  const intl = useIntl();

  const onSelectAll = () => {
    setSelectedProviders(allProviders);
  };

  const handleOnChange = (p: Provider[]) => {
    setSelectedProviders(p);
  };

  const handleOnConfirm = () => {
    if (selectedProviders?.length < 1) {
      toastError(
        intl.formatMessage({
          id: 'provider-access.form.providers-error',
          defaultMessage: 'At least 1 provider must be selected.',
        })
      );

      return;
    }
    onConfirm(selectedProviders);
  };

  const getOptionValue = (opt: Provider) => opt.ProviderID;
  const getOptionLabel = (opt: Provider) => `${opt.FirstName} ${opt.LastName}`;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>
          {intl.formatMessage({
            id: 'provider-access.form.input-label',
            defaultMessage: 'Select providers:',
          })}
        </p>
        <TrackedButton
          onClick={onSelectAll}
          label={intl.formatMessage({
            id: 'provider-access.form.select-all',
            defaultMessage: 'Select all',
          })}
          size="xs"
          variant="secondary"
          trackLabel="select-all-providers"
        />
      </div>
      <AllurionSelect
        isMulti
        options={allProviders}
        defaultValue={selectedProviders}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        value={selectedProviders}
        onChange={handleOnChange}
        components={{ Input }}
        // NOTE: We define the placeholder in the above component `Input`
        // we need an empty space so we don't render the default placeholder from react-select!
        placeholder=" "
        noOptionsMessage={() =>
          intl.formatMessage({ id: 'providers-selector.no-options', defaultMessage: 'No options' })
        }
      />
      <div className={styles.buttonsContainer}>
        <TrackedButton
          onClick={onCancel}
          label={intl.formatMessage(globalMessages.cancel)}
          variant="outlined"
          type="button"
          size="sm"
          trackLabel="cancel-patient-providers-form"
        />
        <TrackedButton
          onClick={handleOnConfirm}
          label={intl.formatMessage(globalMessages.save)}
          variant="primary"
          type="submit"
          size="sm"
          trackLabel="submit-patient-providers-form"
        />
      </div>
    </div>
  );
}
