import { Button, PageHeader, IconButton, EmptyState, SortByMenu } from '@allurion/ui';
import { ComponentProps, useEffect, useRef } from 'react';

import { useTrackEvent } from './analytics';

type TrackedButtonProps = ComponentProps<typeof Button> & {
  trackLabel: string;
};

export function TrackedButton({ onClick, trackLabel, ...props }: TrackedButtonProps) {
  const { trackClick } = useTrackEvent();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    trackClick(event.currentTarget, {
      label: trackLabel,
    });
    onClick?.(event);
  }

  return <Button {...props} onClick={handleClick} data-cy={trackLabel} />;
}

type TrackedIconButtonProps = ComponentProps<typeof IconButton> & {
  trackLabel: string;
};

export function TrackedIconButton({ onClick, trackLabel, ...props }: TrackedIconButtonProps) {
  const { trackClick } = useTrackEvent();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    trackClick(event.currentTarget, {
      label: trackLabel,
    });
    onClick?.(event);
  }

  return <IconButton {...props} onClick={handleClick} data-cy={trackLabel} />;
}

type PageHeaderProps = ComponentProps<typeof PageHeader>;

export function TrackedPageHeader({
  button,
  iconButtons,
  onNavButtonClickTrackLabel,
  onNavButtonClick,
  ...props
}: Omit<PageHeaderProps, 'button' | 'iconButtons'> & {
  button?: TrackedButtonProps;
  iconButtons?: TrackedIconButtonProps[];
  onNavButtonClickTrackLabel?: string;
}) {
  const { trackClick } = useTrackEvent();

  const pageHeaderProps: PageHeaderProps = {
    ...props,
  };

  if (button) {
    const { trackLabel, onClick, ...buttonProps } = button;

    pageHeaderProps.button = {
      ...buttonProps,
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        trackClick(event.currentTarget, {
          label: trackLabel,
        });
        onClick?.(event);
      },
      //@ts-ignore
      'data-cy': trackLabel,
    };
  }

  if (iconButtons) {
    pageHeaderProps.iconButtons = iconButtons.map(({ trackLabel, onClick, ...buttonProps }) => ({
      ...buttonProps,
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        trackClick(event.currentTarget, {
          label: trackLabel,
        });
        onClick?.(event);
      },
      //@ts-ignore
      'data-cy': trackLabel,
    }));
  }

  if (onNavButtonClick) {
    pageHeaderProps.onNavButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      trackClick(event.currentTarget, {
        label: onNavButtonClickTrackLabel ?? 'page-header-back-button',
      });
      onNavButtonClick?.(event);
    };
  }

  return <PageHeader {...pageHeaderProps} />;
}

export function TrackedEmptyState({
  trackedLabel,
  ...props
}: ComponentProps<typeof EmptyState> & {
  trackedLabel: string;
}) {
  const { trackEmptyState } = useTrackEvent();
  const wasTracked = useRef(false);

  useEffect(() => {
    if (wasTracked.current) {
      return;
    }
    trackEmptyState({
      label: trackedLabel,
    });
    wasTracked.current = true;
  }, [trackEmptyState, trackedLabel]);

  return <EmptyState {...props} />;
}

export function TrackedSortByMenu({
  trackLabel,
  ...props
}: ComponentProps<typeof SortByMenu> & {
  trackLabel: string;
}) {
  const { trackSorting } = useTrackEvent();

  return (
    <SortByMenu
      {...props}
      onChange={(value) => {
        trackSorting({
          label: trackLabel,
          sortBy: value,
        });
        props.onChange?.(value);
      }}
    />
  );
}
