import { Card } from '@allurion/ui';
import { toLocalizedLongDate } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { PatientProfile } from 'src/domain/patient/Patients';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useLocale } from 'src/hooks/useLocale';
import translations from 'src/messages/translations';

import styles from './PatientProfileCard.module.scss';

export function PatientProfileCard({ patient }: { patient: PatientProfile }) {
  const intl = useIntl();
  const { locale } = useLocale();
  const { unitsPreference } = useCurrentUser();
  const heightUnit = unitsPreference === 'kg' ? `cm` : `in`;

  return (
    <Card title={intl.formatMessage(translations.personalInformation)}>
      <PersonalInformation
        label={intl.formatMessage({
          id: 'patient-profile.firstName',
          defaultMessage: 'First Name',
        })}
        value={patient?.name ?? '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({
          id: 'patient-profile.lastName',
          defaultMessage: 'Last Name',
        })}
        value={patient?.lastname ?? '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({
          id: 'patient-profile.email',
          defaultMessage: 'Email Address',
        })}
        value={patient?.email_address ?? '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({ id: 'gender' })}
        value={patient?.gender ? intl.formatMessage({ id: `gender.${patient?.gender}` }) : ''}
      />
      <PersonalInformation
        label={intl.formatMessage({ id: 'dob', defaultMessage: 'Date of birth' })}
        value={toLocalizedLongDate(patient?.birthday, locale) || '-'}
      />
      <PersonalInformation
        label={intl.formatMessage({ id: 'height' })}
        value={`${patient?.height} ${heightUnit}`}
      />
    </Card>
  );
}

function PersonalInformation({ label, value }: { label: string; value: string }) {
  return (
    <div className={styles.personalInformation}>
      <span className={styles.personalInformationLabel}>{label}</span>
      <span className={styles.personalInformationValue}>{value}</span>
    </div>
  );
}
