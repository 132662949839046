import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { PatientsAccess } from 'src/domain/patient/Patients';
import { Provider } from 'src/domain/Provider';
import { findDuplicates } from 'src/helpers/shared';
import { CheckIcon } from 'src/icons/CheckIcon';
import { WarningIcon } from 'src/icons/WarningIcon';
import { CircularProgress } from 'src/ui/CircularProgress';

import { ConfirmProviders } from './ConfirmProviders';
import { EditProvidersEnd } from './EditProvidersEnd';
import { ProvidersForm } from './ProvidersForm';

type Props = {
  selectedPatients?: PatientsAccess[];
  providers: Provider[];
  onCancel: () => void;
  onConfirm: (
    patientsIds: number[],
    providersIds: number[],
    onSuccess: () => void,
    onFailure: () => void
  ) => void;
};

export function EditProvidersModal({
  selectedPatients = [],
  providers,
  onCancel,
  onConfirm,
}: Props) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedProviders, setSelectedProviders] = useState<Provider[]>([]);
  const [endMessage, setEndMessage] = useState<{
    label: string;
    error?: string;
    icon?: JSX.Element;
    errorIcon?: JSX.Element;
  }>({
    label: '',
  });
  const intl = useIntl();

  const onSuccess = () =>
    setEndMessage({
      icon: (
        <span style={{ verticalAlign: 'sub', marginRight: 6 }}>
          <CheckIcon />
        </span>
      ),
      label: intl.formatMessage({
        id: 'provider-access.providers-updated',
        defaultMessage: 'Providers updated',
      }),
    });

  const onFailure = () =>
    setEndMessage({
      errorIcon: (
        <span style={{ verticalAlign: 'sub', marginRight: 6 }}>
          <WarningIcon />
        </span>
      ),
      label: intl.formatMessage({
        id: 'global.try-again',
        defaultMessage: 'Please try again later.',
      }),
      error: intl.formatMessage({
        id: 'global.not-saved-error',
        defaultMessage: 'There was a technical error. Some changes were not saved:',
      }),
    });

  const handleOnConfirm = async () => {
    const providersIds = selectedProviders?.map((p) => parseInt(p.ProviderID, 10));
    const patientsIds = selectedPatients?.map((p) => parseInt(p.patient_id, 10));

    onConfirm(patientsIds, providersIds, onSuccess, onFailure);
    setEndMessage({
      icon: <CircularProgress color="inherit" size="20px" />,
      label: intl.formatMessage({
        id: 'global.saving-changes',
        defaultMessage: 'Saving changes',
      }),
    });
    setCurrentStep((s: number) => s + 1);
  };

  const handleOnNext = (selected: Provider[]) => {
    if (currentStep < 1) {
      setCurrentStep((s: number) => s + 1);
      setSelectedProviders(selected);
    }
  };

  const handleOnPrev = () => {
    if (currentStep > 0) {
      setCurrentStep((s: number) => s - 1);
    }
  };

  const patientsProviders = selectedPatients?.map(({ providers: p }) =>
    p?.map(({ provider_id: prid }) => prid)
  );
  const commonPatientsProviders: string[] =
    patientsProviders.length > 1 ? findDuplicates(patientsProviders) : patientsProviders.flat();

  const availableProviders = providers;
  const selectDefaultValue = providers.filter((p) =>
    commonPatientsProviders?.includes(p?.ProviderID)
  );

  const getProviderFullName = (p: Provider) => `${p.FirstName || ''} ${p.LastName || ''}`;
  const providersNames = selectedProviders?.map(getProviderFullName);

  const patientsNames = selectedPatients.map(
    (patient) => `${patient.firstname} ${patient.lastname}`
  );
  const stepperWizard = [
    <ProvidersForm
      key="form-step"
      selectedPatients={selectedPatients}
      providers={availableProviders}
      defaultValue={selectDefaultValue}
      onNext={handleOnNext}
      onCancel={onCancel}
    />,
    <ConfirmProviders
      key="confirm-step"
      providersNames={providersNames}
      patientsNames={patientsNames}
      onConfirm={handleOnConfirm}
      onPrev={handleOnPrev}
    />,
    <EditProvidersEnd
      key="edit-step"
      providersNames={providersNames}
      endMessage={endMessage}
      onClose={onCancel}
    />,
  ];

  return (
    <ModalContainer>
      <ModalContent>{stepperWizard[currentStep]}</ModalContent>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.SoftGray};
  min-width: 500px;
  max-width: 870px;
  max-height: 630px;
  font-size: 18px;
  overflow-y: scroll;
`;

const ModalContent = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
`;
