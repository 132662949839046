export function HelpIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.89838 5.82589C9.71206 5.20476 9.04381 4.767 8.29766 4.86335C7.55516 4.95923 7.05052 5.54159 7.0502 6.175L7.04991 6.72501L5.94991 6.72444L5.9502 6.17444C5.95084 4.93488 6.92057 3.93204 8.15678 3.77241C9.38934 3.61324 10.5975 4.32799 10.952 5.50986C11.3101 6.7036 10.6648 7.93008 9.5083 8.40338C9.21394 8.52385 9.05005 8.78231 9.05004 9.04016L9.05004 9.8502H7.95004V9.04016C7.95005 8.30381 8.41431 7.66254 9.09166 7.38533C9.7658 7.10944 10.0811 6.43517 9.89838 5.82589Z"
        fill="currentColor"
      />
      <path
        d="M8.5002 11.9002C8.8868 11.9002 9.2002 11.5868 9.2002 11.2002C9.2002 10.8136 8.8868 10.5002 8.5002 10.5002C8.1136 10.5002 7.8002 10.8136 7.8002 11.2002C7.8002 11.5868 8.1136 11.9002 8.5002 11.9002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9502 8.0002C1.9502 4.38273 4.88273 1.4502 8.5002 1.4502C12.1177 1.4502 15.0502 4.38273 15.0502 8.0002C15.0502 11.6177 12.1177 14.5502 8.5002 14.5502C4.88273 14.5502 1.9502 11.6177 1.9502 8.0002ZM8.5002 2.5502C5.49024 2.5502 3.0502 4.99024 3.0502 8.0002C3.0502 11.0101 5.49024 13.4502 8.5002 13.4502C11.5101 13.4502 13.9502 11.0101 13.9502 8.0002C13.9502 4.99024 11.5101 2.5502 8.5002 2.5502Z"
        fill="currentColor"
      />
    </svg>
  );
}
