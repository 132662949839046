export function FlagIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66675 10C2.66675 10 3.33341 9.33337 5.33341 9.33337C7.33341 9.33337 8.66675 10.6667 10.6667 10.6667C12.6667 10.6667 13.3334 10 13.3334 10V2.00004C13.3334 2.00004 12.6667 2.66671 10.6667 2.66671C8.66675 2.66671 7.33341 1.33337 5.33341 1.33337C3.33341 1.33337 2.66675 2.00004 2.66675 2.00004V10Z"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M2.66675 14.6667V10"
        stroke="currentColor"
        strokeWidth="1.1"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
