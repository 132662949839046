/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'user-profile.title',
    defaultMessage: 'Your Account',
  },
  firstName: {
    id: 'user-profile.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'user-profile.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'user-profile.email',
    defaultMessage: 'Email Address',
  },
  clinic: {
    id: 'user-profile.clinic',
    defaultMessage: 'Clinic',
  },
  units: {
    id: 'user-profile.units',
    defaultMessage: 'Unit of Measurement Preference',
  },
  messageNotifications: {
    id: 'user-profile.messageNotifications',
    defaultMessage: 'Message notifications',
  },
  messageNotificationsDescription: {
    id: 'user-profile.messageNotifications.description',
    defaultMessage: "We'll notify you when a patient sends a new message.",
  },
  messageNotificationsToggleLabel: {
    id: 'user-profile.messageNotifications.toggleLabel',
    defaultMessage: 'Receive via email',
  },
  messageNotificationsNotifyMe: {
    id: 'user-profile.messageNotifications.notifyMe',
    defaultMessage: 'Notify me',
  },
  messageNotificationsOnceADay: {
    id: 'user-profile.messageNotifications.onceADay',
    defaultMessage: 'Once a day',
  },
  messageNotificationsOnceADayDescription: {
    id: 'user-profile.messageNotifications.onceADayDescription',
    defaultMessage: "You'll receive one email every morning.",
  },
  messageNotificationsEveryTime: {
    id: 'user-profile.messageNotifications.everyTime',
    defaultMessage: "Every time there's new messages",
  },
  resetPassword: {
    id: 'user-profile.resetPassword',
    defaultMessage: 'Reset Password',
  },
  saveProfile: {
    id: 'user-profile.saveProfile',
    defaultMessage: 'Save Profile',
  },
  updateProfileSuccess: {
    id: 'user-profile.saveProfileSuccess',
    defaultMessage: 'Changes Saved',
  },
  updateProfileFailure: {
    id: 'user-profile.saveProfileFailure',
    defaultMessage: 'Failed to Update Profile',
  },
});
