import { defineMessages } from 'react-intl';

export default defineMessages({
  noPatientsFoundTitle: {
    id: 'clinic-patients.no-patients-found-title',
    defaultMessage: 'Nothing found',
  },
  noPatientsFoundSubtitle: {
    id: 'clinic-patients.no-patients-found-subtitle',
    defaultMessage: 'No patients were found, check your search or try a different name.',
  },
  noPatientsAvailableTitle: {
    id: 'clinic-patients.no-patients-available-title',
    defaultMessage: 'Your patients will appear here',
  },
  noPatientsAvailableForAdminWithPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-admin-with-patient-grouping-subtitle',
    defaultMessage:
      'To view patients, invite a new one and assign yourself as a provider, or go to Organize patients and assign patients to yourself.',
  },
  noPatientsAvailableForAdminWithoutPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-admin-without-patient-grouping-subtitle',
    defaultMessage: 'Invite new patients and they will appear here.',
  },
  noPatientsAvailableForStaffWithPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-staff-with-patient-grouping-subtitle',
    defaultMessage:
      'Ask one of the administrators of this clinic to invite a patient and assign you as a provider, or to assign you as a provider of existing patients.',
  },
  noPatientsAvailableForStaffWithoutPatientGroupingSubtitle: {
    id: 'clinic-patients.no-patients-available-for-staff-without-patient-grouping-subtitle',
    defaultMessage:
      'Ask one of the administrators of this clinic to invite new patients and they will appear here.',
  },
});
