export function FileIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.25 30L18.25 21L28.25 21" stroke="currentColor" strokeWidth="1.1" />
      <line x1="10.25" y1="10.45" x2="22.25" y2="10.45" stroke="currentColor" strokeWidth="1.1" />
      <line x1="10.25" y1="16.45" x2="22.25" y2="16.45" stroke="currentColor" strokeWidth="1.1" />
      <path
        d="M28.25 2L4.25 2L4.25 30L18.25 30L28.25 20.6667L28.25 2Z"
        stroke="currentColor"
        strokeWidth="1.1"
      />
    </svg>
  );
}
