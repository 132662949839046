import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { PatientNote } from 'src/domain/patient/Patients';
import { usePatientNotes } from 'src/hooks/usePatientNotes';
import { NEW, COMPLETED } from 'src/utils/constants';

import { NotePanel } from './NotePanel';
import { NotesList } from './NotesList';

type PatientNotesProps = {
  setIsSidebarOpen: (isOpen: boolean) => void;
  setActiveButton: (button: string) => void;
};

export function PatientNotes({ setIsSidebarOpen, setActiveButton }: PatientNotesProps) {
  const { patientId } = useParams();
  const { isLoading, notes, saveNote, deleteNote } = usePatientNotes(patientId);
  const [noteId, setNoteId] = useState('');
  const [mode, setMode] = useState('');
  const { trackClick } = useTrackEvent();

  const handleCloseClick = () => {
    setIsSidebarOpen(false);
    setActiveButton('');
    trackClick(null, {
      label: 'close-patient-notes',
    });
  };

  const onNewNote = () => {
    setMode(NEW);
    trackClick(null, {
      label: 'open-new-patient-note-form',
    });
  };

  const onChange = (noteId?: string) => {
    if (!noteId) {
      setNoteId(notes[0]?.NoteID);
    } else {
      setNoteId(noteId);
    }
    setMode(COMPLETED);
  };

  const onSelectNote = (note: PatientNote) => {
    onChange(note.NoteID);
  };

  const selectedNote = useMemo(
    () => notes.find(({ NoteID }) => NoteID === noteId),
    [noteId, notes]
  );

  const onReset = () => {
    setMode('');
    setNoteId('');
  };

  const isEditMode = mode === NEW || selectedNote;

  return (
    <>
      {!isEditMode ? (
        <NotesList
          notes={notes}
          onSelectNote={onSelectNote}
          onNewNote={onNewNote}
          handleCloseClick={handleCloseClick}
          isLoading={isLoading}
        />
      ) : (
        <NotePanel
          note={selectedNote}
          changeFormMode={(newMode: string) => setMode(newMode)}
          onReset={onReset}
          saveNote={saveNote}
          deleteNote={deleteNote}
        />
      )}
    </>
  );
}
