import * as React from 'react';
import styled from 'styled-components';

import { AttachFileIcon } from 'src/icons/AtachFileIcon';
import { UPLOAD_ATTACHMENT } from 'src/utils/constants';

const selector = {
  hiddenFileInput: 'chat-attach-hidden-file-input',
  buttonWrapper: 'chat-attach-button',
};

type Props = {
  onClick: Function;
};

export function ChatAttachButton({ onClick }: Props) {
  const selectFile: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.persist();
    const { files } = event.target || {};

    if (files) {
      onClick(files[0]);
    }
  };

  const validFileExts = '.jpeg,.jpg,.png,.gif,.pdf,.m4a,.mp3';

  return (
    <>
      <HiddenFileInput
        id={UPLOAD_ATTACHMENT}
        type="file"
        onChange={selectFile}
        accept={validFileExts}
        data-cy={selector.hiddenFileInput}
      />
      <ButtonWrapper htmlFor={UPLOAD_ATTACHMENT} data-cy={selector.buttonWrapper}>
        <AttachFileIcon />
      </ButtonWrapper>
    </>
  );
}

const HiddenFileInput = styled.input`
  position: fixed;
  top: -100em;
`;

const ButtonWrapper = styled.label<any>`
  margin-top: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  padding-top: 1.5em;
  color: ${({ theme }) => theme.colors.Primary};
`;
