import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AllurionSelect } from 'src/components/ui/AllurionSelect';
import { Provider } from 'src/domain/Provider';

type Props = {
  providers: Provider[];
  onSelectProviders: (value: Provider) => void;
  selectedProvider: any;
};

export function ManagePatientsSelectors({
  providers,
  onSelectProviders = () => {},
  selectedProvider,
}: Props) {
  const intl = useIntl();

  const allProviders = {
    ClinicProviderID: undefined,
    FirstName: intl.formatMessage({
      id: 'manage-patients.select-provider-all',
      defaultMessage: 'All providers',
    }),
  };

  const providersSelection = [allProviders, ...providers];

  return (
    <Container>
      <Item style={{ maxWidth: 335 }}>
        <Label>
          {intl.formatMessage({
            id: 'manage-patients.select-provider-label',
            defaultMessage: 'Filter by provider:',
          })}
        </Label>
        <AllurionSelect
          options={providersSelection}
          onChange={onSelectProviders}
          isSearchable={false}
          getOptionValue={(opt: Provider) => opt.ProviderID}
          getOptionLabel={(opt: Provider) => `${opt.FirstName || ''} ${opt.LastName || ''}`}
          defaultValue={allProviders}
          value={selectedProvider || allProviders}
        />
      </Item>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Item = styled.div`
  flex: 0 0 75%;
`;

const Label = styled.p`
  font-family: 'GT-America-Standard-Light', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 10px;
`;
