type ChevronIconProps = {
  direction?: 'up' | 'down' | 'left' | 'right';
};

export function ChevronIcon({ direction = 'up' }: ChevronIconProps) {
  let transform = '';
  switch (direction) {
    case 'up':
      transform = 'rotate(0deg)';
      break;
    case 'down':
      transform = 'rotate(180deg)';
      break;
    case 'left':
      transform = 'rotate(-90deg)';
      break;
    case 'right':
      transform = 'rotate(90deg)';
      break;
  }
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform }}
    >
      <path
        d="M13 10L8 5L3 10"
        stroke="currentColor"
        strokeOpacity="0.9"
        strokeWidth="1.1"
        strokeLinecap="square"
      />
    </svg>
  );
}
