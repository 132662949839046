/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  selectClinic: {
    id: 'create-new-users.selectClinic',
    defaultMessage: 'Clinics',
  },
  firstName: {
    id: 'create-new-users-form.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'create-new-users-form.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'create-new-users-form.email',
    defaultMessage: 'Email Address',
  },
  sendInvite: {
    id: 'create-new-users-form.sendInvite',
    defaultMessage: 'SEND INVITE',
  },
  role: {
    id: 'create-new-users-form.role',
    defaultMessage: 'Role',
  },
  cancelCreateUser: {
    id: 'create-new-users-form.cancelCreateUser',
    defaultMessage: 'Cancel. Do not create user.',
  },
  cancelEditUser: {
    id: 'create-new-users-form.cancelEditUser',
    defaultMessage: 'Cancel. Do not make changes.',
  },
  cancelGoBack: {
    id: 'create-new-users-form.cancelGoBack',
    defaultMessage: 'Cancel and go back',
  },
  adminUser: {
    id: 'create-new-users-form.adminUser',
    defaultMessage:
      'You are adding this user as an Admin user, which means they will be able to add and delete users as well as make other users Admin users.',
  },
  changeAdminUser: {
    id: 'create-new-users-form.changeAdminUser',
    defaultMessage:
      'You are changing this user’s role to Admin, which means they will be able to add and delete users and create other Admin users at their clinic.',
  },
  staffUser: {
    id: 'create-new-users-form.staffUser',
    defaultMessage:
      'You are changing this user’s role. They will no longer be able to add or edit users in Allurion Insights.',
  },
  coachUser: {
    id: 'create-new-users-form.coachUser',
    defaultMessage:
      'You are changing this user’s role. They will no longer be able to add or edit users in Allurion Insights.',
  },
  admin: {
    id: 'create-new-users-form.admin',
    defaultMessage: 'Admin',
  },
  staff: {
    id: 'create-new-users-form.staff',
    defaultMessage: 'Staff',
  },
  coach: {
    id: 'create-new-users-form.coach',
    defaultMessage: 'coach',
  },
  roleAction: {
    id: 'create-new-users-form.roleAction',
    defaultMessage: 'Delete or Change Role',
  },
  deleteUser: {
    id: 'create-new-users-form.deleteUser',
    defaultMessage: 'Delete User',
  },
  deleteUserDisclaimer: {
    id: 'create-new-users-form.deleteUserDisclaimer',
    defaultMessage:
      'You are deleting this user from Allurion Insights. They will no longer have access and will need to be reinvited if you wish to add them in the future.',
  },
  updateUser: {
    id: 'create-new-users-form.updateUser',
    defaultMessage: 'UPDATE USER',
  },
});
