import { useCallback, useEffect, useState } from 'react';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { PatientsAccess } from 'src/domain/patient/Patients';
import { Provider } from 'src/domain/Provider';
import { Logger } from 'src/services/Logger';

export const CLINIC_PROVIDER_ACCESS = (clinicId: string) => `/clinics/${clinicId}/patient-access`;

export type ManagePatient = {
  totalPages: number;
  patientsAccess: PatientsAccess[];
};

type Props = {
  clinicId?: string;
  page?: {
    index: number;
    size: number;
  };
  selectedProvider?: Provider;
  patientFilter?: string;
  order: string;
  orderby: string;
};

export function useProviderAccess({
  clinicId,
  page,
  selectedProvider,
  patientFilter,
  order,
  orderby,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState<PatientsAccess[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchPatients = useCallback(async () => {
    setIsLoading(true);
    try {
      const clinicAccess = await getClinicProviderAccess({
        clinicId: clinicId!,
        pageIndex: page?.index,
        pageSize: page?.size,
        providerId: selectedProvider?.ProviderID,
        search: patientFilter,
        order,
        orderby,
      });

      const { totalPages: _totalPages, patientsAccess } = clinicAccess;

      setTotalPages(_totalPages);
      setPatients(patientsAccess);
      setIsLoading(false);
    } catch (error) {
      Logger.captureException(error);
      setIsLoading(false);
    }
  }, [clinicId, page, selectedProvider, patientFilter, order, orderby]);

  const updateProviderAccess = useCallback(
    async (
      patientsIds: number[],
      providersIds: number[],
      onSuccess: () => void,
      onFailure: (err?: Error) => void
    ) => {
      setIsLoading(true);
      try {
        const pats = patientsIds.map((id) => ({ patient_id: id, providers: providersIds }));

        await updateClinicProviderAccess(clinicId!, pats);
        onSuccess();
      } catch (err: any) {
        onFailure(err);
      } finally {
        setIsLoading(false);
      }
    },
    [clinicId]
  );

  useEffect(() => {
    if (clinicId) {
      fetchPatients();
    }
  }, [clinicId, fetchPatients]);

  return {
    isLoading,
    totalPages,
    patients,
    fetchPatients,
    updateProviderAccess,
  };
}

function getClinicProviderAccess({
  clinicId,
  pageIndex,
  pageSize,
  providerId,
  search,
  orderby,
  order,
}: {
  clinicId: string;
  pageIndex?: number;
  pageSize?: number;
  orderby: string;
  order: string;
  providerId?: string;
  search?: string;
}) {
  return InsightsApi.get<ManagePatient>(
    buildUrl(CLINIC_PROVIDER_ACCESS(clinicId.toString()), {
      page: pageIndex || 0,
      pageSize: pageSize || 10,
      providerID: providerId || '',
      search: search || '',
      orderby,
      order,
    })
  );
}

async function updateClinicProviderAccess(
  clinicId: string,
  patients: { patient_id: number; providers: number[] }[]
) {
  return InsightsApi.put<{ message: string }>(CLINIC_PROVIDER_ACCESS(clinicId), {
    patients,
  }).then((res) => res.message);
}
