import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { ClinicStats } from 'src/domain/Clinic';

export const CLINIC_STATS = (clinicId: string) => `/clinics/${clinicId}/stats`;

export function useClinicHistoricalStats(clinicId: string) {
  const url = clinicId && buildUrl(`${CLINIC_STATS(clinicId.toString())}/history`);
  const { data, error, isValidating } = useSWR(
    url,
    (_url: string) => InsightsApi.get<{ stats: ClinicStats[] }>(_url).then(({ stats }) => stats),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return { historicalStats: data ?? [], isLoading: isValidating, error };
}
