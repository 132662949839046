import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';

export const PATIENT_CONTENT_SUBSCRIPTIONS = (patientId: string) =>
  `/patients/${patientId}/content-subscriptions`;

export function usePatientContentSubscription(patientId: string) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    ['get-patient-content-subscriptions', patientId],
    () => getPatientSubscriptions(patientId).then((res) => res.subscriptions),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    isLoading: isLoading || isValidating,
    subscriptions: data ?? [],
    error,
    reload: mutate,
  };
}

function getPatientSubscriptions(patientId: string) {
  return InsightsApi.get<{
    subscriptions: {
      article_collection_id: string;
      is_active: boolean;
      subscription_date: string;
    }[];
  }>(buildUrl(PATIENT_CONTENT_SUBSCRIPTIONS(patientId)));
}

export function updatePatientSubscriptions(
  patientId: string,
  collections: {
    collection_id: string;
    is_active: boolean;
  }[]
) {
  return InsightsApi.put(buildUrl(PATIENT_CONTENT_SUBSCRIPTIONS(patientId)), { collections });
}
