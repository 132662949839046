/**
 *
 * Messages for intl
 *
 *  */

import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordRules: {
    id: 'confirm-password.passwordRules',
    defaultMessage: 'Passwords should match the following rules:',
  },
  length: {
    id: 'password-popup.length',
    defaultMessage: 'At least eight characters',
  },
  lower: {
    id: 'password-popup.lower',
    defaultMessage: 'At least one lowercase letter',
  },
  capital: {
    id: 'password-popup.capital',
    defaultMessage: 'At least one uppercase letter',
  },
  number: {
    id: 'password-popup.number',
    defaultMessage: 'At least one number',
  },
  specialChar: {
    id: 'password-popup.specialChar',
    defaultMessage: 'At least one special character',
  },
});
